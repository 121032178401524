import React, { useState } from 'react';
import global from '../../assets/scss/global.module.scss';
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const UploadDocument = ({ userData }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [newDocName, setNewDocName] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [error, setError] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  const handleHide = () => {
    setIsVisible(false); // Hide the div when the button is clicked
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setNewDocName(file.name);
      setError('');
    }

    const file1 = event.target.files[0];
    if (file1) {
      // Create a preview URL using FileReader or URL.createObjectURL
      const previewUrl = URL.createObjectURL(file1);
      setImagePreview(previewUrl);
    }

    if (file1 && file1.type === 'application/pdf') {
      //setPdfFile(file1);
    
      const previewUrl = URL.createObjectURL(file1);
      setImagePreview(previewUrl);
      //setFileUrl(URL.createObjectURL(file1)); // Create a URL for the file
    }

  };

  // Handle file name change
  const handleNameChange = (event) => {
    setNewDocName(event.target.value);
  };

  // Handle file upload
  const handleFileUpload = async (docId) => {
    if (!selectedFile) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('providerUserId', userData.providerUserId);
    formData.append('docId', docId || 0);
    formData.append('docType', newDocName);

    try {
      setUploadStatus('Uploading...');
      const response = await fetcher.post(`${ApiUrls.Upload}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploadStatus(`File uploaded successfully: ${response.message}`);
      setSelectedFile(null);
      setNewDocName('');
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('File upload failed. Please try again.');
    }
  };

  return (
    <div>
      <div className={global.wrapper_form}>
        <h3>05 Upload Document</h3>

        <div className={global.upload_resume_section}>
          <label>
            <input
              onChange={handleFileChange}
              id="fileInput"
              className={global.upload_input}
              type="file"
            />
            <span
              onClick={() => document.getElementById('fileInput').click()}
              className={global.upload_resume_}
            >
              <i className={global.upload_resume_icon}></i>
            </span>
            <strong>Click to Upload</strong> or drag and drop (Max. File size: 25 MB)
          </label>
        </div>

        {isVisible && selectedFile && (
          <div className={global.overlay}>
            <div className={` ${global.file_change_thickbox}`}>
              <div className={global.form_group}>
                <div className={` ${global.upload_heading} ${global.pad_24} ${global.d_flex} ${global.d_flex_l_r}`}>
                    <h4>Rename Document</h4>
                    <span> <IconButton  onClick={handleHide}>
      <CloseIcon />
    </IconButton></span>
                </div>
                <div style={{padding:"8px"}}>
                <h6>Selected File: {selectedFile.name}</h6>
                 <div style={{overflowY:"scroll", height:"180px"}}>
                   {imagePreview && (
                    <div>
                      <img src={imagePreview} alt="Image Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                    </div>
                  )}
                 </div>
                <div className={` ${global.row}`}>
                <div className={`${global.col_s_7}`}>
                <input
                  className={global.form_control}
                  type="text"
                  value={newDocName}
                  onChange={handleNameChange}
                  placeholder="Rename file"
                />
                </div>
                <div className={`${global.col_s_5}`}>
                <button 
                  className={global.submit_primary_btn}
                  onClick={() => handleFileUpload(null)}
                >
                  Upload
                </button>
                </div>
                </div>
                

                {error && <p className={global.error_message}>{error}</p>}
                </div>
              </div>
            </div>
          </div>
        )}

      
        <div className={global.mar_top_24}>
         
          {userData.providerBusinessDocuments?.map((doc) => (
            <div key={doc.id} className= {` ${global.row} ${global.upload_doc}`} >
              <div className={global.col_11}>
                <strong>{doc.docName}</strong>: {doc.fileName}
                <a
                  href={doc.bucketUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={global.view_icon}
                  style={{marginLeft:"12px"}}
                >
                  <VisibilityIcon fontSize="small" style={{color:"#06226A"}} />
                </a>
              </div>
              <div className={global.col_1}>
               
                <button
                  className={ `${global.reupload_btn} ${global.reupload_text}`}
                  onClick={() => handleFileUpload(doc.docId)}
                >
                 Reupload
                </button>
              </div>
            </div>
          ))}
        </div>

        {uploadStatus && <p className={global.upload_status}>{uploadStatus}</p>}
      </div>
    </div>
  );
};

export default UploadDocument;
