import { useNavigate } from 'react-router-dom';
import { PageUrls } from '../../../utils/constants';
import { Box, Typography, Button } from '@mui/material';
import globalcss from '../../../assets/scss/global.module.scss'
import logo from '../../../assets/images/tramo-logo.svg'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ESignComplete = ({ setIsPreview }) => {
    const navigate = useNavigate();
    const handlePreview = () => {
        setIsPreview(true);
    };

    return (
        <>
            <div className={globalcss.gray_bg}>
                <header className={`${globalcss.esign_bg_inner} ${globalcss.esgin} `}>
                    <div className='container-fluid'>
                        <img src={logo} alt="logo" />
                    </div>
                </header>


                <div className='container-fluid'>
                    <div className={globalcss.esign_bg_inner}>
                         <div className='container'>
                             <div className='row'>
                                <div className='col-sm-7 mx-auto'>
                                    <h3>eSign Completed</h3>
                                    <div className={globalcss.esgin_completed}>
                                        <div className='mt-5'>
                                        <span className={globalcss.success_large_icon}></span>
                                        </div>
                                        <h4>Complete</h4>
                                        <p>Your eSign is Completed and the document is forwarded for the Client Signature.</p>

                                       <div className={globalcss.esign_button}>
                                        <div className='row'>
                                            <div className='col-6'>
                                    <button className={globalcss.submit_primary_btn} variant="contained" onClick={handlePreview} sx={{ mt: 2 }}>
                                        Preview Document
                                    </button>
                                    </div>
                                    <div className='col-6'>
                                    <button className={globalcss.submit_seconday_btn} variant="outlined" onClick={() => navigate(PageUrls.ESIGNREQ)} sx={{ mt: 2, ml: 2 }}>
                                        Back Home
                                    </button>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                             </div>
                        </div>   
                    </div>
                </div>
            </div>
        </>
    );
};

export default ESignComplete;
