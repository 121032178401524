import React, { useEffect, useState } from 'react';
import { Typography, Select, MenuItem, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, FormControl, InputLabel } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import fetcher from '../../utils/fetcher';
import { PageUrls, ApiUrls, options } from "../../utils/constants";
import { ENTITY_NAME_OPTIONS } from '../../utils/dropdown'
import { useNavigate } from 'react-router-dom';
import LeadDetail from './detail/LeadsDetail';
import global from '../../assets/scss/global.module.scss'
import Loader from '../../utils/Loader';
import ESignRequestDetail from './eSign/ESignRequestDetail';


const ESignRequestNew = () => {
  const navigate = useNavigate();
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [leads, setLeads] = useState([]);

  const [filters, setFilters] = useState({
    filterType: 'entityName',
    filterValue: '',
    status: '',
    startDate: '',
    endDate: '',
  });

  const [statuses] = useState(['ACTIVE', 'INACTIVE']);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLeadClicked, setLeadClicked] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setupLogin();
    //localStorage.setItem('auth', JSON.stringify("eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4ODAwNjMxODU3IiwiZW1haWwiOiJhcnVuQGdtYWlsLmNvbSIsImlkIjoxLCJyb2xlIjpbIkFkbWluIl0sImlhdCI6MTczMTUyMzA0MCwiZXhwIjoxNzMyMTI3ODQwfQ.cjZSATeHTJ9wyzlW_O4izFKv9DdFW7zpc6LWPGXjEUQ"));

    fetchLeads(currentPage);
  }, [currentPage]);

  const setupLogin = async () => {
    const adminData = {
      leadUserId: 1,
      email: "arun@gmail.com",
      mobile: "8800631857",
      role: ["Admin"],
    }
    try {
      const res = await fetcher.post(ApiUrls.AUTHENTICATE, adminData);
      localStorage.setItem('auth', JSON.stringify(res));

    } catch (err) {
    }
  }

  const fetchLeads = async (page) => {
    try {
      setLoading(true)
      const data = await fetcher.get(
        `${ApiUrls.All_LEADS}?page=${page - 1}&size=${itemsPerPage}&field=leadStatus&value=Admin Sign Pending&value=User Sign Pending`
      );

      if (data?.status === 200) {
        setCurrentPage(page);
        setTotalPages(data?.response.totalPages);
        setLeads(data.response.userLeads);
        setFilteredLeads(data.response.userLeads);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
    setLoading(false)
  };



  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const clearFilters = () => {
    setFilters({
      filterType: 'entityName',
      filterValue: '',
      status: '',
      startDate: '',
      endDate: '',
    });
    setFilteredLeads(leads);
  };

  const searchFilters = () => {
    let filtered = [...leads];

    // Filter by filterValue if both filterType and filterValue are provided
    if (filters.filterType && filters.filterValue) {
      filtered = filtered.filter((lead) => {
        const fieldValue = lead[filters.filterType]?.toString().toLowerCase() || '';
        return fieldValue.includes(filters.filterValue.toLowerCase());
      });
    }

    // Filter by status if provided
    if (filters.status) {
      filtered = filtered.filter((lead) => lead.status.toLowerCase().includes(filters.status.toLowerCase()));
    }

    // Filter by startDate if provided
    if (filters.startDate) {
      filtered = filtered.filter((lead) => new Date(lead.createdAt * 1000) >= new Date(filters.startDate));
    }

    // Filter by endDate if provided
    if (filters.endDate) {
      filtered = filtered.filter((lead) => new Date(lead.createdAt * 1000) <= new Date(filters.endDate));
    }

    // Set the filtered leads and reset to the first page
    setFilteredLeads(filtered);
    setCurrentPage(1);  // Reset to first page when new filters are applied
  };





  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === options[0]) navigate(PageUrls.LEADS);
    else if (e.target.value === options[1]) navigate(PageUrls.NEWLEADS);
    else if (e.target.value === options[2]) navigate(PageUrls.ESIGNREQ);
    else if (e.target.value === options[3]) navigate(PageUrls.APIUSER);
  };

  const handleDownload = () => {
    const headers = [
      'Date & Time',
      'Lead ID',
      'Entity Name / Auth. Person Name',
      'Email ID',
      'Phone Number',
      'Aadhaar Number',
      'Person PAN',
      'Entity Pan',
      'GSTIN'
    ];

    const rows = filteredLeads.map((lead) => [
      new Date(lead.createdAt * 1000).toLocaleString(),
      lead.leadUserId,
      `${lead.companyName} / ${lead.authorizedPersonName}`,
      lead.email,
      lead.mobile,
      lead.companyWebsite,
      lead.message,
      lead.onboardingLink,
      lead.status
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map((row) => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'leads_data.csv';
    link.click();
  };


  const openLeadDetail = (leadId) => {
    setSelectedLeadId(leadId);
    setLeadClicked(true);
  };



  return (
    <div className={`${global.gray_bg} ${global.pad_16} `}>
      {loading && <Loader />}
      <div class="container-fluid">
        <div className={`${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
          <Typography variant="h1" gutterBottom > ESign Request</Typography>
        </div>
        <div className={`${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16} `} >
          <FormControl variant="outlined" style={{ width: "120px", marginRight: "8px" }}>
            <InputLabel>Filter Type</InputLabel>
            <Select name="filterType" value={filters.filterType} onChange={handleChange} label="Filter Type">
              {ENTITY_NAME_OPTIONS.map((type) => (
                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            name="filterValue"
            label={`Enter ${filters.filterType === 'entityName' ? 'Entity Name' : filters.filterType}`}
            value={filters.filterValue}
            onChange={handleChange}
          />

          <FormControl variant="outlined" style={{ width: "120px", marginRight: "8px", marginLeft: "8px" }}>
            <InputLabel>Status</InputLabel>
            <Select name="status" value={filters.status} onChange={handleChange} label="Status">
              <MenuItem value="">Select Status</MenuItem>
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            type="date"
            name="startDate"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            value={filters.startDate}
            onChange={handleChange}

            style={{ marginRight: "8px" }}
          />

          <TextField
            variant="outlined"
            type="date"
            name="endDate"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            value={filters.endDate}
            onChange={handleChange}
          />

          <button className={`${global.clear_btn} ${global.lead_common_btn} `} onClick={clearFilters}>Clear</button>
          <button className={`${global.submit_btn_1} ${global.lead_common_btn} `} onClick={searchFilters}> Search <i className={global.lead_search_icon}></i></button>
          <button className={`${global.download_btn_1} ${global.lead_common_btn} `} onClick={handleDownload}>Download <i className={global.lead_download_icon}></i></button>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#EAF6FF" }}>
              <TableRow>
                <TableCell style={{ width: "115px" }}>Date <br /> Time</TableCell>
                <TableCell>Lead ID</TableCell>
                <TableCell>Executed By</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Entity Type</TableCell>
                <TableCell>Entity Name <br /> Auth. Person Name</TableCell>
                <TableCell>Email ID <br /> Phone Number</TableCell>
                {/* <TableCell style={{ width: "170px" }}>Phone Number</TableCell> */}
                <TableCell style={{ width: "133px" }}>Aadhar Number <br /> Pan Number</TableCell>

                <TableCell>GSTIN <br /> Pan Number</TableCell>
                <TableCell>Lead Status</TableCell>
                <TableCell>Agreement Copy</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={global.gray_lead_row}>
              {filteredLeads.map((lead) => (
                <TableRow key={lead.leadUserId} onClick={() => openLeadDetail(lead.leadUserId)} style={{ cursor: 'pointer' }}>
                  <TableCell>{new Date(lead.createdAt * 1000).toLocaleDateString()}<br />
                    {new Date(lead.createdAt * 1000).toLocaleTimeString()}</TableCell>
                  <TableCell>{lead.leadUserId}</TableCell>
                  <TableCell>
                    {/* {lead.executedBy} */}
                    Rajeev <br />{new Date(lead.createdAt * 1000).toLocaleDateString()}<br />
                    {new Date(lead.createdAt * 1000).toLocaleTimeString()}</TableCell>
                  <TableCell>
                    {/* {lead.userType} */}
                    API User
                  </TableCell>
                  <TableCell>Individual</TableCell>
                  <TableCell > {lead.companyName} <br />{lead.authorizedPersonName}</TableCell>
                  <TableCell><span className={global.d_flex} title={lead.email}><span className={global.text_truncation}>{lead.email}</span> <i className={global.green_check_icon}></i></span><br />{lead.mobile} <i className={global.green_check_icon}></i>
                  </TableCell>
                  <TableCell > {lead.leadAadhaarDetails.aadharNumber}<br />
                    {lead.leadPanDetails.panNumber}</TableCell>
                  <TableCell > {lead.leadBusinessDetails.pan}<br />
                    {lead.leadBusinessDetails.gstin}</TableCell>
                  <TableCell>{lead.leadStatus}</TableCell>
                  <TableCell><a className={global.view_agreement}
                    href={`${PageUrls.ESIGNREQ_DETAIL.replace(':id', lead.leadUserId)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  //title={`${PageUrls.ESIGNREQ_DETAIL.replace(':id', lead.leadUserId)}`} 
                  >
                    View
                  </a>
                    {/* <Link className={global.view_agreement} component={RouterLink} to={PageUrls.ESIGNREQ_DETAIL.replace(':id', lead.leadUserId)}>
                      View
                    </Link> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className={`${global.pageination}`}>
          <div className={global.row}>
            <div className={global.col_s_12}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, page) => setCurrentPage(page)}
                color="primary"

                sx={{
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#EAF6FF', // Change to your preferred color
                    borderRadius: '2px',
                    margin: '0',
                    border: 'solid #0682FF 1px;',
                    color: '#06226A',
                  },
                  '& .MuiPaginationItem-root:hover': {
                    backgroundColor: '#EAF6FF', // Change to your preferred color
                    borderRadius: '0',
                    border: 'none',
                    color: '#06226A',       // Hover text color (darker blue)
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default ESignRequestNew;
