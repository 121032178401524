import { Grid, FormControl, Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CustomSelect } from '../../../components/select/CustomSelect.js'
import { useFormik } from "formik";
import ESignModal from "../../../components/modals/ESignModal.js";
import * as Yup from "yup";
import ESignAgreement from "./ESignAgreement.js";
import ESignComplete from "./ESignComplete.js";
import { ApiUrls } from "../../../utils/constants.js";
import fetcher from "../../../utils/fetcher.js";
import Loader from "../../../utils/Loader.js";
import { getLocation, getIpAddress, getDeviceInfo, getOperatingSystem } from "../../../utils/constants.js";
import ESignAgreementPreview from "./ESignAgreementPreview.js";
import globalcss from '../../../assets/scss/global.module.scss'
import '../../../assets/scss/esign.css';


const ESignRequestDetail = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isValid, setisValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [leadDetails, setLeadDetails] = useState()
    const [error, setError] = useState()
    const [isPreview, setIsPreview] = useState(false);
    const [content, setContent] = useState()
    const params = useParams();
    const { id } = params
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [eSignData, setESignData] = useState([])
    const [ip, setIp] = useState("")
    const [browser, setBrowser] = useState("")
    const [operatingSystem, setOperatingSystem] = useState("")
    const [pageOpenTime, setPageOpenTime] = useState(null);
    const date = new Date();
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[date.getMonth()];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const time = `${hours}:${minutes}:${seconds}`;
    useEffect(() => {
        let timeString = `${date.getDate()}.${monthName}.${date.getFullYear()} ${time}`;
        setPageOpenTime(timeString);
    }, []);
    const fetchLeadDetail = async (leadId) => {
        try {
            setLoading(true)
            const location = await getLocation();
            const ipAddress = await getIpAddress();
            setIp(ipAddress)
            const deviceInfo = getDeviceInfo();
            const { browser } = deviceInfo
            setBrowser(browser)
            const operatingSystem = getOperatingSystem();
            setOperatingSystem(operatingSystem)
            setLatitude(location?.latitude)
            setLongitude(location?.longitude)
            const data = await fetcher.get(
                `${ApiUrls.FETCH_LEADS_DETAILS}?id=${leadId}`
            );
            if (data?.status === 200) {
                setLeadDetails(data.response);
            } else {
                setError("Failed to fetch lead details.");
            }
        } catch (error) {
            setError("Error fetching lead details.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };
    const fetchAgreementData = async () => {
        try {
            setLoading(true)
            const data = await fetcher.get(`${ApiUrls.GET_LEAD_SAVE_ESIGN_CONTENT}?leadUserId=${id}`)
            if (data?.status === 200) {
                setContent(data.response)
            } else {
                setError("Failed to fetch ESign content");
            }
        } catch (error) {
            setError("Failed to fetch ESign content:", error);
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };
    const fetchEsign = async () => {
        try {
            setLoading(true)
            const data = await fetcher.get(ApiUrls.ESIGNREQUEST)
            if (data?.status === 200) {
                setESignData(data.response)
            } else {
                setError("Failed to fetch ESign content");
            }
        } catch (error) {
            setError("Failed to fetch ESign content:", error);
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => { fetchLeadDetail(id); fetchEsign(); }, [id])
    useEffect(() => { if (isPreview) { fetchAgreementData(); } }, [isPreview])

    const clientData = {
        clientUserBusinessEmail: leadDetails?.email,
        clientUserAuthorisedPerson: leadDetails?.authorizedPersonName,
        clientUserBusinessAddress: leadDetails?.leadBusinessDetails?.registeredAddress1 + " " + leadDetails?.leadBusinessDetails?.registeredAddress2,
        clientUserBusinessConstitution: leadDetails?.leadBusinessDetails?.constitutionType !== "" ? leadDetails?.leadBusinessDetails?.constitutionType : "Private Limited",
        clientUserBusinessName: leadDetails?.leadBusinessDetails?.name,
        clientTitle: "Authorized Person"
    }

    const adminData = {
        clientAuthorisedPersonTitle: "Head-Legal",
        clientBusinessEmail: "legal@tramo.in",
        clientJurisdiction: "Gautam Buddha Nagar - Uttar Pradesh",
        clientOnboardingFee: 10000,
        clientBusinessName: "TRAMO TECHNOLAB PRIVATE LIMITED",
        clientBrandName: "Tramo",
        clientCIN: "U72501UP2018PTC106704",
        clientBusinessAddress: "Tramo Technolab Private Limited"
    }

    const signOptions = eSignData && eSignData.map((val) => ({
        id: val.id,
        label: val.name,
        value: val.name,
        image: val.photo,
        position: val.designation,
        esign: val.esign
    }))

    const validationSchema = Yup.object({
        // signBy: Yup.string()
        //     .required('This field is required'),
        policyAccepted: Yup.boolean()
            .oneOf([true], 'You must accept the policy'),
    });
    const handleSubmit = (values) => {
        setIsOpen(true);
    };

    const formManager = useFormik({
        initialValues: {
            signBy: "",
            policyAccepted: false,
            position: "",
            esign: "",
            image: ""
        },
        validationSchema,
        onSubmit: (values) => handleSubmit(values),

    });
    const signedBy = {
        signImage: `data:image/jpeg;base64,${formManager.values.esign}` || "",
        signBy: formManager.values.signBy || "",
        position: formManager.values.position || "",
        company: "Tramo Technolab",
        image: `data:image/jpeg;base64,${formManager.values.image}` || "",
    }

    const handleAddClose = () => {
        setIsOpen(false);
    };

    if (loading) return <Loader />;
    if (error) return <Typography>{error}</Typography>;
    return (
        <>
            {!isPreview ? (
                isValid ? (
                    <ESignComplete
                        setIsPreview={setIsPreview}
                    />
                ) : (
                    <div>
                        <ESignAgreement
                            clientData={clientData}
                            adminData={adminData}
                            latitude={latitude}
                            longitude={longitude}
                            operatingSystem={operatingSystem}
                            ip={ip}
                            browser={browser}
                            signedBy={signedBy}
                            pageOpenTime={pageOpenTime}
                        />
                        <div className={globalcss.gray_bg}>
                            <div className="container-fluid">
                                <div
                                    className={globalcss.esign_bg_inner}
                                    style={{
                                        position: "fixed",
                                        bottom: "0px",
                                        left: "0px",
                                        right: "0px",
                                    }}
                                >

                                    <form onSubmit={formManager.handleSubmit}>
                                    <div   style={{
                                        padding: "12px",
                                        border: "#D6D9E1 solid 1px",
                                        borderRadius: "12px",
                                     }}>
                                        <h3 style={{ margin: "0", padding:"0 0 8px 0" }}>Policy Agreement</h3>
                                       
                                        <div className={`${globalcss.row}`}>

                                        <div className={globalcss.col_s_8}>
                                        <FormControl
                                                component="fieldset"
                                                error={
                                                    formManager.touched.policyAccepted &&
                                                    Boolean(formManager.errors.policyAccepted)
                                                }
                                            >
                                                <RadioGroup
                                                    name="policyAccepted"
                                                    value={formManager.values.policyAccepted.toString()}
                                                    onChange={(e) => {
                                                        const value = e.target.value === "true";
                                                        formManager.setFieldValue("policyAccepted", value);
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value="true"
                                                        control={<Radio />}
                                                        label={`I ${signedBy.signBy}, hereby declare that I am signing this agreement in full awareness, consciousness, and without any form of coercion. I acknowledge and agree to all the terms outlined in the agreement and 
    understand that my digital signature, as well as my geographical location, IP address, and browser details, have been recorded as part of the signing process.`}
                                                    />
                                                </RadioGroup>
                                                {formManager.touched.policyAccepted &&
                                                    formManager.errors.policyAccepted && (
                                                        <div style={{ color: "red", fontSize: "12px" }}>
                                                            {formManager.errors.policyAccepted}
                                                        </div>
                                                    )}
                                            </FormControl>
                                         </div>   

                                         <div className={globalcss.col_s_4}>
                                         <div className={`${globalcss.row} `}>
                                            <div className={globalcss.col_s_8}>
                                                <CustomSelect

                                                    options={signOptions}
                                                    value={formManager.values.signBy}
                                                    onChange={(selectedValue) => {
                                                        debugger
                                                        const selectedIds = signOptions.find(
                                                            (val) => val.value === selectedValue);
                                                        const filteredESignId = selectedIds
                                                        formManager.setFieldValue("signBy", filteredESignId.value);
                                                        formManager.setFieldValue("position", filteredESignId.position);
                                                        formManager.setFieldValue("esign", filteredESignId.esign);
                                                        formManager.setFieldValue("image", filteredESignId.image);
                                                    }}
                                                    helperText={
                                                        formManager.touched.signBy &&
                                                        formManager.errors.signBy
                                                    }
                                                    name={"signBy"}

                                                />
                                            </div>

                                            <div className={globalcss.col_s_5}>
                                                <button
                                                    type="submit"
                                                    disabled={!formManager.isValid}
                                                    className={`${globalcss.submit_primary_btn} ${globalcss.display_inline_blk} `}
                                                >
                                                    Proceed
                                                </button>
                                            </div>
                                        </div>
                                        </div>                
                                        </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                        {isOpen && (
                            <ESignModal
                                open={isOpen}
                                onClose={handleAddClose}
                                setIsOpen={setIsOpen}
                                signByUser={signedBy.signBy}
                                setisValid={setisValid}
                                clientData={clientData}
                                adminData={adminData}
                                longitude={longitude}
                                operatingSystem={operatingSystem}
                                ip={ip}
                                browser={browser}
                                latitude={latitude}
                                signedBy={signedBy}
                                id={id}
                                pageOpenTime={pageOpenTime}
                            />
                        )}
                    </div>
                )
            ) : (
                <ESignAgreementPreview
                    setLoading={setLoading}
                    id={id}
                    setError={setError}
                    content={content}
                />
            )}
        </>


    )
}

export default ESignRequestDetail