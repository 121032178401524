import { Box, Typography } from "@mui/material";


export const CustomLabel = ({
    label,
    fontSize,
    fontWeight,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
            }}
        >
            <Typography
                sx={{
                    fontWeight: fontWeight ? { fontWeight } : "500",
                    color: "#000000",
                    fontSize: fontSize ? { fontSize } : 10,
                }}
            >
                {label}
            </Typography>
        </Box>
    );
};
