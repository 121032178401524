import React from 'react';
import { Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import global from '../../assets/scss/global.module.scss';

const BankDetails = ({ leadDetails }) => {
  // Extract all bank details
  const bankDetails = leadDetails?.leadBankDetails || [];
  
  // Find the primary bank detail
  const primaryBankDetail = bankDetails.find((bank) => bank.isPrimary) || {};
  
  // Filter out non-primary bank details for the table
  const otherBankDetails = bankDetails.filter((bank) => !bank.isPrimary);

  return (
    <Box display="flex" flexDirection="column" gap={4} mt={2}>
      {/* Form for Primary Bank Details */}
      <Typography variant="h6">Primary Bank Details</Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Bank Name"
          value={primaryBankDetail.bankName || "N/A"}
          variant="outlined"
          fullWidth
          disabled
        />
        <TextField
          label="Account Number"
          value={primaryBankDetail.accountNumber || "N/A"}
          variant="outlined"
          fullWidth
          disabled
        />
        <TextField
          label="Account Holder Name"
          value={primaryBankDetail.holderName || "N/A"}
          variant="outlined"
          fullWidth
          disabled
        />
        <TextField
          label="IFSC Code"
          value={primaryBankDetail.ifsc || "N/A"}
          variant="outlined"
          fullWidth
          disabled
        />
        <TextField
          label="Branch Name"
          value={primaryBankDetail.branchName || "N/A"}
          variant="outlined"
          fullWidth
          disabled
        />
        <TextField
          label="Account Type"
          value={primaryBankDetail.accountType || "N/A"}
          variant="outlined"
          fullWidth
          disabled
        />
      </Box>

      {/* Table for Other Bank Details */}
      {otherBankDetails.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6">Other Bank Details</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bank Name</TableCell>
                  <TableCell>Account Number</TableCell>
                  <TableCell>Account Holder Name</TableCell>
                  <TableCell>IFSC Code</TableCell>
                  <TableCell>Branch Name</TableCell>
                  <TableCell>Account Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {otherBankDetails.map((bank) => (
                  <TableRow key={bank.id}>
                    <TableCell>{bank.bankName}</TableCell>
                    <TableCell>{bank.accountNumber}</TableCell>
                    <TableCell>{bank.holderName}</TableCell>
                    <TableCell>{bank.ifsc}</TableCell>
                    <TableCell>{bank.branchName}</TableCell>
                    <TableCell>{bank.accountType}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Button */}
      {/* <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <button
          className={`${global.lead_common_btn} ${global.submit_btn_1} ${global.width_120}`}
        >
          Next
        </button>
      </Box> */}
    </Box>
  );
};

export default BankDetails;
