import React, { useState } from "react";
import { Box, Typography, TextField, Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import global from "../../assets/scss/global.module.scss";
import Modal from "@mui/material/Modal";

const NavyButton = styled(Button)({
  backgroundColor: "navy",
  color: "white",
  minHeight: "40px",
  "&:hover": {
    backgroundColor: "darkblue",
  },
});

const StatusText = styled(Typography)(({ status }) => ({
  color: status === "Accepted" ? "green" : "red",
  fontWeight: "bold",
  minHeight: "40px",
  display: "flex",
  alignItems: "center",
}));

const GstinNumber = ({ leadDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusInfo, setStatusInfo] = useState({
    status: "",
    dateTime: "",
  });

  const handleApprove = () => {
    const currentDateTime = new Date().toLocaleString();
    setStatus("Accepted");
    setStatusInfo({ status: "Approved", dateTime: currentDateTime });
    setModalOpen(true);

    const payload = {
      leadUserId: leadDetails.leadUserId,
      verified: "Verified",
      remarks: "Verified",
    };
    approveGSTIN(payload);
  };

  const handleRejectClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (option) => {
    setAnchorEl(null);
    if (option) {
      const currentDateTime = new Date().toLocaleString();
      setStatus(option);
      setStatusInfo({ status: option, dateTime: currentDateTime });
      setModalOpen(true);

      const payload = {
        leadUserId: leadDetails.leadUserId,
        verified: "Rejected",
        remarks: option,
      };
      approveGSTIN(payload);
    }
  };

  const approveGSTIN = async (payload) => {
    const verifyRes = await fetcher.post(ApiUrls.APPROVE_GSTIN, payload);
    if (verifyRes?.status === 200) {
      // Success logic
    } else {
      // Failure logic
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      <TextField
        label="GSTIN Number"
        value={leadDetails?.leadBusinessDetails?.gstin || "N/A"}
        variant="outlined"
        fullWidth
        disabled
      />
      <TextField
        label="Business Name"
        value={leadDetails?.leadBusinessDetails?.name || "N/A"}
        variant="outlined"
        fullWidth
        disabled
      />
      <TextField
        label="Business Place Address"
        value={
          `${leadDetails?.leadBusinessDetails?.registeredAddress1 || ""} ` +
          `${leadDetails?.leadBusinessDetails?.registeredAddress2 || ""} ` +
          `${leadDetails?.leadBusinessDetails?.city || "N/A"}`
        }
        variant="outlined"
        fullWidth
        disabled
      />
      <TextField
        label="Constitution of Business"
        value={leadDetails?.leadBusinessDetails?.constitutionTypeName || "N/A"}
        variant="outlined"
        fullWidth
        disabled
      />
      <TextField
        label="Jurisdiction State"
        value={leadDetails?.leadBusinessDetails?.stateJurisdiction || "N/A"}
        variant="outlined"
        fullWidth
        disabled
      />
      <TextField
        label="GSTIN Registration Date"
        value={leadDetails?.leadBusinessDetails?.incorporationDate || "N/A"}
        variant="outlined"
        fullWidth
        disabled
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        {/* <button
          className={`${global.lead_common_btn} ${global.submit_btn_1} ${global.width_120}`}
        >
          Next
        </button> */}
        <Box display="flex" gap={1} alignItems="center">
          {!status ? (
            <>
              <button
                className={`${global.lead_common_btn} ${global.approve_btn} ${global.width_120}`}
                onClick={handleApprove}
              >
                Approve
              </button>
              <button
                className={`${global.lead_common_btn} ${global.reject_btn} ${global.width_120}`}
                onClick={handleRejectClick}
              >
                Reject
              </button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleMenuClose(null)}
              >
                <MenuItem onClick={() => handleMenuClose("Rejected - Option 1")}>
                  Option 1
                </MenuItem>
                <MenuItem onClick={() => handleMenuClose("Rejected - Option 2")}>
                  Option 2
                </MenuItem>
              </Menu>
            </>
          ) : (
            <StatusText
              variant="h6"
              status={status.startsWith("Accepted") ? "Accepted" : "Rejected"}
            >
              {status}
            </StatusText>
          )}
        </Box>
      </Box>

      {/* Modal */}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
          borderRadius="8px"
          boxShadow={24}
          p={4}
          mx="auto"
          my="auto"
          width="300px"
        >
          <Typography variant="h6" mb={2}>
            {statusInfo.status === "Approved" ? "Lead Approved" : "Lead Rejected"}
          </Typography>
          <Typography variant="body1" mb={3}>
            Status: {statusInfo.status}
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={3}>
            Date & Time: {statusInfo.dateTime}
          </Typography>
          <button
            className={`${global.lead_common_btn} ${global.submit_btn_1}`}
            onClick={handleModalClose}
          >
            Done
          </button>
        </Box>
      </Modal>
    </Box>
  );
};

export default GstinNumber;
