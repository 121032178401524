import React, { useState, useEffect } from "react";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import global from '../../assets/scss/global.module.scss';

import {
  Box,
  Typography,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  Button,
  Switch,
} from "@mui/material";
import Loader from '../../utils/Loader';

const ApplyScheme = ({ leadDetails }) => {
  const [outerData, setOuterData] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState({});
  const [expandedOuterRow, setExpandedOuterRow] = useState(null);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [loadingSubcategory, setLoadingSubcategory] = useState(false);
  const [loadingSchemes, setLoadingSchemes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSchemes, setSelectedSchemes] = useState([]); // Track selected schemes

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await fetcher.get(ApiUrls.FETCH_CATEGORY);
        if (response?.status === 200 && response.response) {
          setOuterData(response.response);
        } else {
          console.error("Failed to fetch categories:", response.message);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
      setLoading(false);
    };
    fetchCategories();
  }, []);

  const fetchSubcategories = async (catid) => {
    setLoadingSubcategory(true);
    try {
      const response = await fetcher.get(ApiUrls.FETCH_SUB_CATEGORY, { catid });
      if (response?.status === 200 && response.response) {
        setSubcategoryData((prev) => ({
          ...prev,
          [catid]: response.response,
        }));
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoadingSubcategory(false);
    }
  };

  const fetchSchemes = async () => {
    setLoadingSchemes(true);
    const catids = Object.keys(subcategoryData).join(",");
    const subcatids = selectedSubcategories.join(",");

    try {
      const url = `${ApiUrls.FETCH_SCHEMES_BY_IDS}?catids=${catids}&subcatids=${subcatids}`;
      const response = await fetcher.get(url);
      if (response?.status === 200 && response.response) {
        setSchemes(response.response);
      } else {
        console.error("Failed to fetch schemes:", response.message);
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
    } finally {
      setLoadingSchemes(false);
    }
  };

  const handleOuterRowToggle = (id) => {
    if (expandedOuterRow === id) {
      setExpandedOuterRow(null);
    } else {
      setExpandedOuterRow(id);
      if (!subcategoryData[id]) {
        fetchSubcategories(id);
      }
    }
  };

  const handleSubcategorySelect = (subcatid) => {
    setSelectedSubcategories((prev) =>
      prev.includes(subcatid)
        ? prev.filter((id) => id !== subcatid)
        : [...prev, subcatid]
    );
  };

  useEffect(() => {
    if (selectedSubcategories.length > 0) {
      fetchSchemes();
    } else {
      setSchemes([]); // Clear schemes when no subcategories are selected
    }
  }, [selectedSubcategories]);

  // const handleSchemeSelect = (scheme) => {
  //   setSelectedSchemes((prev) =>
  //     prev.some((selected) => selected.id === scheme.id)
  //       ? prev.filter((selected) => selected.id !== scheme.id)
  //       : [...prev, scheme]
  //   );
  // };
  const handleSchemeSelect = (scheme) => {
    // When a scheme is selected, set it as the only selected scheme
    setSelectedSchemes([scheme]); // Only keep one selected scheme in the state
  };
  

  const handleSave = async () => {
    if (!selectedSchemes.length) {
      console.log("Please select at least one scheme to save.");
      return;
    }

    const payloadArray = selectedSchemes.map((scheme) => ({
      cat: scheme.catId,
      subcat: scheme.subCatId,
      leadUserId: leadDetails?.leadUserId, // Ensure this is correctly passed
      schemeId: scheme.id,
    }));

    console.log("Payload to be sent:", payloadArray);

    try {
      const response = await fetcher.post(`${ApiUrls.LEAD_APPLIED_SCHEME}`, {
        leadAppliedSchemeList: payloadArray,
      });

      if (response?.status === 200) {
        console.log("Schemes successfully saved.");
      } else {
        console.error("Failed to save schemes:", response.message);
      }
    } catch (error) {
      console.error("Error saving schemes:", error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      {loading && <Loader />}
      {outerData.length > 0 ? (
        outerData.map((outerRow) => (
          <Box key={outerRow.id} p={2} border="1px solid #ddd" borderRadius="4px" bgcolor="#f1f1f1">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              border="1px solid #ccc"
              borderRadius="4px"
              bgcolor="#fff"
            >
              <Typography variant="h6">{outerRow.schemeCategoryName}</Typography>
              <Checkbox
                checked={expandedOuterRow === outerRow.id}
                onChange={() => handleOuterRowToggle(outerRow.id)}
              />
            </Box>

            {expandedOuterRow === outerRow.id && (
              <Box mt={2} p={2} border="1px solid #ccc" borderRadius="4px" bgcolor="#fafafa">
                {loadingSubcategory ? (
                  <CircularProgress size={24} />
                ) : subcategoryData[outerRow.id] ? (
                  subcategoryData[outerRow.id].map((subcategory) => (
                    <Box
                      key={subcategory.id}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={1}
                      p={1}
                      border="1px solid #ddd"
                      borderRadius="4px"
                      bgcolor="#fff"
                    >
                      <Typography variant="body1">{subcategory.schemeSubCategoryName}</Typography>
                      <Checkbox
                        checked={selectedSubcategories.includes(subcategory.id)}
                        onChange={() => handleSubcategorySelect(subcategory.id)}
                      />
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No subcategories found.
                  </Typography>
                )}

                {schemes.length > 0 && (
                  <TableContainer component={Paper} mt={2}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date of Creation</TableCell>
                          <TableCell>Scheme Name & ID</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Set Default</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {schemes.map((scheme) =>
                          scheme.subcatschemes.map((subcat) =>
                            subcat.schemeList.map((schemeItem) => (
                              <TableRow key={schemeItem.id}>
                                <TableCell>{new Date(schemeItem.createdOn * 1000).toLocaleDateString()}</TableCell>
                                <TableCell>{schemeItem.schemeName} ({schemeItem.id})</TableCell>
                                <TableCell>{schemeItem.schemeDesc}</TableCell>
                                <TableCell>{schemeItem.isActive ? "Active" : "Inactive"}</TableCell>
                                <TableCell>
                                  <Radio
                                     name="schemeSelection"
                                    checked={selectedSchemes.some((selected) => selected.id === schemeItem.id)}
                                    onChange={() => handleSchemeSelect(schemeItem)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            )}
          </Box>
        ))
      ) : (
        <Typography variant="body1" color="textSecondary">
          Loading categories...
        </Typography>
      )}

      {loadingSchemes && (
        <Box mt={2}>
          <CircularProgress size={24} />
        </Box>
      )}

      <Box mt={3} display="flex" justifyContent="flex-end">
        <button  className={`${global.lead_common_btn} ${global.submit_btn_1} ${global.width_120}`}  onClick={handleSave}>
          Save
        </button>
      </Box>
    </Box>
  );
};

export default ApplyScheme;
