import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import global from "../../assets/scss/global.module.scss"; // Assuming global CSS module for pagination styling


// Dummy Data for Dropdowns
const banks = ["HDFC", "SBI", "ICICI", "AXIS"];
const searchByOptions = ["Transaction ID", "Entity Name", "UTR"];
// Dummy Data for the table
const dummyData = [
  {
    dateTime: "2024-12-15 10:30 AM",
    executedBy: "John Doe",
    transactionId: "TXN123456",
    entityName: "ABC Pvt. Ltd.",
    amount: "50000",
    debitCredit: "Credit",
    narration: "Monthly payment",
    executesRemark: "Verified by Manager",
    attachment: "invoice123.pdf",
    usersClosingBalance: "150000",
  },
  {
    dateTime: "2024-12-16 11:00 AM",
    executedBy: "Jane Smith",
    transactionId: "TXN987654",
    entityName: "XYZ Pvt. Ltd.",
    amount: "75000",
    debitCredit: "Debit",
    narration: "Office supplies",
    executesRemark: "Awaiting Approval",
    attachment: "invoice987.pdf",
    usersClosingBalance: "75000",
  },
];

// Component
const FundFlowRequest = () => {
  // States for filtering
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    searchText: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Total pages
  const totalPages = Math.ceil(dummyData.length / rowsPerPage);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    console.log("Searching with filters:", filter);
    // Add your filter logic here
  };

  const handleClear = () => {
    setFilter({ startDate: "", endDate: "", searchText: "" });
  };

  return (
     
    <div className= { `${global.gray_bg} ${global.pad_16} `}>

    <div class="container-fluid">
          <div className={ `${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
             <Typography variant="h1"  gutterBottom > Fund Flow Report</Typography>
          </div>
    
          <div className= { `${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16}`} >
          <Box display="flex"   gap={1} flexWrap="wrap">

  <TextField
    select
    label="Search By"
    name="searchBy"
    value={filter.searchBy}
    onChange={handleFilterChange}
    variant="outlined"
    sx={{ width: "150px" }}
  >
    {searchByOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </TextField>
  <TextField
    label="Enter Value"
    name="searchText"
    value={filter.searchText}
    onChange={handleFilterChange}
    variant="outlined"
    
    sx={{ width: "150px" }}
  />
  <TextField
    select
    label="Search by Bank"
    name="bank"
    value={filter.bank}
    onChange={handleFilterChange}
    variant="outlined"
     
    sx={{ width: "150px" }}
  >
    {banks.map((bank) => (
      <MenuItem key={bank} value={bank}>
        {bank}
      </MenuItem>
    ))}
  </TextField>
  <TextField
    select
    label="Status"
    name="status"
    value={filter.status || ""}
    onChange={handleFilterChange}
    variant="outlined"
   
    sx={{ width: "150px" }}
  >
    {["Approved", "Pending", "Rejected"].map((status) => (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    ))}
  </TextField>
  <TextField
    label="From Date"
    type="date"
    name="fromDate"
    value={filter.fromDate || ""}
    onChange={handleFilterChange}
    variant="outlined"
   
    InputLabelProps={{
      shrink: true,
    }}
    sx={{ width: "150px" }}
  />
  <TextField
    label="To Date"
    type="date"
    name="toDate"
    value={filter.toDate || ""}
    onChange={handleFilterChange}
    variant="outlined"
     
    InputLabelProps={{
      shrink: true,
    }}
    sx={{ width: "150px" }}
  />
  <button className={ `${global.clear_btn} ${global.lead_common_btn} `} onClick={handleClear}>Clear</button>
  <button className={ `${global.submit_btn_1} ${global.lead_common_btn} `}  onClick={handleSearch}> Search <i className={global.lead_search_icon}></i></button>
  <button className={ `${global.download_btn_1} ${global.lead_common_btn} `} onClick={() => console.log("Download clicked")}>Download <i className={global.lead_download_icon}></i></button>

 
</Box>
</div>

      {/* Table Section */}
      <TableContainer component={Paper}>
              <Table>
                 <TableHead style={{background: "#EAF6FF"}}>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Executed By</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Entity Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Debit/Credit</TableCell>
              <TableCell>Narration</TableCell>
              <TableCell>Executer's Remark</TableCell>
              <TableCell>Attachment</TableCell>
              <TableCell>Users Closing Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dummyData
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.dateTime}</TableCell>
                  <TableCell>{row.executedBy}</TableCell>
                  <TableCell>{row.transactionId}</TableCell>
                  <TableCell>{row.entityName}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.debitCredit}</TableCell>
                  <TableCell>{row.narration}</TableCell>
                  <TableCell>{row.executesRemark}</TableCell>
                  <TableCell>
                    <a href={`/attachments/${row.attachment}`} target="_blank">
                      View
                    </a>
                  </TableCell>
                  <TableCell>{row.usersClosingBalance}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Custom Pagination Section */}
      <div className={`${global.pageination}`}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          color="primary"
          sx={{
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#EAF6FF",
              borderRadius: "2px",
              border: "solid #0682FF 1px",
              color: "#06226A",
            },
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "#EAF6FF",
              color: "#06226A",
            },
          }}
        />
      </div>
    </div>
    </div>
 
  );
};

export default FundFlowRequest;
