// constants.js

export const ENTITY_NAME_OPTIONS = [
  
    { label: 'Entity Name', value: 'companyName' },
    { label: 'Auth. Person Name', value: 'authorizedPersonName' },
    { label: 'Lead ID', value: 'leadUserId' },
    { label: 'Email Id', value: 'email' },
    { label: 'Mobile Number', value: 'mobile' },
   
]
  
  export const STATUS_OPTIONS = [
    { value: '', label: 'All' },
    
    { value: 'fresh', label: 'Fresh' },
    { value: 'resubmission', label: 'Resubmission' }
  ];
  