import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
} from "@mui/material";
import { useState } from "react";
import { CustomLabel } from "../label/CustomLabel";
//import { DownArrowIcon2 } from "../../svgs";


export const CustomSelect = ({
    label,
    options,
    helperText,
    value,
    onChange,
    errorStatus,
    name,
    defaultValue,
    color,
    disabled,
}) => {
    const [selectValue, setSelectValue] = useState(value ?? "");

    const handleChange = (event) => {
        setSelectValue(event.target.value);
        if (onChange) onChange(event.target.value);
    };

    return (
        <FormControl fullWidth>
            {label && <CustomLabel label={label} fontSize="12px" />}

            <Select
                value={selectValue?.toString()}
                onChange={handleChange}
                //IconComponent={() => <DownArrowIcon2 height={10} />}
                defaultValue={defaultValue ? defaultValue : ""}
                name={name}
                disabled={disabled}
                sx={{
                    height: 30,
                    borderRadius: "3px",
                    color: color ? color : "rgb(0 0 0 / 100%)",
                    fontSize: "12px",
                    //fontFamily: "Roboto-Regular",
                    border: "1px solid rgb(0 0 0 / 30%)",
                    padding: "8px",
                    cursor: "pointer",
                    "& .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-outlined.MuiSelect-select":
                    {
                        position: "relative",
                        overflow: "overlay",
                        padding: 0,
                        zIndex: 1,
                    },
                    "& svg": {
                        position: "absolute",
                        right: "13px",
                        width: "10px"
                    },
                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            "&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
                                marginTop: "7px !important",
                            },
                        },
                    },
                }}
                error={!!errorStatus}
            >
                {options.map((item, index) => (
                    <MenuItem
                        key={`custom-select-option-${index}`}
                        value={item.value}
                        sx={{
                            fontSize: "12px",
                        }}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>

            {helperText && (
                <FormHelperText
                    sx={{
                        //fontFamily: "Roboto-Bold",
                        color: "#FF3939",
                        fontSize: 10,
                        marginLeft: 0,
                        position: "absolute",
                        bottom: "-17px",
                    }}
                >
                    {helperText}
                </FormHelperText>
            )}
            {/* <ErrorStatusMessage error={errorStatus} /> */}
        </FormControl>
    );
};
