import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
} from "@mui/material";
import AddBankModal from "./AddBankModel";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import Loader from "../../utils/Loader";
import global from '../../assets/scss/global.module.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SelectedUserModal from "./SelectedUserModel.js";


const AddBankComponent = () => {
  const [banks, setBanks] = useState([]); // State for bank data
  const [modalOpen, setModalOpen] = useState(false); // Modal state
  const [selectedBank, setSelectedBank] = useState(null); // State for editing bank
  const [loading, setLoading] = useState(false); // Loading state
  const [filter, setFilter] = useState('all'); // Filter state
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [selectedUserModalOpen, setSelectedUserModalOpen] = useState(false); 
    
const dummyUsers = [
  { id: 23617, name: "Tramo Technolab Private Limited" },
  { id: 23618, name: "Example Company A" },
  { id: 23619, name: "Example Company B" },
];




  useEffect(() => {
    const fetchBanks = async () => {
      setLoading(true); // Start loader
      try {
        const response = await fetcher.get(ApiUrls.FETCH_BANKS);
        setBanks(response.response || []); // Adjust based on response structure
      } catch (err) {
        console.error("Error fetching banks:", err);
        setBanks([]); // Reset to empty array in case of error
      } finally {
        setLoading(false); // Stop loader
      }
    };

    fetchBanks();
  }, []);

  // Open modal for adding/editing bank
  const handleEditBank = (bank) => {
    setSelectedBank(bank); // Set selected bank for editing
    setModalOpen(true);
  };

  // Handle bank submission (add or update)
  const handleAddOrEditBank = (bankData) => {
    if (selectedBank) {
      // Update existing bank
      setBanks((prevBanks) =>
        prevBanks.map((bank) =>
          bank.bankId === selectedBank.bankId ? { ...bank, ...bankData } : bank
        )
      );
    } else {
      // Add new bank
      setBanks((prevBanks) => [
        ...prevBanks,
        { bankId: prevBanks.length + 1, ...bankData },
      ]);
    }
    setSelectedBank(null);
    setModalOpen(false);
  };

  // Handle filtering
  const handleFilter = (type) => {
    setFilter(type);
    // Add your filter logic here
  };

  // Handle date filtering
  const handleDateFilter = () => {
    if (startDate && endDate) {
      // Add your date filter logic here
    }
  };

  // Copy to clipboard function
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  return (
    <div className={`${global.gray_bg} ${global.pad_16}`}>
      <div className="container-fluid">
        <div className={`${global.white_bg} ${global.pad_16}`}>
          <Typography variant="h1" gutterBottom>
            Add New Bank
          </Typography>
        </div>

        <Box sx={{ p: 0, mt: 2 }}>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Loader />
            </Box>
          )}
     <div className={`${global.white_bg} ${global.pad_16}`}>
  {/* Container for filters and button */}
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
    {/* Filters Section */}
    <Box display="flex" alignItems="center" gap={1}>
      <a href={void(0)} className={global.scheme_active_tab} onClick={() => handleFilter('all')}>
        All
      </a>
      <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('today')}>
        Today
      </a>
      <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('yesterday')}>
        Yesterday
      </a>
      <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('last7days')}>
        Last 7 Days
      </a>
      <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('last30days')}>
        Last 30 Days
      </a>
      <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('lastMonth')}>
        Last Month
      </a>
      <TextField
        type="date"
        label="Start Date"
        InputLabelProps={{ shrink: true }}
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        style={{ marginRight: "8px" }}
      />
      <TextField
        type="date"
        label="End Date"
        InputLabelProps={{ shrink: true }}
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        style={{ marginRight: "8px" }}
      />
    </Box>

    {/* Add Bank Account Button */}
    <button
      className={`${global.submit_btn_1} ${global.lead_common_btn}`}
      onClick={() => setModalOpen(true)}
    >
      Add Bank Account
    </button>
  </Box>
</div>


          {banks.length > 0 ? (
            <TableContainer component={Paper} style={{ marginTop: "16px" }}>
              <Table>
                <TableHead style={{ background: "#EAF6FF" }}>
                  <TableRow>
                    <TableCell>View To</TableCell>
                    <TableCell>Bank Name</TableCell>
                    <TableCell>A/C Number & IFSC</TableCell>
                    <TableCell>Branch</TableCell>
                    <TableCell>Modes Enable</TableCell>
                    <TableCell>Minimum Deposit</TableCell>
                    <TableCell>Maximum Deposit</TableCell>
                    <TableCell>Modify</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {banks.map((bank, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <select
                         onChange={(e) => {
                          if (e.target.value === "Selected User") {
                            setSelectedUserModalOpen(true);
                          }
                        }}
                        >
                          <option value="Nobody">Nobody</option>
                          <option value="Everyone">Everyone</option>
                          <option value="Selected User">Selected User</option>
                        </select>
                      </TableCell>
                      <TableCell>
                        <img src={bank.logoUrl} style={{ width: '20px', marginRight: '8px' }} />
                        {bank.bankName || "N/A"}
                      </TableCell>
                      <TableCell>
                        {bank.accountNumber || "N/A"} 
                        <IconButton onClick={() => copyToClipboard(bank.accountNumber)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                        <br />
                        {bank.ifscCode || "N/A"}
                        <IconButton onClick={() => copyToClipboard(bank.ifscCode)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{bank.branchName || "N/A"}</TableCell>
                      <TableCell>
                        {Array.isArray(bank.allowedModes)
                          ? bank.allowedModes.join(", ")
                          : bank.allowedModes || "N/A"}
                      </TableCell>
                      <TableCell>{bank.minimumDeposit || "N/A"}</TableCell>
                      <TableCell>{bank.maximumDeposit || "N/A"}</TableCell>
                      <TableCell>
                        <button
                          className={global.edit_btn}
                          onClick={() => handleEditBank(bank)}
                        >
                          Edit
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                No Bank Found
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                Sorry, we came up empty-handed.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setModalOpen(true)}
              >
                Add Bank Account
              </Button>
            </Box>
          )}

          {/* Add Bank Modal */}
          <AddBankModal
            open={modalOpen}
            onClose={() => {
              setSelectedBank(null);
              setModalOpen(false);
            }}
            onSubmit={handleAddOrEditBank}
            bank={selectedBank}
          />
          <SelectedUserModal
  open={selectedUserModalOpen}
  onClose={() => setSelectedUserModalOpen(false)}
  onSubmit={(users) => {
    console.log("Selected Users:", users);
    setSelectedUserModalOpen(false);
  }}
  dummyUsers={dummyUsers}
/>

        </Box>
      </div>
    </div>
  );
};

export default AddBankComponent;
