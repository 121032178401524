import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  MenuItem,
  TablePagination,
  Paper
} from "@mui/material";
import global from "../../assets/scss/global.module.scss"; // Assuming global CSS module for pagination styling

const AccountStatementReport = () => {
  const [filter, setFilter] = useState({
    searchBy: "",
    searchText: "",
    bank: "",
    status: "",
    fromDate: "",
    toDate: "",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const searchByOptions = ["Transaction ID", "Category", "Operator"];
  const banks = ["Bank A", "Bank B", "Bank C"];

  const rows = [
    {
      transactionId: "TXN0012345",
      dateTime: "2024-12-21 12:45 PM",
      category: "Category: Recharges\nSub Category: Recharge 1\nProduct: DTH",
      operator:
        "Operator Name: TATA SKY\nOperator ID: 001\nCA Number: 1234567890\nPhone: 9870291036",
      transactionDetails:
        "Txn. Amount: ₹500\nCharges: ₹3.45\nGST: ₹0.19\nCommission: ₹0.00\nTDS: ₹0.00",
      remarks: "Successful",
      status: "Approved",
    },
    // Add more rows as needed for testing pagination
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleSearch = () => {
    console.log("Search clicked with filters:", filter);
  };

  const handleClear = () => {
    setFilter({
      searchBy: "",
      searchText: "",
      bank: "",
      status: "",
      fromDate: "",
      toDate: "",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className= { `${global.gray_bg} ${global.pad_16} `}>

    <div class="container-fluid">
          <div className={ `${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
             <Typography variant="h1"  gutterBottom > Account Statement Report</Typography>
          </div>
    
          <div className= { `${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16}  `} >
          <Box display="flex"  gap={1} flexWrap="wrap">

        <TextField
          select
          label="Search By"
          name="searchBy"
          value={filter.searchBy}
          onChange={handleFilterChange}
          variant="outlined"
          sx={{ width: "150px" }}
        >
          {searchByOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Enter Value"
          name="searchText"
          value={filter.searchText}
          onChange={handleFilterChange}
          variant="outlined"
          sx={{ width: "150px" }}
        />
        <TextField
          select
          label="Search by Bank"
          name="bank"
          value={filter.bank}
          onChange={handleFilterChange}
          variant="outlined"
          sx={{ width: "150px" }}
        >
          {banks.map((bank) => (
            <MenuItem key={bank} value={bank}>
              {bank}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Status"
          name="status"
          value={filter.status || ""}
          onChange={handleFilterChange}
          variant="outlined"
          sx={{ width: "150px" }}
        >
          {["Approved", "Pending", "Rejected"].map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="From Date"
          type="date"
          name="fromDate"
          value={filter.fromDate || ""}
          onChange={handleFilterChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: "150px" }}
        />
        <TextField
          label="To Date"
          type="date"
          name="toDate"
          value={filter.toDate || ""}
          onChange={handleFilterChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: "150px" }}
        />
       <button className={ `${global.clear_btn} ${global.lead_common_btn} `} onClick={handleClear}>Clear</button>
  <button className={ `${global.submit_btn_1} ${global.lead_common_btn} `}  onClick={handleSearch}> Search <i className={global.lead_search_icon}></i></button>
  <button className={ `${global.download_btn_1} ${global.lead_common_btn} `} onClick={() => console.log("Download clicked")}>Download <i className={global.lead_download_icon}></i></button>

      </Box>
      </div>


      <TableContainer component={Paper}>
                   <Table>
                      <TableHead style={{background: "#EAF6FF"}}>
            <TableRow>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Date and Time</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Operators</TableCell>
              <TableCell>Transaction Details</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.transactionId}</TableCell>
                  <TableCell>{row.dateTime}</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>{row.category}</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>{row.operator}</TableCell>
                  <TableCell style={{ whiteSpace: "pre-wrap" }}>{row.transactionDetails}</TableCell>
                  <TableCell>{row.remarks}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    </div>
  );
};

export default AccountStatementReport;
