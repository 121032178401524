import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Modal,
  IconButton
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import global from "../../assets/scss/global.module.scss";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Dummy Data for Dropdowns
const banks = ["HDFC", "SBI", "ICICI", "AXIS"];
const searchByOptions = ["Transaction ID", "Entity Name", "UTR"];

const FundRequestTable = () => {
  // States for filtering
  const [filter, setFilter] = useState({
    searchBy: "",
    searchText: "",
    bank: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const [data, setData] = useState([]); // Data fetched from the API
  const [loading, setLoading] = useState(false); // Loading state
  const [modalOpen, setModalOpen] = useState(false); // Modal open state
  const [selectedRow, setSelectedRow] = useState(null); // Data for the selected row
  const [approvalStatus, setApprovalStatus] = useState(""); // Approval status state
  const [remark, setRemark] = useState(""); // Remark state
  const [confirmApprove, setConfirmApprove] = useState(false); // Confirm approve state

  // Total pages
  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  // Handle search
  const handleSearch = () => {
    console.log("Searching with filters:", filter);
    // Add your filter logic here
  };

  // Handle clearing filters
  const handleClear = () => {
    setFilter({ searchBy: "", searchText: "", bank: "" });
  };

  // Fetch fund requests from the API
  useEffect(() => {
    const fetchFundRequests = async () => {
      setLoading(true); // Start loading
      try {
        const response = await fetcher.get(ApiUrls.FETCH_fUND_REQUESTS);
        if (response?.status === 200 && Array.isArray(response.response)) {
          setData(response.response);
        } else {
          console.error("Failed to fetch fund requests:", response.message);
        }
      } catch (error) {
        console.error("Error fetching fund requests:", error);
      } finally {
        setLoading(false); // End loading
      }
    };
    fetchFundRequests();
  }, []);

  // Handle modal open
  const handleModalOpen = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  // Handle modal close
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  // Handle approval status change
  const handleApprovalChange = (event) => {
    const value = event.target.value;
    if (value === "approve") {
      setConfirmApprove(true);
    } else {
      setApprovalStatus(value);
    }
  };

  // Handle confirm action
  const handleConfirm = () => {
    console.log("Approval Status:", approvalStatus);
    console.log("Remark:", remark);
    console.log("Selected Row:", selectedRow);
    // Add your confirm logic here
    handleModalClose();
  };

  // Handle approve confirmation
  const handleApproveConfirm = (confirm) => {
    if (confirm) {
      setApprovalStatus("approve");
    }
    setConfirmApprove(false);
  };

  // Copy to clipboard function
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  // Function to convert amount to words using Indian place value system
  const numberToWords = (num) => {
    const a = [
      "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    const b = [
      "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];
    const g = [
      "", "Thousand", "Lakh", "Crore"
    ];

    const makeGroup = ([ones, tens, huns]) => {
      return [
        a[huns] ? a[huns] + " Hundred " : "",
        b[tens] ? b[tens] + (ones ? "-" : "") : "",
        a[ones] || ""
      ].join("");
    };

    const thousand = (group, i) => group === "" ? group : `${group} ${g[i]}`;

    if (typeof num === "number") {
      let strNum = num.toString();
      const len = strNum.length;
      const groups = [];

      if (len > 3) {
        groups.push(strNum.slice(-3));
        strNum = strNum.slice(0, -3);
      }

      while (strNum.length > 2) {
        groups.push(strNum.slice(-2));
        strNum = strNum.slice(0, -2);
      }

      groups.push(strNum);

      return groups.reverse().map((group, i) => makeGroup(group.split("").reverse().map(Number))).map(thousand).filter(Boolean).reverse().join(" ");
    }

    return "";
  };

  return (
    <div className={`${global.gray_bg} ${global.pad_16}`}>
      <div className="container-fluid">
        <div className={`${global.white_bg} ${global.pad_16}`}>
          <Typography variant="h1" gutterBottom>
            Fund Request
          </Typography>
        </div>
        <div
          className={`${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16}`}
        >
          {/* Header Section */}
          <Box display="flex" alignItems="center" gap={1} mb={3}>
            <TextField
              select
              label="Search By"
              name="searchBy"
              value={filter.searchBy}
              onChange={handleFilterChange}
              variant="outlined"
              style={{ width: "120px", marginRight: "8px" }}
            >
              {searchByOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Enter Value"
              name="searchText"
              value={filter.searchText}
              onChange={handleFilterChange}
              variant="outlined"
              style={{ width: "150px", marginRight: "8px" }}
            />
            <TextField
              select
              label="Search by Bank"
              name="bank"
              value={filter.bank}
              onChange={handleFilterChange}
              variant="outlined"
              style={{ width: "150px", marginRight: "8px" }}
            >
              {banks.map((bank) => (
                <MenuItem key={bank} value={bank}>
                  {bank}
                </MenuItem>
              ))}
            </TextField>
            <button
              className={`${global.clear_btn} ${global.lead_common_btn}`}
            >
              New Request
            </button>
            <button
              className={`${global.clear_btn} ${global.lead_common_btn}`}
            >
              Approved
            </button>
            <button
              className={`${global.clear_btn} ${global.lead_common_btn}`}
            >
              Rejected
            </button>
            <button
              className={`${global.submit_btn_1} ${global.lead_common_btn}`}
              onClick={handleSearch}
            >
              Search <i className={global.lead_search_icon}></i>
            </button>
            <button
              className={`${global.clear_btn} ${global.lead_common_btn}`}
              onClick={handleClear}
            >
              Clear
            </button>
          </Box>
        </div>

        {/* Table Section */}
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ background: "#EAF6FF" }}>
                <TableRow>
                  <TableCell>Date & Time</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Entity Name</TableCell>
                  <TableCell>Bank</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Transfer Date</TableCell>
                  <TableCell>Branch</TableCell>
                  <TableCell>Charge/Commission</TableCell>
                  <TableCell>UTR</TableCell>
                  <TableCell>Deposit Slip</TableCell>
                  <TableCell>Remark</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(
                    (currentPage - 1) * rowsPerPage,
                    currentPage * rowsPerPage
                  )
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {new Date(row.createdAt * 1000).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {row.id}
                        <IconButton onClick={() => copyToClipboard(row.id)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.depositorMobile}</TableCell>
                      <TableCell>
                        <img src={row.bankLogoUrl}  style={{ width: '20px', marginRight: '8px' }} />
                        {row.bankAccount}
                        <br />
                        <Typography variant="caption">{row.accountNumber || "XXXXX"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.amount}</Typography>
                        <Typography >{numberToWords(row.amount)}</Typography>
                        <IconButton onClick={() => copyToClipboard(row.amount)} style={{ float: 'right' }}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {new Date(row.transferDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{row.depositBranch}</TableCell>
                      <TableCell>{row.charge}</TableCell>
                      <TableCell>
                        {row.utrReference}
                        <IconButton onClick={() => copyToClipboard(row.utrReference)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.acknowledgementSlip}</TableCell>
                      <TableCell>{row.remarks}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleModalOpen(row)}
                        >
                          Action
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Modal Section */}
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800, // Medium size
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Fund Request Details
            </Typography>
            {selectedRow && (
              <Box mt={2} display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                <Typography><strong>Transaction ID:</strong> {selectedRow.id}</Typography>
                <Typography><strong>UTR:</strong> {selectedRow.utrReference}</Typography>
                <Typography>
                  <strong>Amount:</strong> {selectedRow.amount}
                  <IconButton onClick={() => copyToClipboard(selectedRow.amount)} style={{ float: 'right' }}>
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                  <br />
                  <Typography variant="caption">{numberToWords(selectedRow.amount)}</Typography>
                </Typography>
                <Typography>
                  <strong>Bank:</strong> 
                  <img src={selectedRow.bankLogoUrl} alt="Bank Logo" style={{ width: '20px', marginRight: '8px' }} />
                  {selectedRow.bankAccount}
                  <br />
                  <Typography variant="caption">{selectedRow.accountNumber || "XXXXX"}</Typography>
                </Typography>
                <Typography><strong>Transfer Date:</strong> {new Date(selectedRow.transferDate).toLocaleDateString()}</Typography>
                <Typography><strong>Branch:</strong> {selectedRow.depositBranch}</Typography>
                <Typography><strong>Charge/Commission:</strong> {selectedRow.charge}</Typography>
                <Typography><strong>Deposit Slip:</strong> {selectedRow.acknowledgementSlip}</Typography>
                <Typography><strong>Remark:</strong> {selectedRow.remarks}</Typography>
              </Box>
            )}
            <Box mt={2} display="flex" justifyContent="space-between">
              <label>
                <input
                  type="radio"
                  value="approve"
                  checked={approvalStatus === "approve"}
                  onChange={handleApprovalChange}
                />
                Approve
              </label>
              <label>
                <input
                  type="radio"
                  value="reject"
                  checked={approvalStatus === "reject"}
                  onChange={handleApprovalChange}
                />
                Reject
              </label>
            </Box>
            <TextField
              label="Remark"
              placeholder="Enter remark"
              fullWidth
              multiline
              rows={3}
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              sx={{ mt: 2 }}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                onClick={handleConfirm}
                sx={{ backgroundColor: "#06226A" }}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                onClick={handleModalClose}
                sx={{ borderColor: "#06226A", color: "#06226A" }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Approve Confirmation Modal */}
        <Modal
          open={confirmApprove}
          onClose={() => setConfirmApprove(false)}
          aria-labelledby="confirm-approve-title"
          aria-describedby="confirm-approve-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="confirm-approve-title" variant="h6" component="h2">
              Are you sure the amount has been credited to the bank account?
            </Typography>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                onClick={() => handleApproveConfirm(true)}
                sx={{ backgroundColor: "#06226A" }}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleApproveConfirm(false)}
                sx={{ borderColor: "#06226A", color: "#06226A" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Custom Pagination Section */}
        <div className={`${global.pageination}`}>
          <div className={global.row}>
            <div className={global.col_s_12}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, page) => setCurrentPage(page)}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#EAF6FF",
                    borderRadius: "2px",
                    border: "solid #0682FF 1px",
                    color: "#06226A",
                  },
                  "& .MuiPaginationItem-root:hover": {
                    backgroundColor: "#EAF6FF",
                    color: "#06226A",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundRequestTable;
