import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import global from '../../assets/scss/global.module.scss';
import fetcher from "../../utils/fetcher";
import { ApiUrls } from '../../utils/constants';
import Loader from '../../utils/Loader';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Phone } from "@mui/icons-material";


const ProviderDetails = ({ listener  }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { providerData } = location.state || {};
  // Validation schema with Yup
  const validationSchema = Yup.object().shape({
    providerCode: Yup.string().required('Provider code is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    aliasName: Yup.string().required('Alias Name is required'),
    entityName: Yup.string().required('Entity Name is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
  });

  // Initial values for Formik
  const initialValues = {
    providerUserId: providerData?.providerUserId || '',
    providerCode: providerData?.providerCode || '',
    firstName: providerData?.firstName || '',
    lastName: providerData?.lastName || '',
    aliasName: providerData?.aliasName || '',
    entityName: providerData?.entityName || '',
    email: providerData?.email || '',
    mobile: providerData?.mobile || '',
  };

  // Submit handler with API call
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = await  fetcher.post(`${ApiUrls.CREATE }`, values);
      if (data?.status === 200) {
        listener(data?.response);
      }else if (data?.status !== 200) {
        toast.error(data?.message || "Error submitting form");
      }
    //  console.log('Form submitted successfully', response.data);
    } catch (error) {
    //  console.error('Error submitting form', error);
    
    } finally {
      setLoading(false);
    }
  };
  const fetchProviderDetail = async () => {
    setLoading(true)
    try {
      const url = `${ApiUrls.PROVIDER_DETAILS}?id=${providerData.providerUserId}`;
      const data = await fetcher.get(url);
      if (data?.status === 200) {
        listener(data.response);
      } else {
        console.error("Failed to fetch schemes:", data.message);
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    if(providerData){
      fetchProviderDetail();
    }
  }, []);
  return (
    <div>
      <div className={global.wrapper_form}>
      {loading && <Loader />}
      <ToastContainer />
    
        <h3>01 Provider Details</h3>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, handleBlur, values }) => (
            <Form>
              <div className={`${global.row} ${global.mar_top_24}`}>
                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Provider code</label>
                    <Field
                      name="providerCode"
                      className={global.form_control}
                      placeholder="Provider code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.providerCode}
                    />
                    <ErrorMessage
                      name="providerCode"
                      component="div"
                      className={global.error_text}
                    />
                  </div>
                </div>

                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Entity Name</label>
                    <Field
                      name="entityName"
                      className={global.form_control}
                      placeholder="Entity Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.entityName}
                    />
                    <ErrorMessage
                      name="entityName"
                      component="div"
                      className={global.error_text}
                    />
                  </div>
                </div>
                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>First Name</label>
                    <Field
                      name="firstName"
                      className={global.form_control}
                      placeholder="First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className={global.error_text}
                    />
                  </div>
                </div>
                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Last Name</label>
                    <Field
                      name="lastName"
                      className={global.form_control}
                      placeholder="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className={global.error_text}
                    />
                  </div>
                </div>

                
              </div>
              <div className={`${global.row} ${global.mar_top_24}`}>
              <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Alias Name</label>
                    <Field
                      name="aliasName"
                      className={global.form_control}
                      placeholder="Enter Alias Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.aliasName}
                    />
                    <ErrorMessage
                      name="aliasName"
                      component="div"
                      className={global.error_text}
                    />
                  </div>
                </div>
                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Email</label>
                    <Field
                      name="email"
                      className={global.form_control}
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={global.error_text}
                    />
                  </div>
                </div>

                

                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Phone Number</label>
                    <Field
                      name="mobile"
                      className={global.form_control}
                      placeholder="Phone Number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile}
                    />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className={global.error_text}
                    />
                  </div>
                </div>

                
              </div>
              <div className={`${global.row} ${global.mar_top_16}`}>
                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <button
                      type="submit"
                      className={global.submit_primary_btn}
                      disabled={loading}
                    >
                      {loading ? 'Submitting...' : 'Confirm'}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProviderDetails;
