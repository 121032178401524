import React, { useState, useEffect } from 'react';
import global from '../../assets/scss/global.module.scss';
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';

const UserApplyScheme = ({ userData }) => {
  const [schemes, setSchemes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchLeadUserScheme = async (page) => {
    setLoading(true);
    try {
      const url = `${ApiUrls.FETCH_APPLIED_SCHEME}?page=${page - 1}&appliedUserId=${userData.leadUserId}&userType=1`;
      const response = await fetcher.get(url);
      if (response?.status === 200 && response.response) {
        setCurrentPage(page);
        setSchemes(response.response);
      } else {
        console.error("Failed to fetch schemes:", response.message);
      }
    } catch (error) {
      console.error("Error fetching schemes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeadUserScheme(currentPage);
  }, [currentPage]);

  return (
    <div className={global.wrapper_form}>
      <h3 className={global.mar_bottom_20}>Apply Scheme</h3>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className={global.mar_top_16} cellPadding="5" borderColor="#eeeeee" cellSpacing="0" border="1" style={{ borderCollapse: "collapse" }}>
          <thead style={{ background: "#eeeeee" }}>
            <tr>
              <th>Scheme Name</th>
              <th>ID</th>
              <th>Category</th>
              <th>Product</th>
              <th>Date of Creation</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {schemes.map((scheme) => (
              <tr key={scheme.id}>
                <td>{scheme.name}</td>
                <td>{scheme.id}</td>
                <td>{scheme.cat}</td>
                <td>{scheme.subcat}</td>
                <td>{scheme.dateOfCreation}</td>
                <td>{scheme.desc}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default UserApplyScheme;
