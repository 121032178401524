import React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { canAccessTheRouteWithUserAuthorities, isAuthenticated, isRestrictedRoutWithAuthority, setRedirectLoginCallback } from "../utils/authGuard";
import Layout from "../components/layout/Layout";  // Import the Layout HOC
import Login from "../pages/login/Login";
import Index from "../pages/Index";
import { NoMatch } from "../pages/NoMatch";
import { PageUrls } from "../utils/constants";
import Leads from "../pages/leads/Leads";
import NewLeads from "../pages/leads/NewLeads";
import ApiUsers from "../pages/leads/ApiUsers";
import ESignRequest from "../pages/leads/eSign/ESignRequest";
import LeadDetail from "../pages/leads/detail/LeadsDetail";
import AddProvider from "../pages/provider/AddProvider";
import ESignRequestNew from "../pages/leads/ESignRequestNew";
import Dashboard from "../pages/dashboard/dashBoard";
import SchemeAdmin from "../pages/SchemeAdmin/SchemeAdmin";
import SchemeListPage from "../pages/SchemeAdmin/SchemeList";
import ESignRequestDetail from "../pages/leads/eSign/ESignRequestDetail";
import ProviderList from "../pages/provider/ProviderList";
import APILEADDETAILS from "../pages/leads/detail/ApiUserDetail";
import AddNewBank from "../pages/funds/AddNewBank";
import FundRequest from "../pages/funds/FundRequest";
import DeveloperZone from "../pages/developer/developerZone";
import FundRequestTable from "../pages/analytics/FundRequestReport";
import FundRequestReport from "../pages/analytics/FundRequestReport";
import FundFlowRequest from "../pages/analytics/FundFlowReport";
import AccountStatementReport from "../pages/analytics/AccountStatementReport";
import WalletLedger from "../pages/analytics/WalletLedger";
import RecoveryModuleReport from "../pages/analytics/RecoveryModule";
import FundFlow from "../pages/funds/FundFlow";
import Lien from "../pages/funds/Liean";



function PrivateRoute({ element }) {
  const location = useLocation();
  if (!isAuthenticated()) {
    return <Navigate to={PageUrls.LOGIN} />;
  }
  if (isRestrictedRoutWithAuthority(location.pathname) && !canAccessTheRouteWithUserAuthorities(location.pathname)) {
    return <Navigate to={PageUrls.UNAUTHORIZED} />
  }
  return element;
}

const Routers = () => {
    const navigate = useNavigate();
    setRedirectLoginCallback(() => {
      navigate(PageUrls.LOGIN);
    });
    
    return(
        <Routes>
          <Route path={PageUrls.LOGIN} element={<Login />}/>
          <Route path={PageUrls.INDEX} element={<Index />}/>
          <Route path={PageUrls.LEADS} element={<PrivateRoute element={ <Layout> <Leads /> </Layout> } />} />
          <Route path={PageUrls.DASHBOARD} element={<PrivateRoute element={<Layout> <Dashboard /> </Layout>} />} />
          <Route path={PageUrls.NEWLEADS} element={<PrivateRoute element={<Layout> <NewLeads /> </Layout>} />} />
          <Route path={PageUrls.APIUSER} element={<PrivateRoute element={ <Layout><ApiUsers /></Layout> } />} />
          {/* <Route path={PageUrls.ESIGNREQ} element={<ESignRequest  />}/> */}
          <Route path={PageUrls.ESIGNREQ} element={<Layout><ESignRequestNew  /></Layout>}/>
          <Route path={PageUrls.LEADSDETAILS} element={<PrivateRoute element={ <LeadDetail /> } />} />
          <Route path={PageUrls.ADD_PROVIDER} element={<PrivateRoute element={ <Layout><AddProvider /> </Layout>} />} />
          <Route path={PageUrls.PROVIDER_LIST} element={<PrivateRoute element={ <Layout><ProviderList /> </Layout>} />} />
          <Route path={PageUrls.SCHEMEADMIN} element={<PrivateRoute element={ <Layout><SchemeAdmin /></Layout> } />} />
          < Route path={PageUrls.SCHEMELIST} element={<PrivateRoute element={ <Layout><SchemeListPage /></Layout> } />} />
          {/* <Route path={PageUrls.ESIGNREQ} element={<ESignRequest />} /> */}
          <Route path={PageUrls.ESIGNREQ_DETAIL} element={<ESignRequestDetail />} />
          <Route path={PageUrls.LEADSDETAILS} element={<LeadDetail />} />
          <Route path={PageUrls.APILEADDETAILS} element={<APILEADDETAILS />} />
          <Route path={PageUrls.ADD_PROVIDER} element={<AddProvider />} />
          <Route path={PageUrls.Developer_Zone} element={<Layout><DeveloperZone /> </Layout>} />
          <Route path={PageUrls.ADD_NEW_BANK} element={<Layout><AddNewBank/></Layout> } />
          <Route path={PageUrls.FUND_REQUEST} element={<Layout><FundRequest/></Layout> } />
          <Route path={PageUrls.FUND_REQUEST_REPORT} element={<Layout><FundRequestReport/></Layout> } />
          <Route path={PageUrls.ACCOUNT_STATEMENT_REPORT} element={<Layout><AccountStatementReport/></Layout> } />
          <Route path={PageUrls.WALLET_LEDGER} element={<Layout><WalletLedger/></Layout> } />
          <Route path={PageUrls.FUND_FLOW_REPORT} element={<Layout><FundFlowRequest/></Layout> } />
          <Route path={PageUrls.RECOVERY_REPORT} element={<Layout><RecoveryModuleReport/></Layout> } />
          <Route path={PageUrls.LIEN} element={<Layout><Lien/></Layout> } />
          <Route path={PageUrls.FUND_FLOW} element={<Layout><FundFlow/></Layout> } />

          <Route path="*" element={<NoMatch />} />
        </Routes>
    )
}

export default Routers;
