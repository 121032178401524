import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ProductionAccess = () => {
  const [apiKey, setApiKey] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const [showApiKey, setShowApiKey] = useState(false);
  const [showAccessKey, setShowAccessKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    alert("Copied to clipboard!");
  };

  const handleSubmit = () => {
    console.log("API Key:", apiKey);
    console.log("Access Key:", accessKey);
    console.log("Secret Key:", secretKey);
  };

  const renderKeyField = (label, value, setValue, showValue, setShowValue) => (
    <TextField
      variant="outlined"
      placeholder={`Enter ${label}`}
      type={showValue ? "text" : "password"}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      fullWidth
      InputProps={{
        style: { backgroundColor: "#FFFFFF", borderRadius: 4 },
        endAdornment: (
          <>
            <IconButton
              onClick={() => setShowValue((prev) => !prev)}
              sx={{ color: "#06226A" }}
            >
              {showValue ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            <IconButton
              onClick={() => handleCopy(value)}
              sx={{ color: "#06226A" }}
            >
              <ContentCopyIcon />
            </IconButton>
          </>
        ),
      }}
    />
  );

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: 4,
        borderRadius: 2,
        color: "#06226A",
      }}
    >
    
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom style={{
            fontWeight: "600"
          }}>
            API Key
          </Typography>
          {renderKeyField("API Key", apiKey, setApiKey, showApiKey, setShowApiKey)}
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom style={{
            fontWeight: "600"
          }}>
            Access Key
          </Typography>
          {renderKeyField("Access Key", accessKey, setAccessKey, showAccessKey, setShowAccessKey)}
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom style={{
            fontWeight: "600"
          }}>
            Secret Key
          </Typography>
          {renderKeyField("Secret Key", secretKey, setSecretKey, showSecretKey, setShowSecretKey)}
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#06226A",
                "&:hover": {
                  backgroundColor: "#06226A",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductionAccess;
