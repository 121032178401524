import React, { useEffect, useState } from 'react';
import { Typography, Select, MenuItem, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, FormControl, InputLabel } from '@mui/material';
import fetcher from '../../utils/fetcher';
import { PageUrls, ApiUrls, options } from "../../utils/constants";
import { ENTITY_NAME_OPTIONS } from '../../utils/dropdown'
import { useNavigate } from 'react-router-dom';
import LeadDetail from './detail/LeadsDetail';
import global from '../../assets/scss/global.module.scss'
import Loader from '../../utils/Loader';
import { getClassForStatus } from './Leads';

const OnBoardingUser = () => {
  const navigate = useNavigate();
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [leads, setLeads] = useState([]);

  const [filters, setFilters] = useState({
    filterType: 'entityName',
    filterValue: '',
    status: '',
    startDate: '',
    endDate: '',
  });


  const [statuses] = useState(['ACTIVE', 'INACTIVE']);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLeadClicked, setLeadClicked] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setupLogin();
    //localStorage.setItem('auth', JSON.stringify("eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4ODAwNjMxODU3IiwiZW1haWwiOiJhcnVuQGdtYWlsLmNvbSIsImlkIjoxLCJyb2xlIjpbIkFkbWluIl0sImlhdCI6MTczMTUyMzA0MCwiZXhwIjoxNzMyMTI3ODQwfQ.cjZSATeHTJ9wyzlW_O4izFKv9DdFW7zpc6LWPGXjEUQ"));

    fetchLeads(currentPage);
  }, [currentPage]);

  const setupLogin = async () => {
    const adminData = {
      leadUserId: 1,
      email: "arun@gmail.com",
      mobile: "8800631857",
      role: ["Admin"],
    }
    try {
      const res = await fetcher.post(ApiUrls.AUTHENTICATE, adminData);
      localStorage.setItem('auth', JSON.stringify(res));

    } catch (err) {
    }
  }

  const fetchLeads = async (page) => {

    try {
      setLoading(true)
      const data = await fetcher.get(
        `${ApiUrls.All_LEADS}?page=${page - 1}&size=${itemsPerPage}&field=leadStatus&value=Fresh,Resubmission`
      );

      if (data?.status === 200) {
        setCurrentPage(page);
        setTotalPages(data?.response.totalPages);
        setLeads(data.response.userLeads);
        setFilteredLeads(data.response.userLeads);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
    setLoading(false)
  };



  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const clearFilters = () => {
    setFilters({
      filterType: 'entityName',
      filterValue: '',
      status: '',
      startDate: '',
      endDate: '',
    });
    setFilteredLeads(leads);
  };

  const searchFilters = async () => {
    setLoading(true);
    try {
      const { filterType, filterValue, status, startDate, endDate } = filters;

      // Build query parameters dynamically
      const params = new URLSearchParams();
      if (filterType && filterValue) {
        params.append('field', filterType);
        params.append('value', filterValue);
      }
      if (status) params.append('status', status);
      if (startDate) params.append('startDate', new Date(startDate).toISOString());
      if (endDate) params.append('endDate', new Date(endDate).toISOString());

      // Fetch leads based on filters
      const url = `${ApiUrls.All_LEADS}?page=${currentPage - 1}&size=${itemsPerPage}&field=leadStatus&value=Fresh,Resubmission&${params.toString()}`;
      const data = await fetcher.get(url);

      if (data?.status === 200) {
        setFilteredLeads(data.response.userLeads);
        setTotalPages(data.response.totalPages);
      }
    } catch (error) {
      console.error('Error fetching filtered leads:', error);
    }
    setLoading(false);
  };





  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === options[0]) navigate(PageUrls.LEADS);
    else if (e.target.value === options[1]) navigate(PageUrls.NEWLEADS);
    else if (e.target.value === options[2]) navigate(PageUrls.ESIGNREQ);
    else if (e.target.value === options[3]) navigate(PageUrls.APIUSER);
  };

  const handleDownload = () => {
    const headers = [
      'Date & Time',
      'Lead ID',
      'Entity Name / Auth. Person Name',
      'Email ID',
      'Phone Number',
      'Aadhaar Number',
      'Person PAN',
      'Entity Pan',
      'GSTIN'
    ];

    const rows = filteredLeads.map((lead) => [
      new Date(lead.createdAt * 1000).toLocaleString(),
      lead.leadUserId,
      `${lead.companyName} / ${lead.authorizedPersonName}`,
      lead.email,
      lead.mobile,
      lead.companyWebsite,
      lead.message,
      lead.onboardingLink,
      lead.status
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map((row) => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'leads_data.csv';
    link.click();
  };


  const openLeadDetail = (leadId) => {
    setSelectedLeadId(leadId);

    setLeadClicked(true);

  };



  return (
    <div className={`${global.gray_bg} ${global.pad_16} `}>
      {loading && <Loader />}

      <div class="container-fluid">
        <div className={`${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
          <Typography variant="h1" gutterBottom > New OnBoarding Request</Typography>
        </div>

        {!isLeadClicked && (
          <>
            <div className={`${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16}  `} >


              <FormControl variant="outlined" style={{ width: "120px", marginRight: "8px" }}>
                <InputLabel>Filter Type</InputLabel>
                <Select name="filterType" value={filters.filterType} onChange={handleChange} label="Filter Type">
                  {ENTITY_NAME_OPTIONS.map((type) => (
                    <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                variant="outlined"
                name="filterValue"
                label={`Enter ${filters.filterType === 'entityName' ? 'Entity Name' : filters.filterType}`}
                value={filters.filterValue}
                onChange={handleChange}
              />

              <FormControl variant="outlined" style={{ width: "120px", marginRight: "8px", marginLeft: "8px" }}>
                <InputLabel>Status</InputLabel>
                <Select name="status" value={filters.status} onChange={handleChange} label="Status">
                  <MenuItem value="">Select Status</MenuItem>
                  {statuses.map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                variant="outlined"
                type="date"
                name="startDate"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                value={filters.startDate}
                onChange={handleChange}

                style={{ marginRight: "5px" }}
              />

              <TextField
                variant="outlined"
                type="date"
                name="endDate"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                value={filters.endDate}
                onChange={handleChange}
              />

              <button className={`${global.clear_btn} ${global.lead_common_btn} `} onClick={clearFilters}>Clear</button>
              <button className={`${global.submit_btn_1} ${global.lead_common_btn} `} onClick={searchFilters}> Search <i className={global.lead_search_icon}></i></button>
              <button className={`${global.download_btn_1} ${global.lead_common_btn} `} onClick={handleDownload}>Download <i className={global.lead_download_icon}></i></button>
            </div>

            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ background: "#EAF6FF" }}>
                  <TableRow>
                    <TableCell>Date <br /> Time</TableCell>
                    <TableCell>Lead ID</TableCell>
                    <TableCell>Executed <br /> by</TableCell>
                    <TableCell>Entity Type</TableCell>
                    <TableCell>Entity Name <br /> Auth. Person Name</TableCell>
                    <TableCell>Email ID <br />Phone Number</TableCell>
                    {/* <TableCell style={{ width: "170px" }}>Phone Number</TableCell> */}
                    <TableCell>Aadhar Number <br /> Pan Number</TableCell>

                    <TableCell>GSTIN<br />Pan Number</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={global.gray_lead_row}>
                  {filteredLeads.map((lead) => (
                    <TableRow key={lead.leadUserId} onClick={() => openLeadDetail(lead.leadUserId)} style={{ cursor: 'pointer' }}>
                      <TableCell>{new Date(lead.createdAt * 1000).toLocaleDateString()}<br />
                        {new Date(lead.createdAt * 1000).toLocaleTimeString()}</TableCell>
                      <TableCell>{lead.leadUserId}</TableCell>
                      <TableCell>Rajeev <br />{new Date(lead.createdAt * 1000).toLocaleDateString()}<br />
                        {new Date(lead.createdAt * 1000).toLocaleTimeString()}</TableCell>
                      <TableCell>Individual</TableCell>
                      <TableCell > {lead.companyName} <br />{lead.authorizedPersonName}</TableCell>
                      <TableCell><span className={global.d_flex} title={lead.email}><span className={global.text_truncation}>{lead.email}</span> <i className={global.green_check_icon}></i></span><br />{lead.mobile} <i className={global.green_check_icon}></i></TableCell>
                      {/* <TableCell>{lead.mobile} <i className={global.green_check_icon}></i></TableCell> */}
                      <TableCell > {lead.leadAadhaarDetails.aadharNumber}<br />{lead.leadPanDetails.panNumber}</TableCell>
                      <TableCell > {lead.leadBusinessDetails.pan}<br /> {lead.leadBusinessDetails.gstin}</TableCell>
                      <TableCell>
                        <div className={getClassForStatus(lead.leadStatus)}>
                          {lead.leadStatus}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className={`${global.pageination}`}>
              <div className={global.row}>
                <div className={global.col_s_12}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(e, page) => setCurrentPage(page)}
                    color="primary"

                    sx={{
                      '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: '#EAF6FF', // Change to your preferred color
                        borderRadius: '2px',
                        margin: '0',
                        border: 'solid #0682FF 1px;',
                        color: '#06226A',
                      },
                      '& .MuiPaginationItem-root:hover': {
                        backgroundColor: '#EAF6FF', // Change to your preferred color
                        borderRadius: '0',
                        border: 'none',
                        color: '#06226A',       // Hover text color (darker blue)
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {isLeadClicked && <LeadDetail leadId={selectedLeadId} />}
      </div>
    </div>
  );
}

export default OnBoardingUser;
