export const PageUrls = {
  TICKET_DETAIL: '/ticket/ticket-detail/:id',
  UNAUTHORIZED: '/unauthorized',
  INDEX: '/',
  LOGIN: '/login',
  LEADS: '/leads',
  LEADSDETAILS: '/lead_details',
  APILEADDETAILS: "/api-user-details",
  NEWLEADS: '/new_leads',
  ESIGNREQ: '/esign_request',
  APIUSER: '/api_user',
  ADD_PROVIDER: '/add-provider',
  DASHBOARD: '/dashboard',
  SCHEMELIST: "/scheme_list",
  ESIGNREQ_DETAIL: '/esign_request_detail/:id',
  ESIGNREQ_CLIENT: '/esign_request_client',
  CLIENT_AGREEMENT: '/client-agreement/:param1/:param2/:param3',
  PROVIDER_LIST: "/provider-list",
  Developer_Zone: "/dev-zone",


  OTP_VERIFICATION: "/esign_otp_verification/:param1/:param2",
  SCHEMEADMIN: '/admin_scheme',
  ADD_NEW_BANK: "/add-new-bank",
  FUND_REQUEST: "/fund-request",
  FUND_REQUEST_REPORT: "/fund-request-report",
  FUND_FLOW_REPORT: "/fund-flow-report",
  ACCOUNT_STATEMENT_REPORT: "/account-statement-report",
  WALLET_LEDGER: "/wallet-ledger",
  RECOVERY_REPORT: "/recovery-module-report",
  FUND_FLOW: "/fund-flow",
  LIEN : "/lien",
 
};

export const ApiUrls = {
  LEAD_BASIC_REGISTRATION: '/leads/createlead',
  LEAD_GENRATE_OTP: '/leads/otp/generate',

  All_LEADS: '/fetch-leads',
  FETCH_API_USER: "/fetch-api-user",
  FETCH_LEADS_DETAILS:'/fetch-lead-details',
  AUTHENTICATE:'/authenticate',
  FILTER_LEADS:'/fetch-leads',
  FETCH_fUND_REQUEST: "/fetch-fund-request",

  APPROVE_DOC:'/approve-business-doc',
  APPROVE_GSTIN:'/approve-gstin',
  ESIGNREQUEST: '/fetch-esign',

  LEAD_UPDATE_REGISTER_STEP: '/leads/register-step-update',
  LEAD_SET_PRIMARY_ACCOUNT: '/leads/bank-primary-acc',
  LEAD_SAVE_BUSINESS_DETAILS: '/leads/save-business-details',
  LEAD_VERIFY_BANK_ACC: '/leads/verify-bank-acc',
  LEAD_SAVE_BANK_DETAILS: '/leads/save-bank-details',
  LEAD_SAVE_DEPT_PARTICIPANTS: '/leads/save-dept-participants',
  CREATE: "/provider/createlead",
  PROVIDER_DETAILS: "/provider/register-details",
  VERIFY_GSTIN: "/verify-gstin",
  PROVIDER_SAVE_BUSINESS_DETAILS: '/provider/save-business-details',
  LEAD_CONSTITUTION_TYPE: '/constitution-type',
  PROVIDER_SAVE_BANK_DETAILS: '/provider/save-bank-details',
  VERIFY_BANK_ACC: '/verify-bank-acc',
  PROVIDER_SAVE_DEPT_PARTICIPANTS: "/provider/save-dept-participants",
  Upload: "/provider/uploaddoc",
  FETCH_CATEGORY: "/scheme/master/fetch-category",
  FETCH_SUB_CATEGORY: "/scheme/master/fetch-sub-category",
  FETCH_SERVICES: "/scheme/master/fetch-services",
  FETCH_SCHEMES_BY_IDS: "/scheme/fetch-schemes-by-ids",

  SAVE_SCHEME: "/scheme/save",
  LEAD_APPLIED_SCHEME: "/leads/lead-applied-scheme",
  FETCH_SCHEME_BY_TYPE: "/scheme/fetch-scheme-by-type",
  FETCH_SCHEMES_BYIDS : "scheme/fetch-scheme-byid",
  SAVE_BANK_DETAILS: "save-bank-details",
  FETCH_BANKS: "/fetch-banks",
  FETCH_fUND_REQUESTS: "/fetch-fund-request",
  //Providers
  All_PROVIDERS:'/fetch-providers',
  PROVIDER_ISACTIVE:'/provider-status-update',
  FETCH_APPLIED_SCHEME:'/fetch-applied-scheme',
  PROVIDER_SAVE_SCHEME:'/scheme/provider/save',
  VERIFY_PIN: "/verify-pin",
  LEAD_SAVE_ESIGN_CONTENT: "/leads/save-esign-content",
  GET_LEAD_SAVE_ESIGN_CONTENT: "leads/fetch-esign-content",
  FETCH_ADMIN_WALLET_DATA: "/admin-wallet",
}

export const DepartmentType = {
  operation: 1,
  finance: 2,
  it: 3
};

export const LeadSteps = {
  kycdoc: 5,
  banking: 6,
  department: 7
};

export const PageRouteRegx = {
  TICKET_DETAIL: '/ticket/ticket-detail/[0-9]',
};

export const AUTHORITY = {
  USER: 'USER',
  ADMIN: 'Admin'
};

export const AUTHORITY_ROUT_MAP = [
  { route_regx: PageRouteRegx.TICKET_DETAIL, authority: [AUTHORITY.USER, AUTHORITY.ADMIN] },
  { route_regx: PageUrls.LEADS, authority: [AUTHORITY.ADMIN] },
  { route_regx: PageUrls.DASHBOARD, authority: [AUTHORITY.ADMIN] },
];


export const filterTypes = [
  { label: 'Entity Name', value: 'companyName' },
  { label: 'Auth. Person Name', value: 'authorizedPersonName' },
  { label: 'Lead ID', value: 'leadUserId' },
  { label: 'Email Id', value: 'email' },
  { label: 'Mobile Number', value: 'mobile' },
];
export const options = ['Leads', 'NewLeads', 'ESignRequest', 'ApiUsers',]; // Dropdown options


export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => reject(error),
      );
    } else {
      reject('Geolocation is not supported');
    }
  });
};
 export const statesAndUTs = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
"West Bengal",
  "Andaman and Nicobar Islands (UT)",
  "Chandigarh (UT)",
  "Dadra and Nagar Haveli and Daman and Diu (UT)",
  "Delhi (NCT) (UT)",
  "Jammu and Kashmir (UT)",
  "Ladakh (UT)",
  "Lakshadweep (UT)",
  "Puducherry (UT)",
  ];

export const getIpAddress = async () => {
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();
  return data.ip
};
export const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;

  // Detecting browser
  const browser = (() => {
    if (userAgent.includes("Firefox")) return "Mozilla Firefox";
    if (userAgent.includes("Edg")) return "Microsoft Edge";
    if (userAgent.includes("Chrome")) return "Google Chrome";
    if (userAgent.includes("Safari")) return "Safari";
    if (userAgent.includes("Opera") || userAgent.includes("OPR")) return "Opera";
    return "Unknown Browser";
  })();


  return { browser, userAgent };
};

export const getOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return "MacOS";
  }
  if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
    return "Windows";
  }
  if (/Linux/.test(userAgent)) {
    return "Linux";
  }

  return "Unknown OS";
};