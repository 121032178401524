import React, { useState } from 'react';
import global from '../../assets/scss/global.module.scss';
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';

const UploadUploadDocument = ({ userData }) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [newFileName, setNewFileName] = useState('');
  const [error, setError] = useState('');
  const [savedFile, setSavedFile] = useState(null); // State to store the saved file info

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      setSelectedFile(file);
      setFileName(file.name); // Set the original file name
      setNewFileName(file.name); // Set the new file name (initially the same)
      setError('');
    }
  };

  // Handle file name change
  const handleNameChange = (event) => {
    setNewFileName(event.target.value);
  };

  // Handle saving the file (renaming it)
  const handleSaveFile = () => {
    if (!newFileName) {
      setError('Please provide a file name');
      return;
    }
    if (newFileName === fileName) {
      setError('File name is the same. No changes made.');
      return;
    }

    // Simulate file save with the new name (this does not change the actual file on disk, but in your app context)
    const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });

    // Set the saved file info in the state to display it
    setSavedFile(renamedFile);

    // Reset the states after saving
    setSelectedFile(null);
    setFileName('');
    setNewFileName('');
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (!file) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('providerUserId', userData.providerUserId); // Replace with the actual value
    formData.append('docId', '1'); // Replace with the actual value

    try {
      setUploadStatus('Uploading...');
      const response = await fetcher.post(`${ApiUrls.Upload}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploadStatus(`File uploaded successfully: ${response.data.message}`);
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('File upload failed. Please try again.');
    }
  };

  return (
    <div>
      <div className={global.wrapper_form}>
        <div className={`${global.d_flex} ${global.d_flex_l_r}`}>
          <h3> Documents</h3>
        </div>

        <div className={global.upload_resume_section}>

          <div style={{ padding: '20px' }}>

            <label>
              <input onChange={handleFileChange}
                id="fileInput" className={global.upload_input} type="file" />
              <span onClick={() => document.getElementById('fileInput').click()} className={global.upload_resume_}>
                <i className={global.upload_resume_icon}></i>
              </span>
              <strong>Click to Upload</strong> or drag and drop (Max. File size: 25 MB)
            </label>
            </div>
          </div>
            {selectedFile && (
              <div className={global.overlay}>
                <div className={global.file_change_thickbox}>
                  <div className={global.form_group}>
                    <div className={global.pad_24}>
                      <h6>Selected File: {fileName}</h6>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      </p>

                    </div>
                    <div className={global.document_type_change}>
                      <label><strong>Document Type</strong></label>
                      <div className={global.row}>
                        <div className={global.col_s_9}>
                          <input className={global.form_control}
                            type="text"
                            value={newFileName}
                            onChange={handleNameChange}
                            placeholder="Rename file"
                          />
                        </div>
                        <div className={global.col_s_3}>
                          <button className={global.submit_primary_btn}
                            onClick={handleSaveFile} style={{ padding: '10px' }}>
                            Save File
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={global.row}>
                      {error && <div className={global.error_message}>{error}</div>}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {savedFile && (

              <div className={`${global.file_path} ${global.mar_top_24}`}>
                <div className={`${global.row}`}>
                  <div className={global.col_s_12}>
                    <strong>Saved File Name:</strong> {savedFile.name} <strong>File Type:</strong> {savedFile.type} <strong>File Size:</strong> {(savedFile.size / 1024).toFixed(2)} KB
                  </div>
                </div>
              </div>


            )}
       
        

        {uploadStatus && <p className={global.upload_status}>{uploadStatus}</p>}
      </div>
    </div>
  );
};

export default UploadUploadDocument;
