import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import global from '../../assets/scss/global.module.scss';

const departmentMapping = {
  1: "A Operation Department",
  2: "B Finance Department",
  3: "C Development Department IT",
  4: "D Legal Department",
};

const DeepParticipant = ({ leadDetails }) => {
  // Group participants by department
  const groupedParticipants = leadDetails?.leadDepartmentParticipants?.reduce((acc, participant) => {
    const departmentName = departmentMapping[participant.departmentType] || "Unknown Department";
    if (!acc[departmentName]) acc[departmentName] = [];
    acc[departmentName].push(participant);
    return acc;
  }, {});

  return (
    <Box display="flex" flexDirection="column" gap={1} mt={1}>
      {groupedParticipants &&
        Object.entries(groupedParticipants).map(([department, participants]) => (
          <Box key={department} mb={4} className={global.deepartment_section}>
            {/* Department Title */}
            <Typography variant="h5" align="left" className={global.heading} gutterBottom>
              {department}
            </Typography>

            {/* Participant Details Table */}
            {participants.map((participant) => (
              <div key={participant.id} className={`${global.row} ${global.participant_gray_bg1}`}>
                <div className={global.col_s_4}>
                  <span className={global.d_flex}>
                    <Avatar
                      alt={participant.name}
                      src="/path/to/dummy-avatar.jpg"
                      sx={{ width: 26, height: 26 }}
                      style={{ marginRight: '8px', verticalAlign: 'middle' }}
                    />
                    <Typography variant="h6" className={global.heading2}>
                      {participant.name}
                    </Typography>
                  </span>
                </div>
                <div className={global.col_s_4}>{participant.phone}</div>
                <div className={global.col_s_4}>{participant.email}</div>
              </div>
            ))}
          </Box>
        ))}

      {/* "Next" Button */}
      {/* <Box mt={1} display="flex" justifyContent="flex-start">
        <button className={`${global.lead_common_btn} ${global.submit_btn_1} ${global.width_120}`}>
          Next
        </button>
      </Box> */}
    </Box>
  );
};

export default DeepParticipant;
