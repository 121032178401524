import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  Avatar,
  CircularProgress,
} from "@mui/material";
import fetcher from "../../../utils/fetcher";
import { ApiUrls } from "../../../utils/constants";
import KycDocument from "../../tabPages/KycDocument";
import GstinNumber from "../../tabPages/Gstin";
import DeepParticipant from "../../tabPages/DeepParticipant";
import ApplyScheme from "../../tabPages/ApplyScheme";
import global from "../../../assets/scss/global.module.scss";
import { Padding } from "@mui/icons-material";
import BankDetails from "../../tabPages/BankDetails";
import UserUploadDocument from "../../users/UserUploadDocument";
import UserDepartmentParticipants from "../../users/UserDepartmentParticipants";
import UserApplyScheme from "../../users/UserApplyScheme";
import UserBankDetails from "../../users/UserBankDetails";

const ApiUserDetail = ({ leadId }) => {
  const [leadDetails, setLeadDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [activeRightTab, setActiveRightTab] = useState(0);
  const [aadhaarImageUrl, setAadhaarImageUrl] = useState({});
  const [userData, setUserData] = useState({ leadUserId: leadId }); // Add userData state

  const fetchLeadDetail = async (leadId) => {
    try {
      const data = await fetcher.get(
        `${ApiUrls.FETCH_LEADS_DETAILS}?id=${leadId}`
      );
      if (data?.status === 200) {
        setLeadDetails(data.response);
        const imageUrl = `data:image/jpeg;base64,${data.response.leadAadhaarDetails.profilePic}`;
        setAadhaarImageUrl(imageUrl);
      } else {
        setError("Failed to fetch lead details.");
      }
    } catch (error) {
      setError("Error fetching lead details.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeadDetail(leadId);
  }, [leadId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography>{error}</Typography>;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleRightTabChange = (event, newValue) => {
    setActiveRightTab(newValue);
  };

  return (
    <Box display="flex">
      {/* Left side with 30% width */}
      <Box
        width="30%"
        p={2}
        style={{
          background: "#fff",
          marginTop: "12px",
          borderRadius: "12px",
          border: "none",
        }}
      >
        {/* Horizontal Tabs */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          left
          sx={{
            //borderBottom: 1,
            //borderColor: 'divider',
            "& .MuiTab-root": {
              fontWeight: 400,
              fontSize: "14px",
              background: "#F6F6F6",
              borderRadius: "8px",
              textTransform: "capitalize",
              marginLeft: "8px",
              border: "#D1D1D1 solid 1px",
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#ffffff",
              background: "#06226A",
              borderRadius: "8px",
              borderBottom: "0px",
              borderBottom: "none",
            },
            MuiTab: {
              defaultProps: {
                disableRipple: true,
              },
            },
          }}
        >
          <Tab
            label="Personal Information"
            style={{ textTransform: "normal" }}
          />
          <Tab
            label="Business Information"
            style={{ textTransform: "normal" }}
          />
        </Tabs>

        {/* Tab Content */}
        {activeTab === 0 && leadDetails && (
          <Box display="flex" flexDirection="column" gap={2} mt={5}>
            {/* Profile Image and Name */}
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              style={{
                border: "#D6D9E1 solid 1px",
                borderRadius: "8px",
                padding: "12px",
              }}
            >
              <Avatar src={aadhaarImageUrl} alt="Profile Image" />
              <Typography variant="h6" style={{ fontSize: "14px" }}>
                <span className={global.person_name}>Person name </span>
                <br />
                {leadDetails.authorizedPersonName || "N/A"}
              </Typography>
            </Box>

            {/* Email Field */}
            <TextField
              label="Email"
              value={leadDetails.email || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />

            {/* Phone Number Field */}
            <TextField
              label="Phone Number"
              value={leadDetails.mobile || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />

            {/* Gender Field */}
            <TextField
              label="Gender"
              value={
                leadDetails?.leadAadhaarDetails?.gender === "M"
                  ? "Male"
                  : leadDetails?.leadAadhaarDetails?.gender === "F"
                  ? "Female"
                  : "N/A"
              }
              variant="outlined"
              fullWidth
              disabled
            />

            <TextField
              label="Date of Birth here"
              value={leadDetails?.leadAadhaarDetails?.dateOfBirth || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />

            <TextField
              label="Address"
              value={
                leadDetails?.leadAadhaarDetails?.house +
                  "," +
                  leadDetails?.leadAadhaarDetails?.landmark +
                  " " +
                  leadDetails?.leadAadhaarDetails?.district +
                  " , " +
                  leadDetails?.leadAadhaarDetails?.state +
                  " , " +
                  leadDetails?.leadAadhaarDetails?.country || "N/A"
              }
              variant="outlined"
              fullWidth
              disabled
            />

            <TextField
              label="Aadhar Card Number"
              value={leadDetails?.leadAadhaarDetails?.aadharNumber || "N/A"}
              variant="outlined"
              fullWidth
              disabled
              InputProps={{
                endAdornment: leadDetails.isAadharVerified ? (
                  <Box component="span" color="green">
                    ✔️
                  </Box>
                ) : null,
              }}
            />

            <TextField
              label="PAN Card Number"
              value={leadDetails?.leadPanDetails?.panNumber || "N/A"}
              variant="outlined"
              fullWidth
              disabled
              InputProps={{
                endAdornment: leadDetails.isPanVerified ? (
                  <Box component="span" color="green">
                    ✔️
                  </Box>
                ) : null,
              }}
            />

            <TextField
              label="OnBoarding Link"
              value={leadDetails.onBoardingLink || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
          </Box>
        )}

        {activeTab === 1 && leadDetails && (
          <Box display="flex" flexDirection="column" gap={2} mt={5}>
            {/* Business Information Fields */}
            <TextField
              label="Business Name"
              value={leadDetails?.leadBusinessDetails?.name || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="Business Email"
              value={leadDetails.email || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="Business Phone"
              value={leadDetails.mobile || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="Business Address"
              value={
                leadDetails.leadBusinessDetails.registeredAddress1 +
                  "  " +
                  leadDetails.leadBusinessDetails.registeredAddress1 || "N/A"
              }
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="Constitution Of Business"
              value={leadDetails.leadBusinessDetails.constitutionType || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="Business Incorpartion Date"
              value={leadDetails.leadBusinessDetails.incorporationDate || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="GSTIN Number"
              value={leadDetails.leadBusinessDetails?.gstin || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="GSTIN Registration Date"
              value={leadDetails?.leadBusinessDetails?.status || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="Seeding Status"
              value={leadDetails?.leadBusinessDetails?.status || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
            <TextField
              label="Compony Website"
              value={leadDetails.companyWebsite || "N/A"}
              variant="outlined"
              fullWidth
              disabled
            />
          </Box>
        )}
      </Box>

      <Box
        flex="1"
        p={2}
        width={"70%"}
        style={{
          background: "#fff",
          marginTop: "12px",
          marginLeft: "12px",
          borderRadius: "12px",
        }}
      >
      
        {/* Right Side Tab Content */}
        <UserUploadDocument/>
        <UserDepartmentParticipants />
        <UserApplyScheme userData={userData} /> 
        <UserBankDetails bankDetails={leadDetails?.leadBankDetails} />
        {/* Right Side Tab Content */}
       
      </Box>
    </Box>
  );
};

export default ApiUserDetail;
