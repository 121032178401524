import React, { useEffect, useState } from "react";

import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Download as DownloadIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import global from '../../assets/scss/global.module.scss'


const KycDocument = ({ leadDetails }) => {
  const [viewOpen, setViewOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState({});
  const [status, setStatus] = useState({});
  const [viewFileField, setViewFileField] = useState(null);
  const [showRejectReason, setShowRejectReason] = useState(false); // To show the reject reason dropdown
  const [reasonRequired, setReasonRequired] = useState(false); // To check if reason is selected when rejecting

  const [step, setStep] = useState(1); // State to manage steps
  const [images, setImages] = useState([]);
  const [pdfDocuments, setPdfDocuments] = useState([]);
  // Handle View Click for PDF
  const handleViewFile = (field) => {
    setViewFileField(field.id);
    setViewOpen(true);
    setShowRejectReason(false); // Reset the reject reason dropdown when viewing the file
  };

  // Handle Approve Click for PDF/Image
  const handleApprove = (field) => {
    setStatus((prev) => ({
      ...prev,
      [field.id]: "Approved",
    }));
    setRejectReason((prev) => ({ ...prev, [field.id]: null })); // Clear reject reason on approval
    setShowRejectReason(false); // Hide the reject reason dropdown when approved
    const payload = {
      id: field.id,
      Verified: "Verified",
      remarks: "Verified",
    };
    approveDocument(payload);
    
  };

  // Handle Reject Click for PDF/Image
  const handleReject = (field) => {
    setStatus((prev) => ({
      ...prev,
      [field.id]: "Rejected",
    }));
    setShowRejectReason(true); 
   
  };

  // Handle Change of Reject Reason
  const handleRejectReasonChange = (field, reason) => {
    setRejectReason((prev) => ({
      ...prev,
      [field.id]: reason,
    }));
    setReasonRequired(false); // Reset the error message when a reason is selected
    const payload = {
      id: field.id,
      Verified: "Rejected",
      remarks: reason,
    };
    approveDocument(payload)
  };

  // Handle Next Button click (to move to next step)
  const handleNextStep = () => {
    if (Object.values(status).some((status) => status === "Rejected")) {
      setReasonRequired(true); // Ensure reason is selected before moving to next step if any document is rejected
    } else {
      setStep(step + 1); // Move to the next step if no rejections
    }
  };

  const approveDocument = async (payload) => {
    const verifyRes = await fetcher.post(ApiUrls.APPROVE_DOC, payload);
    if (verifyRes?.status === 200) {
            //Done
    } else {
         //Failed
    }
  };
  const handleDownload = async (field) => {
    try {
      const response = await fetch(field.bucketUrl)
      
  
      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = field.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Cleanup URL
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  
  
  
 

  useEffect(() => {
      // Filter for images (assuming file extensions are for image files)
      const imageFiles = leadDetails.leadBusinessDocuments.filter(doc => 
          doc.fileName.endsWith('.jpg') || doc.fileName.endsWith('.jpeg') || doc.fileName.endsWith('.png')
      );

      // Filter for PDFs
      const pdfFiles = leadDetails.leadBusinessDocuments.filter(doc => doc.fileName.endsWith('.pdf'));

      setImages(imageFiles);
      setPdfDocuments(pdfFiles);
  }, []);
  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2}>
      
    
      <TextField label="Constitution Type" variant="outlined" fullWidth     value={leadDetails?.leadBusinessDetails?.constitutionTypeName || "N/A"} />

      

      {/* Dynamic Fields for Image Files */}
      {images.map((field, index) => (
        <Box key={index} display="flex" flexDirection="column" gap={1}>
          <div className="row justify-content-center" style={{border:"#D6D9E1 solid 1px", borderRadius:"4px", margin:"4px 4px 0 4px", padding:"8px"}}>
        
        <div className="col-sm-4">
             {field.docName}
        </div>
        <div className="col-sm-2">
          {/* {status[field.id] && (
              <span
                className={`badge ${status[field.id] === 'Approved' ? 'bg-success' : 'bg-danger'}`}
                style={{ marginRight: '8px' }}
              >
                {status[field.id]}
              </span>
            )} */}
        </div>

        <div className="col-sm-4">
        <i className={global.file_icon}></i>  {field.fileName}
        {status[field.id] && (
                <span style={{ marginLeft: '8px', color: status[field.id] === 'Approved' ? 'green' : 'red' }}>
                  {status[field.id]}
                </span>
              )}
        </div>
        

       
        
        <div className="col-sm-2 text-right">
            <div className={global.d_flex}>
        <button style={{background:"none", border:"none"}}
              className="btn btn-primary"
              onClick={() => handleDownload(field)}
            >
              <i className={global.download_icon}></i> 
            </button>

            <a
           
              className={`${global.view_doc_link} ${global.d_flex}`}
              onClick={() => handleViewFile(field)}
            >
              View <i className={global.view_doc_icon}></i>
            </a>
            </div>
        </div>

      </div>

          {/* Placeholder for Inline Image View */}
          {viewOpen && viewFileField === field.id && (
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              border="1px solid"
              borderColor="grey.300"
              p={2}
              borderRadius={2}
              position="relative"
            >
              {/* Close Button */}
              <IconButton
                onClick={() => setViewOpen(false)}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
              >
                <CloseIcon />
              </IconButton>

              <Typography variant="body1" mb={2}>
                Image Preview - {field.docName}
              </Typography>
              <Box
                component="img"
                src={field.bucketUrl}//"/path/to/image.jpg" // Replace with actual image URL
                alt={field.fileName}
                maxWidth="100%"
                maxHeight="300px"
              />
              <Box
                display="flex"
                justifyContent="flex-start"
                width="100%"
                mt={2}
                gap={2}
              >
              
                

                <button  className={ `${global.lead_common_btn} ${global.approve_btn}  ${global.width_120} `}
          onClick={() => handleApprove(field)}
        >
          Approve
        </button>

        <button  className={ `${global.lead_common_btn} ${global.reject_btn}  ${global.width_120} `}
           onClick={() => handleReject(field)}
        >
          Reject
        </button>

        {showRejectReason && status[field.id] === "Rejected" && (
                <Box>
                  <Select
                    value={rejectReason[field.id] || ""}
                    onChange={(e) =>
                      handleRejectReasonChange(field, e.target.value)
                    }
                    displayEmpty
                    fullWidth
                    required
                  >
                    <MenuItem value="" disabled>
                      Select Reason
                    </MenuItem>
                    <MenuItem value="Incomplete document">
                      Incomplete document
                    </MenuItem>
                    <MenuItem value="Blurry image">Blurry image</MenuItem>
                    <MenuItem value="Mismatched details">
                      Mismatched details
                    </MenuItem>
                  </Select>
                  {reasonRequired && !rejectReason[field.id] && (
                    <Typography color="error" variant="body2" mt={1}>
                      Please select a rejection reason.
                    </Typography>
                  )}
                </Box>
              )}
              </Box>

             
             


            </Box>
          )}
        </Box>
      ))}

      

      {/* Dynamic Fields for PDF Files */}
      {pdfDocuments.map((field, index) => (
        <>
        <div className={global.row} style={{border:"#D6D9E1 solid 1px", borderRadius:"4px",  padding:"8px"}}>
            <div className={global.col_s_7}>
                {field.docName}
            </div>
            <div className={global.col_s_5}>
               <Typography mr={1} display="flex">
                <i className={global.file_icon}></i> {field.fileName}
                {status[field.id] && (
                  <span style={{ marginLeft: '8px', color: status[field.id] === 'Approved' ? 'green' : 'red' }}>
                    {status[field.id]}
                  </span>
                )}
              </Typography>
            </div>
            

              <div className={global.col_s_2}>
                <div className={global.d_flex}>
              <IconButton onClick={() => handleDownload(field)} aria-label="Download document">
            <i className={global.download_icon}></i>
          </IconButton>


              <a
              className={`${global.view_doc_link} ${global.d_flex}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleViewFile(field);
              }}
              aria-label="View document"
            >
              View <i className={global.view_doc_icon}></i>
            </a>
            </div>
              </div>
              </div>

        <Box key={index} display="flex" flexDirection="column" gap={1}>
  
          {/* View PDF File (Inline) */}
          {viewOpen && viewFileField === field.id && (
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              border="1px solid"
              borderColor="grey.300"
              p={2}
              borderRadius={2}
              position="relative"
            >
              {/* Close Button */}
              <IconButton
                onClick={() => setViewOpen(false)}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
              >
                <CloseIcon />
              </IconButton>

              <Typography variant="body1" mb={2}>
                PDF Preview - {field.docName}
              </Typography>
              {/* PDF Viewer or Placeholder */}
              <Box
                component="iframe"
                src={`${field.bucketUrl}`} // Replace with actual PDF URL
                width="100%"
                height="600px"
              />
              <Box
                display="flex"
                justifyContent="flex-start"
                width="100%"
                mt={2}
                gap={2}
              >
                {/* Approve and Reject Buttons */}


          <button  className={ `${global.lead_common_btn} ${global.approve_btn}  ${global.width_120} `}
          onClick={() => handleApprove(field)}
        >
          Approve
        </button>

        <button  className={ `${global.lead_common_btn} ${global.reject_btn}  ${global.width_120} `}
           onClick={() => handleReject(field)}
        >
          Reject
        </button>

               
              </Box>

              {/* Reject Reason Dropdown (Visible only when rejected) */}
              {showRejectReason && status[field.id] === "Rejected" && (
                <Box mt={2}>
                  <Select
                    value={rejectReason[field.id] || ""}
                    onChange={(e) =>
                      handleRejectReasonChange(field, e.target.value)
                    }
                    displayEmpty
                    fullWidth
                    required
                  >
                    <MenuItem value="" disabled>
                      Select Reason
                    </MenuItem>
                    <MenuItem value="Incomplete document">
                      Incomplete document
                    </MenuItem>
                    <MenuItem value="Incorrect details">
                      Incorrect details
                    </MenuItem>
                  </Select>
                  {reasonRequired && !rejectReason[field.id] && (
                    <Typography color="error" variant="body2" mt={1}>
                      Please select a rejection reason.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
        </>
      ))}

      
    </Box>
  );
};

export default KycDocument;
