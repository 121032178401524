
import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  TextField,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import global from '../../assets/scss/global.module.scss'

// Mock data for the last 5 transactions
const transactions = [
  {
    date: "06 Apr 2024 4:13:33 PM",
    executedBy: "Ankur Gupta",
    transactionId: "94646377224567",
    entityName: "Tramo Technolab Private Limited",
    amount: "₹1,23,14,910.00",
    debitCredit: "Debit",
    narration: "Service Fee/Transaction Charges",
    remarks: "Lorem ipsum dolor sit amet consectetur...",
    attachment: "📄",
    closingBalance: "₹1,23,14,910.00",
  },
  {
    date: "06 Apr 2024 4:13:33 PM",
    executedBy: "Ankur Gupta",
    transactionId: "94646377224568",
    entityName: "Alpha Corp",
    amount: "₹15,00,000.00",
    debitCredit: "Credit",
    narration: "Transaction Adjustment - Credit",
    remarks: "Adjustment for credit settlement",
    attachment: "📄",
    closingBalance: "₹1,38,14,910.00",
  },
];

// Narration reasons for debit and credit
const debitReasons = [
  "Credit Reversed",
  "Chargeback",
  "Wrong Fund Request Approved",
  "Transaction Adjustment - Debit",
  "Service Fee/Transaction Charges",
  "Subscription/Recurring Charges",
  "Penalty or Fine",
  "Refund Adjustment",
];
const creditReasons = [
  "GST Reimbursement",
  "Incentive/Cashback",
  "Credit Given",
  "Transaction Adjustment - Credit",
];

// Validation schema
const validationSchema = yup.object({
  amount: yup
    .number("Enter a valid amount")
    .required("Amount is required")
    .max(2500000, "You cannot transfer or credit funds greater than ₹25,00,000"),
  narration: yup.string().required("Narration is required"),
  remarks: yup.string().required("Remarks are required"),
});

// Function to convert numbers to words
const numberToWords = (num) => {
  const words = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const scales = ["", "Thousand", "Million", "Billion"];

  if (num === 0) return "Zero";

  let wordsStr = "";
  let scale = 0;

  while (num > 0) {
    const chunk = num % 1000;
    if (chunk > 0) {
      const chunkWords = [];
      if (chunk >= 100) {
        chunkWords.push(words[Math.floor(chunk / 100)] + " Hundred");
      }
      const remainder = chunk % 100;
      if (remainder > 0) {
        if (remainder < 20) {
          chunkWords.push(words[remainder]);
        } else {
          chunkWords.push(
            tens[Math.floor(remainder / 10)] +
              (remainder % 10 > 0 ? "-" + words[remainder % 10] : "")
          );
        }
      }
      wordsStr = chunkWords.join(" ") + " " + scales[scale] + " " + wordsStr;
    }
    num = Math.floor(num / 1000);
    scale++;
  }
  return wordsStr.trim();
};

const FundFlow = () => {
  const [tabValue, setTabValue] = useState(0);
  const [previewData, setPreviewData] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      searchBy: "Entity Name",
      keyword: "",
      amount: "",
      narration: "",
      remarks: "",
      attachment: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPreviewData(values);
      setOpenPreview(true);
    },
  });

  const handlePreviewSubmit = () => {
    setOpenPreview(false);
    console.log("Transaction submitted:", previewData);
  };

  return (
    <div className= { `${global.gray_bg} ${global.pad_16} `}>

<div className='container-fluid'>
      <div className={ `${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
         <Typography variant="h1"  gutterBottom>Fund Flow</Typography>
      </div>

      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: 2,
          border: "1px solid #d0e5f2",
          marginTop:"12px"
        }}
      >

<Tabs
 
  value={tabValue}
  onChange={handleTabChange}
  TabIndicatorProps={{
    sx: {
      backgroundColor: "#f7f7f7",  
      height: 3, 
  
    }
  }}
>
  <Tab
    label={
      <>
        <i  className={global.credit_icon}></i>
        Debit
      </>
    }
    sx={{
      textTransform: "none",
      marginRight:"4px",
      paddingRight:"32px",
      fontWeight: tabValue === 0 ? "bold" : "normal",
      backgroundColor: tabValue === 0 ? "#f7f7f7" : "transparent",  
      color: tabValue === 0 ? "#fff" : "inherit", 
      '&:hover': {
        backgroundColor: "#f7f7f7",  
      },
      borderBottom: tabValue === 0 ? "2px solid #DC2626" : "none", // Add red border for active tab

    }}
  />
  
  <Tab
    label={
      <>
      <i  className={global.debit_icon}></i>
      Credit
    </>
    }
    sx={{
      textTransform: "none",
      paddingRight:"32px",
      fontWeight: tabValue === 1 ? "bold" : "normal",
      backgroundColor: tabValue === 1 ? "#f7f7f7" : "transparent", // Background color for the active tab
      color: tabValue === 1 ? "#fff" : "inherit", // Text color for active tab
      '&:hover': {
        backgroundColor: "#f7f7f7", // Background color on hover
      },
      borderBottom: tabValue === 1 ? "2px solid #0DA344" : "none", // Add red border for active tab
    }}
  />
    
</Tabs>


        {/* Form */}
        <Box sx={{ mt: 1, p: 3, borderRadius: 2, boxShadow: 1, bgcolor: "#fff" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  label="Search by"
                  name="searchBy"
                  value={formik.values.searchBy}
                  onChange={formik.handleChange}
                  fullWidth
                >
                  <MenuItem value="Entity Name">Entity Name</MenuItem>
                  <MenuItem value="User ID">User ID</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Keyword"
                  name="keyword"
                  value={formik.values.keyword}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Amount"
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <Typography sx={{ mr: 1, color: "#aaa" }}>₹</Typography>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  select
                  label={`Choose ${tabValue === 0 ? "Debit" : "Credit"} Narration`}
                  name="narration"
                  value={formik.values.narration}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.narration && Boolean(formik.errors.narration)}
                  helperText={formik.touched.narration && formik.errors.narration}
                >
                  {(tabValue === 0 ? debitReasons : creditReasons).map(
                    (reason, index) => (
                      <MenuItem key={index} value={reason}>
                        {reason}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Remarks"
                  name="remarks"
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                  helperText={formik.touched.remarks && formik.errors.remarks}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="outlined"
                  component="label"
                  fullWidth
                  sx={{ border: "1px dashed #d0d0d0", padding: "16px 20px" }}
                >
                  Attachment
                  <input
                    hidden
                    type="file"
                    onChange={(event) =>
                      formik.setFieldValue("attachment", event.target.files[0])
                    }
                  />
                </Button>
                {formik.values.attachment && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {formik.values.attachment.name}
                  </Typography>
                )}

             

              </Grid>



              <Grid item xs={12} sm={6} md={3}>     
              <button
                   className={ `${global.submit_btn_1} ${global.lead_common_btn} `}
                  type="submit"
                >
                  Submit
                </button>

                 </Grid>  

              
            </Grid>
          </form>
        </Box>
      </Box>

      {/* Transaction Table */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2, color: "#06226A" }}>
          Last 5 Transactions
        </Typography>
        <TableContainer component={Paper} style={{marginTop:"16px"}}>
            <Table>
              <TableHead style={{background: "#EAF6FF"}}>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Executed By</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Entity Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Debit/Credit</TableCell>
                <TableCell>Narration</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Attachment</TableCell>
                <TableCell>Closing Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction, index) => (
                <TableRow key={index}>
                  <TableCell>{transaction.date}</TableCell>
                  <TableCell>{transaction.executedBy}</TableCell>
                  <TableCell>{transaction.transactionId}</TableCell>
                  <TableCell>{transaction.entityName}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{transaction.debitCredit}</TableCell>
                  <TableCell>{transaction.narration}</TableCell>
                  <TableCell>{transaction.remarks}</TableCell>
                  <TableCell>{transaction.attachment}</TableCell>
                  <TableCell>{transaction.closingBalance}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Preview Modal */}
      <Dialog open={openPreview} onClose={() => setOpenPreview(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Preview Transaction</DialogTitle>
        <DialogContent>
          <Typography>
            <strong>Search By:</strong> {previewData?.searchBy}
          </Typography>
          <Typography>
            <strong>Keyword:</strong> {previewData?.keyword}
          </Typography>
          <Typography>
            <strong>Amount:</strong> ₹{previewData?.amount} (
            {numberToWords(Number(previewData?.amount))})
          </Typography>
          <Typography>
            <strong>Narration:</strong> {previewData?.narration}
          </Typography>
          <Typography>
            <strong>Remarks:</strong> {previewData?.remarks}
          </Typography>
          <Typography>
            <strong>Attachment:</strong>{" "}
            {previewData?.attachment?.name || "None"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreview(false)}>Modify</Button>
          <Button
            onClick={handlePreviewSubmit}
            variant="contained"
            sx={{ backgroundColor: "#06226A", color: "#fff" }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    </div>

    </div>

   );
};

export default FundFlow;
