//import React, { useRef } from "react";
import globalcss from '../../../assets/scss/global.module.scss'
import logo from '../../../assets/images/tramo-logo.svg'
import { ToastBody } from 'react-bootstrap';


//import { usePDF } from 'react-to-pdf';
const ESignAgreement = (props) => {
    const date = new Date();
    //const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
    // Get the month name
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[date.getMonth()];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const time = `${hours}:${minutes}:${seconds}`;



    const dayOfAgreement = new Date().getDate()
    const yearOfAgreement = new Date().getFullYear()


    const { clientData, adminData, latitude, longitude, signedBy, ip, browser, operatingSystem, pageOpenTime } = props
    const {
        clientUserBusinessEmail,
        clientUserAuthorisedPerson,
        clientUserBusinessAddress,
        clientUserBusinessConstitution,
        clientUserBusinessName,
        clientTitle
    } = clientData;
    const {
        clientAuthorisedPersonTitle,
        clientBusinessEmail,
        clientJurisdiction,
        clientOnboardingFee,
        clientBusinessName,
        clientBrandName,
        clientCIN,
        clientBusinessAddress
    } = adminData
    const { signImage,
        signBy,
        position,
        company, image } = signedBy
    // const pdfRef = useRef(null);
    // const handlePrint = () => {
    //     if (pdfRef.current) {
    //         // Clone the content to avoid modifying the actual DOM
    //         const contentToPrint = pdfRef.current.cloneNode(true);

    //         // Open a new window
    //         const printWindow = window.open("", "_blank");
    //         if (printWindow) {
    //             // Write the content to the new window
    //             printWindow.document.open();
    //             printWindow.document.write(`
    //           <html>
    //             <head>
    //               <title>Print Preview</title>
    //               <style>
    //                 /* Ensure styles are preserved */
    //                 body {
    //                   font-family: Arial, sans-serif;
    //                   margin: 0;
    //                   padding: 20px;
    //                 }
    //                 table {
    //                   width: 100%;
    //                   border-collapse: collapse;
    //                 }
    //                 th, td {
    //                   border: 1px solid black;
    //                   padding: 8px;
    //                   text-align: left;
    //                 }
    //               </style>
    //             </head>
    //             <body>${contentToPrint.innerHTML}</body>
    //           </html>
    //         `);
    //             printWindow.document.close();

    //             // Trigger the print dialog
    //             printWindow.print();

    //             // Close the print window
    //             printWindow.close();
    //         }
    //     }
    // };
    const containerStyle = {
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        border: "1px solid #ccc",
        margin: "20px",
        lineHeight: "1.6",
    };

    const headerStyle = {
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: "20px",
    };

    const tableStyle = {
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: "20px",
    };

    const thTdStyle = {
        border: "1px solid #ddd",
        padding: "8px",
        textAlign: "left",
        fontSize: "14px",
    };

    const photoStyle = {
        display: "block",
        margin: "auto",
        width: "80px",
        height: "80px",
        borderRadius: "50%",
    };

    const consentStyle = {
        marginTop: "20px",
        fontSize: "14px",
        lineHeight: "1.6",
    };

    return (
        <>
            {/* {isPreview && <button onClick={() => toPDF()}>Download PDF</button>} */}
            {/* {isPreview && <button onClick={handlePrint}>Generate PDF</button>} */}
            {/* <div ref={targetRef}> */}
            {/* <div>     ref={pdfRef}> */}
            <div className={globalcss.gray_bg}>
                <header className={`${globalcss.esign_bg_inner} ${globalcss.esgin} ${globalcss.header_fixed}`}>
                    <div className='container-fluid'>
                        <img src={logo} alt="" />
                    </div>
                </header>

                <div className='container-fluid'>
                    <div className={globalcss.esign_bg_inner} style={{ marginBottom: "250px" }}>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "100px", padding: "20px" }}>
                            <table>
                                <tr>
                                    <td>AMONGST</td>
                                    <td><strong>{clientUserBusinessName}</strong></td>
                                    <td>(As the "Client")</td>
                                    <td>AND</td>
                                    <td><strong>{clientBusinessName}</strong></td>
                                    <td>(As "{clientBrandName}")</td>
                                </tr>
                            </table>



                            <ol type="1">
                                <li >DEFINTIONS</li>
                                <li >NON-EXCLUSIVE ENGAGEMENT</li>
                                <li >SERVICES</li>
                                <li >COMMISSION / CHARGES</li>
                                <li >
                                    RESPONSIBILITIES, DUTIES, AND OBLIGATIONS OF THE CLIENT
                                </li>
                                <li >TERM AND TERMINATION:</li>
                                <li >
                                    DATA & SYSTEM SECURITY AND COMPLIANCE WITH REGULATIONS/ STANDARDS
                                </li>
                                <li >
                                    REPRESENTATIONS AND WARRANTIES
                                </li>
                                <li >CONFIDENTIALITY</li>
                                <li >EXCEPTIONS:</li>
                                <li >
                                    INSPECTION OF BUSINESS, RECORDS, AND REPORTS
                                </li>
                                <li >
                                    RIGHTS RELATED TO INTELLECTUAL PROPERTY
                                </li>
                                <li>INDEMNITY</li>
                                <li >RESTRICTIONS ON LIABILITY</li>
                                <li >GENERAL</li>
                                <li >FRAUDULENT TRANSACTIONS</li>
                                <li >CHARGEBACKS</li>
                                <li >GOVERNING LAW AND JUDITION</li>
                                 
                            </ol>

                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                        <ol type="1" start="19">
                                    <li >
                                    COMPLIANCE WITH ANTI-BRIBERY, ANTI-CORRUPTION
                                </li>
                                <li >SCOPE OF SERVICES</li>
                                <li >SERVICE INTEGRATION</li>
                                <li >CLIENT OBLIGATION</li>
                                <li >REFUNDS</li>
                                <li >
                                    RESTRICTIONS ON USE OF SERVICES
                                </li>
                                <li >TERMINATION</li>
                                    </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3>SERVICE AGREEMENT</h3>
                            <p>
                                This Agreement (“SERVICE AGREEMENT”) is made, entered, executed and
                                effected into on the<b> {dayOfAgreement}</b> day of
                                <b> {monthName},{yearOfAgreement} </b> at
                                <b> {time}.</b>
                            </p>

                            <h3>BETWEEN</h3>
                            <p>
                                <b>{clientBusinessName} </b>(CIN:<b> {clientCIN} </b>), a company
                                incorporated under the Companies Act, 2013 having its registered address
                                at <b>{clientBusinessAddress} </b>(hereinafter referred to as
                                <b> {clientBrandName}</b> which expression shall, unless it be
                                repugnant to the subject or context thereof, include its successors and
                                permitted assigns); of the <b> FIRST PART; </b>
                            </p>
                            <h3>AND</h3>

                            <p>
                                <b>{clientUserBusinessName} </b> a
                                <b>{clientUserBusinessConstitution} </b>incorporated an entity
                                incorporated under the laws of India or a company incorporated under the
                                provisions of the Companies Act, 1956 or Companies Act, 2013 (as
                                applicable), having its registered office at<b
                                >{clientUserBusinessAddress}
                                </b>
                                Shop, (hereinafter referred to as the “Client” which expression shall,
                                unless it be repugnant to the subject or context thereof, include its
                                successors and permitted assigns); of the<b> SECONDPART;</b> The Client
                                and<b> {clientBrandName} </b>are, wherever the context so admit,
                                hereinafter individually referred to as the “Party” and collectively as
                                “Parties”.
                            </p>

                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3>WHEREAS</h3>
                            <ol type="a" style={{ fontweight: "400" }}>
                                <li>

                                    <b> {clientBrandName} </b> is a company that conducts business in
                                    the business of inter alia payment gateway aggregation, developing
                                    and implementing payment solutions, facilitating the initiation and
                                    receipt of electronic payments, including but not limited to the
                                    electronic payment transactions industry and offers a variety of
                                    services such as mobile recharges, DTH recharges, Bharat Bill
                                    Payment Services, Domestic money transfers, Aadhaar-enabled payment
                                    services, micro ATM, PAN Card applications, cash management
                                    services, Loan referrals, Prepaid cards, Withdrawals and Deposits,
                                    Utility bills, Account opening, Coupons and E-voucher, KYC services,
                                    Consumer wallet update, Travel ticketing, Biometric devices, POS
                                    devices, UPI transactions, QR transactions, Payouts, Software
                                    solutions, Mobile applications, Server maintenance, SMS service,
                                    Insurance, and Email service. The company has entered into business
                                    arrangements with various service providers, billers, and similar
                                    entities (collectively referred to as
                                    <b>"Service Providers"</b>
                                    ), for the purpose of managing electronic payments and collections
                                    services, which are more specifically defined as "Services"
                                    hereinafter.

                                </li>
                                <li>

                                    The Client hereby represents and warrants to
                                    <b> {clientBrandName} </b> that it possesses the necessary and
                                    requisite infrastructure, including sufficient space, manpower, and
                                    adequate financial resources to distribute the Services through its
                                    own retail distribution channels (hereinafter defined as
                                    <b>"Retail Outlets"</b>), or online portals, e-commerce websites as
                                    the case may be, to end-customers in accordance with the terms of
                                    this Agreement.

                                </li>
                                <li>

                                    <b> {clientBrandName} </b> has entered into this Agreement with the
                                    Client for the distribution of the Services solely relying upon the
                                    representations, warranties, and covenants made by the client. The
                                    terms of this Agreement are to be performed in accordance with the
                                    aforementioned representations, warranties, and covenants made by
                                    the client.

                                </li>
                              

                            </ol>

                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                        <ol type="a" start="4">
                                <li>
                                        
                                    <b> {clientBrandName} </b> has developed software protocols to,
                                    inter alia, communicate with acquirers to provide the services.

                                    </li>
                                    <li>

                                    The client has approached <b> {clientBrandName} </b> to avail
                                    services (defined hereinafter) to enable customers (defined
                                    hereinafter) to make payments to the client, and
                                    <b> {clientBrandName} </b> has agreed to provide services in
                                    accordance with the terms and conditions of this agreement.

                                    </li>
                                    <li>
                                    The Parties are now desirous of entering into this Agreement for the
                                    purpose of recording the rights and obligations inter se the
                                    Parties.

                                </li>

                                </ol> 

                                
                            NOW THEREFORE, in consideration of the mutual agreements, covenants,
                            representations, and warranties set forth in the Agreement, and for
                            other good and valuable consideration, the receipt and sufficiency of
                            which is acknowledged by the Parties, the Parties hereby agree as
                            follows:           
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol type="a" start="6">
                              
                            </ol>

                            <h3>1: DEFINITIONS</h3>

                            In this Service Agreement, unless the context otherwise requires:
                            <ol type="1" >
                                <li >

                                    The term <b>"Effective Date"</b> shall refer to the date of this
                                    Agreement.

                                </li>
                                <li >
                                    The term <b>" {clientBrandName} "</b> shall refer to entities
                                    with whom {clientBrandName} has established business
                                    arrangements or agreements for the purpose of offering Services.

                                </li>
                                <li >

                                    The Term <b>“Affiliate”</b> shall mean, in relation to a
                                    company:

                                    <ol type="a">
                                        <li>its Subsidiary;</li>
                                        <li>its Holding Company</li>
                                        <li>

                                            or any other Subsidiary of that Holding Company (including
                                            head offices and branches of the above).

                                        </li>
                                    </ol>
                                </li>
                                <li >

                                    The term <b>“Applicable Law”</b> means to the extent applicable
                                    to a Party, any applicable statute, law, regulation, ordinance,
                                    rule, judgment, rule of law, order, decree, clearance, approval,
                                    directive, guideline, policy, requirement, or other governmental
                                    restriction or any similar form of decision, or determination
                                    by, or any interpretation or administration of any of the
                                    foregoing by any governmental authority in effect, as amended
                                    from time to time.

                                </li>
                                <li >

                                    The term <b>“Authorised Signatories”</b> each person:

                                    <ol type="i">
                                        <li>

                                            specified as an authorised signatory of a Party in any
                                            documents delivered to any other Party pursuant to this
                                            Tri-Party Service Agreement;

                                        </li>
                                        <li>

                                            authorised to sign all documents and notices on behalf of
                                            the relevant Party, as applicable.

                                        </li>
                                    </ol>
                                </li>
                                <li >

                                    The term <b>“Business Day”</b> shall mean a day other than
                                    Saturday or Sunday or a national holiday or a public holiday. It
                                    is clarified herein that if any obligation is falling on a
                                    holiday, then the same shall be fulfilled on the succeeding
                                    Business Day.

                                </li>

                            </ol>

                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol type="7" start="7">
                                <li >

                                    The term <b>“Business Hours”</b> shall mean the working hours on
                                    a Business Day from 09:30 AM to 06:00 PM.

                                </li>
                                <li >

                                    The term <b>“Control”</b> shall mean where one person (either
                                    directly or indirectly and whether by share capital, voting
                                    power, contract or otherwise) has the power to appoint and / or
                                    remove the majority of the members of the governing body of
                                    another person or otherwise controls or has the power to control
                                    the affairs and policies of that other person and that other
                                    person is taken to be <b>“Controlled”</b> by the first person.

                                </li>
                                <li >

                                    The term <b>"Services"</b> shall refer to payment gateway
                                    aggregation, developing and implementing payment solutions,
                                    facilitating the initiation and receipt of electronic payments,
                                    including but not limited to the electronic payment transactions
                                    industry and offers a variety of services such as mobile
                                    recharges, DTH recharges, Bharat Bill Payment Services, Domestic
                                    money transfers, Aadhaar-enabled payment services, micro ATM,
                                    PAN Card applications, cash management services, Loan referrals,
                                    Prepaid cards, Withdrawals and Deposits, Utility bills, Account
                                    opening, Coupons and E-voucher, KYC services, Consumer wallet
                                    update, Travel ticketing, Biometric devices, POS devices, UPI
                                    transactions, According to the terms of this agreement, the
                                    client will provide customers with QR transactions, Payouts,
                                    software solutions, mobile applications, server maintenance, SMS
                                    service, insurance, email service, and any other pre-paid and
                                    post-paid payment collection services that
                                    <b> {clientBrandName} </b> makes available to the client. A
                                    more detailed description of the services can be found in
                                    Schedule I of this agreement.

                                </li>
                                <li >

                                    The term <b>"Customer(s)"</b> shall refer to individual
                                    customers of the Client who utilize the Services on a retail
                                    basis (i.e., B2B basis) through the Client's Retail Outlets or
                                    through the Client's online/mobile software applications or
                                    websites.

                                </li>
                               
                            </ol>
                        </div>



                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol type="11" start="11">

                            <li >

The term <b>"Confidential Information"</b> shall refer to, but
not be limited to, any and all information related to this
Agreement, as well as information about
<b> {clientBrandName} </b>, its business practices,
administrative structure, financial positions, accounts, data,
trade secrets, future plans, pricing, discounts, business,
financial and marketing strategy, whether communicated orally,
in writing, or by any other means, and regardless of whether
such information is marked or identified as confidential.

</li>

                                <li >
                                    <strong>“Force Majeure Event”</strong> means any:
                                    <ol type="a">
                                        <li>

                                            flood, storm, earthquake or another natural event;

                                        </li>
                                        <li>

                                            war hostilities terrorism, revolution, riot or civil
                                            disorder;

                                        </li>
                                        <li>

                                            strike, lockout or other industrial action;

                                        </li>
                                        <li>

                                            change in any law or any change in the interpretation or
                                            enforcement of any law;

                                        </li>
                                        <li>

                                            act or order of any Government Authority;

                                        </li>
                                        <li>

                                            order of any court or other judicial body;

                                        </li>
                                        <li>

                                            restriction or impending restriction on the availability,
                                            convertibility, credit or transferability of any currency;

                                        </li>
                                        <li>

                                            computer system malfunction or failure (regardless of cause)
                                            or any third-party interference with a computer system;

                                        </li>
                                        <li>

                                            error, failure, interruption, delay or non-availability of
                                            any goods or services supplied to the Parties by a third
                                            party; or

                                        </li>
                                        <li>

                                            other circumstance beyond the reasonable control of the
                                            Service provider;

                                        </li>
                                    </ol>
                                </li>
                              

                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                        <ol type="13" start="13">
                                      <li >

                                    The term <b>“e-wallet”</b> is an arrangement between a client
                                    and <b> {clientBrandName} </b>. <b>“e-wallet”</b> serves as a
                                    non-interest-bearing digital wallet that client shall maintain
                                    with <b> {clientBrandName} </b>. The clients can load money
                                    into this wallet through bank transfers or deposits to
                                    <b> {clientBrandName} ’s</b> bank account, and the funds stored
                                    in the wallet can then be operated in terms of this Tri-Party
                                    Service Agreement

                                </li>
                                <li >

                                    The term <b>“Government Authority”</b> or<b> “Authority”</b>
                                    means any governmental, semi-governmental, administrative,
                                    fiscal, regulatory or judicial body, department, commission,
                                    authority, courts, tribunal, agency or entity in India or with
                                    jurisdiction over any of the Parties and / or the transactions
                                    contemplated hereunder;

                                </li>
                                <li >

                                    The term <b>“Holding Company”</b> shall mean, in relation to a
                                    company, a company in respect of which the first named company
                                    is a Subsidiary;

                                </li>
                                <li >

                                    The term <b>“Laws”</b> means any law, statute, ordinance, rule,
                                    regulation, guideline, policy or other pronouncement having the
                                    effect of law of any Government Authority, as currently
                                    interpreted and administered;

                                </li>
                                <li >

                                    The term <b>“Losses”</b> means any losses, damages, demands,
                                    claims, liabilities, costs (including legal costs) and expenses
                                    of any kind (including any direct, indirect or consequential
                                    losses, loss of profit, loss of goodwill and loss of reputation)
                                    whether or not they were foreseeable or likely to occur;

                                </li>   

                                <li >

The term <b>“Restricted Party”</b> shall mean a person that is:

<ol type="i">
    <li>

        listed on, or owned or controlled by a person listed on, or
        acting on behalf of a person listed on, any Sanctions List;

    </li>
    <li>

        located in, incorporated under the laws of, or owned or
        (directly or indirectly) controlled by, or acting on behalf
        of, a person located in or organized under the laws of a
        country or territory that is the target of country-wide or
        territory-wide Sanctions;

    </li>
    <li>

        or otherwise a target of Sanctions (<b
        >target of Sanctions</b> signifying a person with whom a US person or other
        national of a Sanctions Authority would be prohibited or
        restricted by law from engaging in trade, business or other
        activities);

    </li>
</ol>
</li>
                                </ol>     
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol type="19" start="19">
                             
                                <li >

                                    The term <b>“Sanctions”</b> shall mean the economic sanctions
                                    laws, regulations, embargoes or restrictive measures
                                    administered, enacted or enforced by:

                                    <ol type="i">
                                        <li>
                                            the United States government;
                                        </li>
                                        <li>the United Nations;</li>
                                        <li>the European Union</li>
                                        <li>the United Kingdom;</li>
                                        <li>

                                            or the respective governmental institutions and agencies of
                                            any of the foregoing, including, without limitation, the
                                            Office of Foreign Assets Control of the US Department of
                                            Treasury (“<b>OFAC</b>”), the United States Department of
                                            State, and Her Majesty's Treasury (“<b>HMT</b>”) or

                                        </li>
                                        <li>the Reserve Bank of India;</li>
                                    </ol>
                                    (together “<b>the Sanctions Authorities</b>”);
                                </li>
                                <li >

                                    The term <b>“Sanctions List”</b> shall mean the “Specially
                                    Designated Nationals and Blocked Persons” list maintained by
                                    OFAC, the Consolidated List of Financial Sanctions Targets and
                                    the Investment Ban List maintained by HMT, or any similar list
                                    maintained by the Reserve Bank of India, or public announcement
                                    of Sanctions designation made by, any of the Sanctions
                                    Authorities;

                                </li>
                                <li >

                                    <b>“Seller(s)”</b> shall mean the entities onboarded as
                                    ‘sellers’ on the Platform.

                                </li>

                            </ol>
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol type="22" start="22">

                                <li >

                                    The term <b>“Subsidiary”</b> shall mean, in relation to a
                                    company, any other company:

                                    <ol type="a">
                                        <li>

                                            which is Controlled, directly or indirectly, by the first
                                            named company;

                                        </li>
                                        <li>

                                            more than half the issued share capital of which is
                                            beneficially owned, directly or indirectly, by the first
                                            named company;

                                        </li>
                                        <li>

                                            or which is a Subsidiary of another Subsidiary of the first
                                            named company.

                                        </li>
                                    </ol>
                                </li>
                                <li >

                                    The term <b>"Intellectual Property Rights"</b> shall refer to
                                    all information, whether in written or oral form, including but
                                    not limited to documentation, specifications, reports, data,
                                    notes, drawings, models, patterns, samples, software, computer
                                    outputs, designs, circuit diagrams, inventions (whether
                                    patentable or not), and know-how, that belongs to
                                    <b> {clientBrandName} </b>.

                                </li>
                                <li >

                                    The term <b>"Transaction Acknowledgement Confirmation"</b> shall
                                    mean an electronic slip, receipt, acknowledgment, pop-up
                                    display, email, SMS, or paper acknowledgment displayed on the
                                    client's front-end Point-of-Sale Device (PoS) or given by the
                                    client to the customer, acknowledging receipt of payment(s) from
                                    the customer for the <b>"Services."</b>

                                </li>
                                <li >

                                    The term <b>“Term”</b> means the period of this Agreement
                                    commencing with the effective date, unless terminated earlier in
                                    accordance with the present Agreement.

                                </li>
                                <li >

                                    The term <b>“Retail Outlets”</b> shall mean physical and virtual
                                    points of sale, including kiosks, payment terminals, web
                                    portals, mobile applications, and other such authorized software
                                    and hardware combinations of the client that enables the client
                                    to provide services to customers and transmit such transactional
                                    information through the API integration system to
                                    <b> {clientBrandName} </b>.

                                </li>
                            </ol>
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3>2: NON-EXCLUSIVE ENGAGEMENT
                            </h3>
                            <ol type="1" start="1">
                                <li >

                                    Relying on the Client representations and warranties,
                                    <b> {clientBrandName} </b> hereby appoints Client for the
                                    purpose of offering Services to the Customers of the Client. The
                                    appointment of the Client is subject to the terms and conditions
                                    set forth in this Agreement.

                                </li>
                                <li >

                                    This appointment is non-exclusive and
                                    <b> {clientBrandName} </b> reserves its right to appoint one or
                                    more Clients, other representatives, or agents to sell, market,
                                    and/or distribute the Services in the same or different
                                    geographical area. Further, <b> {clientBrandName} </b> shall be
                                    entitled to sell, market, and/or distribute the same directly by
                                    itself as <b> {clientBrandName} </b> may deem fit in its sole
                                    discretion.

                                </li>
                                <li >

                                    The Client acknowledges that it is acting for the limited and
                                    exclusive purpose of this Agreement, which does not constitute
                                    the Client as an agent, employee, partner, joint venture,
                                    affiliate, or group company of <b> {clientBrandName} </b>. The
                                    Client is appointed on a principal-to-principal basis authorized
                                    and permitted to market the Services only in accordance with the
                                    terms and conditions of this Agreement. The Client shall offer
                                    Services on an ‘as-is-where-is’ basis only. None of the
                                    employees of the Client shall be construed or deemed to be the
                                    employees of <b> {clientBrandName} </b>
                                    at any time and Client shall indemnify and keep indemnified
                                    <b> {clientBrandName} </b>, its directors & officers, and its
                                    affiliate/associate against any claim, demand, loss, or
                                    whatsoever in this connection.

                                </li>
                                <li >

                                    If <b> {clientBrandName} </b> so requests, the client shall
                                    provide <b> {clientBrandName} </b> with a list of its retail
                                    outlets on the effective date.

                                </li>
                            </ol>
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3>3: SERVICES</h3>
                            <ol type="1">
                                <li >

                                    The Scope of Services (‘Services’) to be provided and availed
                                    under this Agreement and the Obligations of the Parties in the
                                    performance of the Services shall be as detailed in Schedule I
                                    attached hereto.

                                </li>
                                <li >

                                    Both parties may occasionally request extensions or changes to
                                    Schedule I, and if both parties agree, such extensions or
                                    changes to services shall be effective as of the date agreed
                                    upon and executed between the parties in writing, including
                                    electronic mail. In the event of any change in the work
                                    arrangement between
                                    <b> {clientBrandName} </b> and any of the
                                    <b> {clientBrandName} </b> that, in the opinion of
                                    <b> {clientBrandName} </b>, impacts its ability to continue
                                    providing such services, <b> {clientBrandName} </b> shall have
                                    the absolute discretion to discontinue such services and/or
                                    modify the applicable terms and conditions in respect thereof.

                                </li>
                            </ol>
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#ccc solid 3px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3>4: COMMISSION / CHARGES</h3>
                            <ol type="1">
                                <li >

                                    As full and final consideration towards providing services in
                                    terms of this agreement, the client shall be entitled to receive
                                    commission Payouts from <b> {clientBrandName} </b> or be
                                    charged a fee by <b> {clientBrandName} </b>, as detailed in
                                    Schedule I attached hereto, and the same shall be credited or
                                    debited, as the case may be, to the client in the manner
                                    provided in Schedule I. The rates of commission or fee are
                                    subject to periodic review between the parties and may be
                                    modified thereafter with prior written notice.

                                </li>
                                <li >

                                    Each party shall be responsible for its own taxes and duties,
                                    except for withholding taxes, which shall apply as per
                                    applicable laws. Each party agrees to report and pay its own
                                    taxes imposed on its income by direct or indirect tax laws. The
                                    parties further agree that if such taxes are imposed, the burden
                                    of such taxes shall be the respective party's responsibility.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3>5: RESPONSIBILITIES, DUTIES, AND OBLIGATIONS OF THE CLIENT
                            </h3>
                            The Client hereby covenants to <b> {clientBrandName} </b> that:
                            <ol type="1">
                                <li >



                                    <ol type="a">
                                        <li>

                                            The Client shall maintain a pre-funded e-wallet replenished
                                            from time to time and to be utilized in accordance with
                                            Schedule I;

                                        </li>
                                        <li>

                                            Client shall be responsible for duties and obligations, as
                                            expressly set forth herein and hereby undertakes to perform
                                            the same as per terms of this Agreement;

                                        </li>
                                        <li>

                                            The Client acknowledges that it shall have absolute control
                                            over all its retail outlets' functioning in terms of
                                            applicable laws. The Client shall be fully responsible for
                                            the remuneration/commission/any other payment/ consideration
                                            that may be payable to the Retail Outlets in respect of the
                                            Services and <b> {clientBrandName} </b> shall not be held
                                            responsible for any such payments, whatsoever. Client shall
                                            be liable to ensure that the Retail Outlets do not commit
                                            any act or omission, which may result in violation of this
                                            Agreement;

                                        </li>
                                        <li>

                                            Client must ensure that the Retail Outlets do not charge the
                                            Customers any more than that prescribed by
                                            <b> {clientBrandName} </b> from time to time;

                                        </li>
                                        <li>

                                            The Client is to provide the Retail Outlet details to
                                            <b> {clientBrandName} </b> on a case-to-case basis if any
                                            suspicious transaction originating from Client’s platform is
                                            noticed for the purpose of FIU Reporting.

                                        </li>
                                    </ol>
                                </li>
                               

                            </ol>

                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                        

                        <ol start="2" type="1">


                        <li >

AS PER THE TERMS OF THIS AGREEMENT, THE CLIENT SHALL:

<ol type="a">
    <li>

        Implement Customer KYC checks in the form as may be
        prescribed by <b> {clientBrandName} </b> in writing, from
        time to time and any failure in implementing such checks
        shall be treated as a material breach;

    </li>
    <li>

        Not commit a breach or violate any of the terms and
        conditions of this Agreement and shall also honour and
        comply with such instructions as may be issued by
        <b> {clientBrandName} </b> from time to time;

    </li>
    <li>

        Ensure that the Customer Application Form (if any) is
        complete in all respects and shall reject any incomplete
        Customer Application Forms or such Customer Application
        Forms wherein there is any inconsistency in the information
        provided by the Customer;

    </li>
    <li>

        Shall pay all taxes, including but not limited services tax
        and service tax as applicable, payable on its own earn
        services the performance in service agreement and any
        statutory increase in respect thereof.

    </li>
</ol>
</li>

<li >

The client hereby agrees that it shall not offer the Services at
a price higher than the maximum stipulated value of the Service,
as determined by the Service provider.

</li>
<li >

The client shall promptly inform <b> {clientBrandName} </b> in
writing of any change in the constitution of the client and
shall submit a copy of the revised constitutional documentation
to <b> {clientBrandName} </b> within a reasonable time.

</li>
<li >

The client shall maintain all required records, registers,
account books, and other documentation that are mandatory under
any Applicable Law.

</li>
<li >

The client shall provide such information as may be required
under any Applicable Law to any authority.

</li>
                        </ol>
                        
                        </div>
                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <ol start="7" type="7">

                              
                                <li >

                                    The client shall comply with all applicable laws and regulations
                                    governing the Services and shall not engage in any illegal or
                                    unethical activity.

                                </li>
                                <li >

                                    The client shall cooperate with <b> {clientBrandName} </b> in
                                    any investigation or inquiry conducted by any authority in
                                    connection with the Services.

                                </li>
                                <li >

                                    The client shall indemnify and hold harmless
                                    <b> {clientBrandName} </b>, its affiliates, and its officers
                                    and employees from any loss, liability, or damages arising out
                                    of or in connection with any breach of the terms of this
                                    agreement or any Applicable Law by the client.

                                </li>
                                <li >

                                    This agreement shall be governed by and construed in accordance
                                    with the laws of the jurisdiction in which the Services are
                                    provided.

                                </li>
                                <li >

                                    Any dispute arising out of or in connection with this agreement
                                    shall be resolved through arbitration in accordance with the
                                    rules of the relevant arbitration body.

                                </li>
                                <li >

                                    This agreement represents the entire understanding and agreement
                                    between the parties and supersedes all prior negotiations,
                                    understandings, and agreements between them, whether written or
                                    oral.

                                </li>
                                <li >

                                    The client hereby agrees that neither the client nor any Retail
                                    Outlets appointed by the client or any of its representatives
                                    shall make or give directly or indirectly, orally or in writing,
                                    any guarantees, representations, or warranties, express or
                                    implied, with respect to the Services, rate packages in the
                                    Services, to the Customers or any Person, except as expressly
                                    authorized by <b> {clientBrandName} </b>

                                </li>
                                <li >

                                    The client shall be solely responsible for all tax or statutory
                                    liabilities arising in connection with transactions or any other
                                    obligations pertaining to the clientship.

                                </li>
                            </ol>

                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <ol start="15" type="15">
                                <li >

                                    The client shall be responsible for compliance and payment of
                                    all taxes, duties, levies, cess, surcharge, or any other charges
                                    that may be applicable to the client for the Services under this
                                    agreement.

                                </li>
                                <li >

                                    At the end of every financial year, the client shall provide a
                                    written confirmation to <b> {clientBrandName} </b>that it has
                                    declared the income arising out of the transactions with
                                    <b> {clientBrandName} </b>and has paid taxes thereon. The
                                    client shall further confirm that it has filed the Income-tax
                                    return in respect of the stated transactions done with
                                    <b> {clientBrandName} </b>

                                </li>

                                <li >

                                    The client shall maintain accurate records of all transactions
                                    and shall provide such records to
                                    <b> {clientBrandName} </b>upon request.

                                </li>
                                <li >

                                    The client shall indemnify and hold harmless
                                    <b> {clientBrandName} </b> its affiliates, and its officers and
                                    employees from any loss, liability, or damages arising out of or
                                    in connection with any breach of the terms of this agreement or
                                    any Applicable Law by the client.

                                </li>
                                <li >

                                    This agreement shall be governed by and construed in accordance
                                    with the laws of the jurisdiction in which the Services are
                                    provided.

                                </li>
                                <li >

                                    Any dispute arising out of or in connection with this agreement
                                    shall be resolved through arbitration in accordance with the
                                    rules of the relevant arbitration body. (repeated as 5.11)

                                </li>
                                <li >

                                    This agreement represents the entire understanding and agreement
                                    between the parties and supersedes all prior negotiations,
                                    understandings, and agreements between them, whether written or
                                    oral.

                                </li>

                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >6: TERM AND TERMINATION</h3>
                            <ol type="1">
                                <li >

                                    That the Agreement shall commence from the Effective Date and
                                    shall continue to be in full force until and unless terminated
                                    by any of the party earlier in accordance with the terms hereof.
                                    It is decided that in case either party decides to terminate the
                                    agreement then such party shall send a written notice to the
                                    opposite party before 30 days from the date of such termination
                                    of agreement.

                                </li>
                                <li >

                                    IT IS POSSIBLE TO END THIS AGREEMENT BY:

                                    <ol type="a">
                                        <li>

                                            the onset of a ‘Force Majeure Event’;

                                        </li>
                                        <li>

                                            either party with or without cause by giving a written
                                            notice of at least [30] days to the other party.

                                        </li>
                                        <li>

                                            either Party ("Non-Defaulting Party") with cause by giving
                                            written notice to the other Party ("Defaulting Party"),
                                            provided that such notice specifies the breach that the
                                            Defaulting Party committed and if such breach is remediable
                                            but is not remedied within a period of [30] days from the
                                            date of such notice;

                                        </li>
                                        <li>

                                            either Party with immediate effect, if the other Party has
                                            entered into winding-up or liquidation proceedings, whether
                                            voluntarily or involuntarily or a petition for its winding
                                            up is admitted by a court of law or a receiver or liquidator
                                            is appointed over its assets or undertaking.

                                        </li>
                                    </ol>
                                </li>
                                <li >

                                    Termination shall not affect rights, liabilities, and
                                    obligations of the Parties accrued prior or at Termination, and
                                    such rights, liabilities, and obligations shall continue to be
                                    binding on the Parties.

                                </li>
                                <li >

                                    Upon termination, the client shall immediately cease all use of
                                    <b> {clientBrandName} ’s</b> intellectual property, including
                                    but not limited to its trademarks, logos, and service marks.

                                </li>
                                <li >

                                    In the event of termination, any outstanding payments owed to
                                    <b> {clientBrandName} </b> shall become immediately due and
                                    payable.

                                </li>
                            </ol>
                        </div>



                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3>7:  DATA & SYSTEM SECURITY AND COMPLIANCE WITH REGULATIONS/ STANDARDS
                            </h3>
                            <ol type="1">
                                <li >

                                    The client shall ensure compliance with all statutory
                                    obligations regarding data security and privacy, including but
                                    not limited to the Information Technology Act, 2000, as amended
                                    from time to time. The client shall not disclose, without the
                                    prior written consent of  {clientBrandName} , any information
                                    pertaining to the identity of any customer or any information
                                    related to any transaction to any third party, except for the
                                    purposes of this agreement. However, the client may disclose
                                    such information if required by a court order or regulatory
                                    authority of competent jurisdiction, to the extent specified in
                                    the order. The client shall take all necessary measures to
                                    safeguard the confidentiality and security of all customer data
                                    and shall promptly notify  {clientBrandName}  in case of any
                                    data breach or security incident.

                                </li>
                            </ol>

                            <h3 >8: REPRESENTATIONS AND WARRANTIES</h3>
                            <ol type="1">
                                <li >

                                    EACH PARTY REPRESENTS AND WARRANTS TO THE OTHER PARTY THAT:

                                    <ol type="a">
                                        <li>

                                            It is a duly constituted and validly existing entity under
                                            the laws of India.

                                        </li>
                                        <li>

                                            It possesses all necessary licenses, approvals, and consents
                                            required for its business operations, and such licenses,
                                            approvals, and consents are currently valid and subsisting.

                                        </li>
                                        <li>

                                            It has the capacity, as per its constitutional documents and
                                            applicable laws, to enter into and execute this Agreement,
                                            and has taken all requisite actions, including obtaining
                                            necessary statutory and other approvals, to enter into this
                                            Agreement.

                                        </li>
                                        <li>

                                            Neither the making nor the performance of this Agreement
                                            shall violate any law or conflict with, breach, or
                                            constitute a default that requires any consent under any
                                            decree, order, judgment, indenture, or agreement.

                                        </li>
                                       
                                    </ol>
                                </li>
                            </ol>

                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                              
                                <ol type="a" start="5">
                                    <li>

                                    It shall comply with all applicable union, state, and local
                                    laws, ordinances, regulations, and codes while performing
                                    its obligations under this Agreement, including the
                                    procurement of licenses, permits, and certificates, and
                                    payment of taxes as and when required by law.

                                    </li>
                                </ol>       

                                
                         </div>               

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >9: CONFIDENTIALITY</h3>
                            <ol type="1">
                                <li >

                                    The Client hereby agrees and undertakes that, throughout the
                                    term of this Agreement, it shall not, under any circumstances,
                                    disclose any Confidential Information, whether directly or
                                    indirectly, concerning the business or affairs of
                                    <b> {clientBrandName} </b> to any person, without the prior
                                    written consent of <b> {clientBrandName} </b>.

                                </li>
                                <li >

                                    Notwithstanding the foregoing, the client may disclose
                                    confidential information to its employees, officers, and
                                    representatives who have a need to know such information for the
                                    purposes of carrying out the client's obligations under this
                                    agreement. The client shall take all reasonable steps to ensure
                                    that such employees, officers, and representatives comply with
                                    the terms of this clause and maintain the confidentiality of the
                                    confidential information.

                                </li>
                                <li >

                                    In addition, the Client may disclose Confidential Information if
                                    and to the extent required by law, court order, or any
                                    governmental or regulatory authority provided that the Client
                                    gives <b> {clientBrandName} </b> prompt written notice of such
                                    disclosure prior to the disclosure, and cooperates with
                                    <b> {clientBrandName} </b> in seeking to obtain a protective
                                    order or other appropriate protection for the Confidential
                                    Information.

                                </li>
                            </ol>
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3>10: EXCEPTIONS</h3>

                            <ol type="1">
                                <li >

                                    THE AFORESAID CONFIDENTIALITY OBLIGATIONS SHALL IMPOSE NO
                                    OBLIGATION ON THE RECEIVING PARTY WITH RESPECT TO ANY PORTION OF
                                    CONFIDENTIAL INFORMATION WHICH:

                                </li>
                                <li >

                                    was at the time received or which thereafter becomes, through no
                                    act or failure on the part of the Receiving Party, generally
                                    known or available to the public;

                                </li>
                                <li >

                                    is, at the time of receipt, known to the Receiving Party as
                                    evidenced by written documentation then rightfully in the
                                    possession of either Party;

                                </li>
                                <li >

                                    was already acquired by the Receiving Party from a third party
                                    who does not thereby breach an obligation of confidentiality to
                                    the Disclosing Party and who discloses it to the Receiving Party
                                    in good faith;

                                </li>
                                <li >

                                    is developed by the Receiving Party without use of the
                                    Disclosing Party’s Confidential Information in such development.

                                </li>
                                <li >

                                    has been disclosed pursuant to the requirements of Applicable
                                    Law, any Governmental Authority, provided however, that the
                                    Disclosing Party shall have been given a reasonable opportunity
                                    to resist disclosure and/or to obtain a suitable protective
                                    order.

                                </li>
                            </ol>

                            The obligations of the client under this clause shall survive the
                            termination or expiration of this agreement for any reason.

                            <h3>11: INSPECTION OF BUSINESS, RECORDS, AND REPORTS</h3>
                            <ol><li>
                                The client shall provide access to  {clientBrandName}  and its
                                employees and authorized personnel to inspect the reports and
                                financial information as regards the subject matter of this
                                agreement, together with such details, breakdowns, and supporting
                                records.
                            </li></ol>


                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <h3 >12: RIGHTS RELATED TO INTELLECTUAL PROPERTY</h3>
                            <ol type="1">
                                <li >

                                    The client acknowledges and agrees that all intellectual
                                    property rights, including trademarks, patents, design marks,
                                    and any other intellectual property owned or controlled by <b>
                                        {clientBrandName} </b>,
                                    whether registered under the relevant provisions of law or
                                    not, are the exclusive properties of <b> {clientBrandName} </b>.
                                    This Agreement shall not give, and shall not be deemed to
                                    give, to the Client any right, title, interest, or ownership of
                                    the intellectual property rights or any part thereof,
                                    promotional advertising, or other written materials relating to
                                    the Service except for the right to use any intellectual
                                    property rights strictly in accordance with the terms and
                                    conditions of this Agreement.

                                </li>
                                <li >

                                    That all goodwill associated with such intellectual property
                                    rights shall inure exclusively to the benefit of <b>
                                        {clientBrandName} </b>.
                                    The client covenants and agrees that it shall not use any of
                                    the intellectual property rights as any part of its corporate,
                                    firm, or business names and may only use such intellectual
                                    property rights in such manner and for such purpose as <b>
                                        {clientBrandName} </b>
                                    may specify and approve in writing.

                                </li>
                                <li >

                                    Except as expressly permitted in writing by <b>
                                        {clientBrandName} </b>
                                    or in accordance with the terms and conditions of this
                                    agreement, the client shall not use any of the intellectual
                                    property rights in any way that could suggest or indicate that
                                    it is the owner of the intellectual property rights or for any
                                    other purposes.

                                </li>
                                <li >

                                    The Client shall not, directly or indirectly, dispute or contest
                                    the validity or ownership of the intellectual property rights or
                                    the rights or ownership of <b> {clientBrandName} </b> thereto,
                                    try to register them, or try to lower the value of any goodwill
                                    attached to or lasting from the intellectual property rights
                                    of <b> {clientBrandName} </b> during the Term of this Agreement
                                    or at any time after.

                                </li>

                            </ol>
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <ol start="5" type="5">

                                <li >

                                    The Client shall not, directly or indirectly, dispute or contest
                                    the validity or ownership of the intellectual property rights or
                                    the rights or ownership of <b> {clientBrandName} </b> thereto,
                                    try to register them, or try to lower the value of any goodwill
                                    attached to or lasting from the intellectual property rights
                                    of <b> {clientBrandName} </b> during the Term of this Agreement
                                    or at any time afterward. The safekeeping and maintenance of the
                                    same shall be the responsibility of the client, and the client
                                    shall be responsible for any losses, damages, expenses, and
                                    costs arising on account of loss, theft, damage or destruction
                                    of such signage/signboards.

                                </li>
                                <li >

                                    If it becomes advisable at any time, in the sole discretion of
                                    <b> {clientBrandName} </b> , for the client to modify or
                                    discontinue the use of any of the intellectual property rights
                                    or to use one or more additional or substitute intellectual
                                    property rights, the client shall do so immediately on notice
                                    from <b> {clientBrandName} </b>.


                                </li>
                            </ol>

                            <h3>13: INDEMNITY</h3>


                            <ol type="1">
                                <li >
                                    THE FOLLOWING ARE THE KEY POINTS OF THE INDEMNIFICATION CLAUSE IN
                                    THE AGREEMENT BETWEEN THE CLIENT AND <b> {clientBrandName} </b>:
                                    <ol type="a">
                                        <li>

                                            Client agrees to indemnify, keep indemnified and hold
                                            harmless
                                            <b> {clientBrandName} </b> from any actions, proceedings,
                                            claims, liabilities, penalties, demands, costs, awards,
                                            damages, losses and/or expenses arising out of the discharge
                                            of the scope of work by the Client under this agreement. <b>
                                                {clientBrandName} </b> may be subjected to such claims either directly or through
                                            a legal suit.

                                        </li>
                                        <li>

                                            The indemnification covers any breach or non-performance by
                                            the client of its undertakings, warranties, covenants,
                                            declarations, or obligations under the agreement. The
                                            indemnification also covers any act, neglect, or default of
                                            client employees.

                                        </li>
                                        <li>

                                            Additionally, the indemnification covers any claim by any
                                            other party against <b> {clientBrandName} </b> arising from
                                            the breach or non-performance by the client or its
                                            employees.

                                        </li>
                                       
                                    </ol>
                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                           
                           <ol start="d" type='a'>

                           <li>

                                If any proceedings are undertaken which may give rise to the
                                Client's liability under this agreement, <b>
                                    {clientBrandName} </b> shall provide prompt notice to the Client and an
                                opportunity to participate in any such proceedings to
                                represent its interest appropriately.

                                </li>
                                <li>

                                Notwithstanding anything contained in the agreement, <b>
                                    {clientBrandName} </b> ’s liability to the Client in any event for any damages
                                shall not exceed the transaction value under dispute.

                                </li>

                           </ol>
                           
                           
                            <h3 >14: FORCE MAJEURE</h3>
                            <ol type="1">
                                <li >

                                    <b> {clientBrandName} </b> shall not be liable to Client for
                                    certain types of losses or damages, including loss of profit,
                                    goodwill, business or business opportunity, anticipated savings,
                                    special, indirect, or consequential damages, or any loss that is
                                    an indirect or secondary consequence of any act or omission of
                                    the party.

                                </li>
                                <li >

                                    The total liability of <b> {clientBrandName} </b> towards the
                                    Client shall be limited in all circumstances to the value of the
                                    specific Transaction under dispute.

                                </li>
                                <li >

                                    <b> {clientBrandName} </b> has no obligations to the client
                                    and/or its customers, other than putting the transaction value
                                    back into the client's wallet, in case of any loss or damage
                                    arising out of or in connection with this Agreement, any breach
                                    or non-performance by such party of any of its undertakings,
                                    warranties, covenants, declarations, or obligations under this
                                    Agreement, or otherwise in contract, tort (including negligence
                                    or breach of statutory duty), misrepresentation, or otherwise.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >15: GENERAL</h3>
                            <ol type="1">
                                <li >
                                    <h3 >NOTICES</h3>
                                    <ol type="a">
                                        <li >

                                            Notices, writings, and other communications under this
                                            Agreement may be delivered by hand, by registered mail, by
                                            electronic communication, by overnight courier service, or
                                            facsimile to the addresses and numbers specified as follows
                                            or to such other addresses and numbers as may be specified
                                            in writing to the Parties in the manner provided in this
                                            Clause:


                                            <p >
                                                In case of <b> {clientBusinessName} </b>
                                            </p>
                                            <p >To</p>
                                            <p >
                                                <b>{clientAuthorisedPersonTitle} <br />
                                                    {clientBusinessAddress}</b>
                                            </p>
                                            <p >
                                                <b>{clientBusinessEmail}</b>
                                            </p>

                                            <p >
                                                In case of <b>{clientUserBusinessName}</b>
                                            </p>
                                            <p >To</p>
                                            <p >
                                                <b>{clientUserAuthorisedPerson}</b>
                                                <br />
                                            </p>
                                            <p >
                                                <b>{clientUserBusinessName}</b>
                                            </p>
                                            <p >
                                                <b>Address: {clientUserBusinessAddress}</b>
                                            </p>
                                            <p >
                                                <b>{clientUserBusinessEmail}</b>
                                            </p>

                                        </li>
                                    </ol>
                                </li>
                                <li >

                                    Waiver: The failure of either party to require the performance
                                    of any terms of the agreement or the waiver of any breach shall
                                    not prevent subsequent enforcement or be deemed a waiver of any
                                    subsequent breach. Remedies are cumulative and may be enforced

                                </li>
                              

                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol start="3" type="3">

                            <li >

Entire Agreement: This agreement, together with all schedules,
constitutes the entire agreement between the parties and
supersedes all prior arrangements/agreements (oral or written).
There are no oral or implied agreements or warranties between
the parties.

</li>

                                <li >

                                    Severability: If any provision of the agreement is held invalid,
                                    the remaining provisions shall continue to be valid and binding.
                                    The parties shall negotiate in good faith to replace the invalid
                                    provision.

                                </li>
                                <li >

                                    Amendments: Any amendments to the agreement shall be effective
                                    only upon mutual consent of the parties in writing.

                                </li>
                                <li >

                                    Counterparts: The agreement shall be executed in two
                                    counterparts, each of which when executed and delivered by both
                                    parties shall be deemed an original.

                                </li>
                                <li >

                                    Good Faith: Each party will act in good faith in performing its
                                    responsibilities under the agreement and will not unreasonably
                                    delay, condition, or withhold any consent, decision, or approval
                                    requested by <b> {clientBrandName} </b>.

                                </li>
                                <li >

                                    Assignment: The Client shall not assign, transfer, or
                                    subcontract any of its rights, benefits, or obligations under
                                    the agreement without <b> {clientBrandName} </b>'s prior
                                    written consent.

                                </li>
                                <li >

                                    Capacity and Authority: The agreement is entered into between
                                    the parties on an independent principal-to-principal basis and
                                    does not create or establish any partnership, agency, or joint
                                    venture. The Client does not have the power or authority to bind
                                    or pledge credit to <b> {clientBrandName} </b>, except as
                                    expressly provided in the agreement.

                                </li>
                                <li >

                                    Costs: Each party shall bear its own costs in connection with
                                    its obligations under the agreement, including but not limited
                                    to the cost of connectivity, technical fees, additional software
                                    and hardware required for the Services to be enabled, except as
                                    expressly provided in the agreement.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >16: FRAUDULENT TRANSACTIONS</h3>
                            <ol type="1">
                                <li >

                                    Subject to Clause 16.2 and 16.3, if
                                    <b> {clientBrandName} </b>is intimated, by a Facility Provider,
                                    that a Customer has reported an unauthorised debit of the
                                    Customer's Payment Instrument ("FRAUDULENT TRANSACTION"), then
                                    <b> {clientBrandName} </b>shall be entitled to suspend the
                                    settlement of the amount associated with the Fraudulent
                                    Transaction during the pendency of inquiries, investigations and
                                    resolution thereof by the Facility Providers.

                                </li>
                                <li >

                                    Subject to Clause 16.3, if the Fraudulent Transaction results in
                                    a Chargeback, then the Chargeback shall be resolved in
                                    accordance with the provisions set out in paragraph 3.

                                </li>
                                <li >

                                    If the amount in respect of the Fraudulent Transaction has
                                    already been settled to the Client pursuant to the terms of this
                                    Agreement, any dispute arising in relation to the said
                                    Fraudulent Transaction, following settlement, shall be resolved
                                    in accordance with the RBI’s notification DBR.No.
                                    Leg.BC.78/09.07.005/2017-18, dated July 6, 2017 read with RBI’s
                                    notification DBOD. LEG. BC 86/09.07.007/2001-02 dated April 8,
                                    2002 and other notifications, circulars and guidelines issued by
                                    the RBI in this regard from time to time;

                                </li>
                                <li >

                                    If any fraudulent activity is traced back to the Client, then
                                    the Client shall be completely responsible for the same Activity
                                    and
                                    <b> {clientBrandName} </b> shall have the complete right to
                                    initiate legal proceedings against the Client. Further, it is
                                    agreed that in such a scenario the Client shall be liable to
                                    bear the expenses as incurred by <b> {clientBrandName} </b>.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >17: CHARGEBACKS</h3>
                            <ol type="1">
                                <li >

                                    If Acquirer or the Issuer communicates to <b>
                                        {clientBrandName} </b>
                                    the receipt of a Chargeback from a Customer, then the Client
                                    will forthwith be notified of the Chargeback.

                                </li>
                                <li >

                                    The Client shall be entitled to furnish to  {clientBrandName}
                                    documents and information ("CHARGEBACK DOCUMENTS") pertaining
                                    to the Transaction associated with the Chargeback in order to
                                    substantiate (I) the completion of the aforesaid Transaction
                                    and/or; (II) delivery of goods / services sought by the Customer
                                    pursuant to the said Transaction. Provided however if the Client
                                    is desirous of furnishing the Chargeback Document, the Client
                                    shall do so within five (5) days (or such other period specified
                                    by the Acquirer) of receiving notification of the Chargeback
                                    under paragraph 17.1.

                                </li>
                                <li >

                                    The Client agrees and acknowledges that (I) if the Client is
                                    unable to furnish Chargeback Documents stipulated in paragraph
                                    17.2 the Issuer is not satisfied with the Chargeback Documents
                                    furnished by the Client, then the Issuer shall be entitled to
                                    order the Acquirer or <b> {clientBrandName} </b> to affect a
                                    reversal of the debit of the Chargeback Amount associated with
                                    the Chargeback such that the said Chargeback Amount is credited
                                    to the Customer’s Payment Instrument.

                                </li>
                                <li >

                                    Pursuant to paragraph 17.3 and subject to paragraph 17.5, if the
                                    Acquirer or the Issuer charges the Chargeback Amount to <b>
                                        {clientBrandName} </b>,
                                    then the Client agrees and acknowledges that <b>
                                        {clientBrandName} </b>
                                    is entitled to charge such Chargeback Amount to the Client by
                                    way of deduction from the Transaction Amounts to be settled to
                                    the Client subsequent to <b> {clientBrandName} </b> incurring
                                    the charge of the Chargeback Amount.

                                </li>
                                <li >

                                    The Client further agrees and acknowledges that following <b>
                                        {clientBrandName} </b>
                                    incurring the charge stipulated in paragraph 17.4, if the
                                    available Transaction Amounts are insufficient for deduction of
                                    t, then <b> {clientBrandName} </b> is entitled to issue a debit
                                    note seeking reimbursement of the Chargeback Amount. The Client
                                    shall reimburse the Chargeback Amount within thirty (30) days of
                                    receipt of the debit note.

                                </li>

                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol start="6" type="6">
                                <li >

                                    On the issuance of notice of termination, <b>
                                        {clientBrandName} </b>
                                    reserves the right to withhold from each settlement made
                                    during the Notice Period, a sum computed based on a Stipulated
                                    Percentage (defined hereinbelow) for a period of one hundred and
                                    twenty (120) days ("WITHHOLDING TERM") from the date of
                                    termination of this Agreement. The sums so withheld shall be
                                    utilized towards settlement of Chargebacks. After processing
                                    such Chargebacks, <b> {clientBrandName} </b> shall transfer the
                                    unutilized amounts, if any, to the Client forthwith upon
                                    completion of the Withholding Term. The ‘STIPULATED PERCENTAGE’
                                    is the proportion of the Chargeback Amounts out of the total
                                    Transaction Amounts settled during the subsistence of this
                                    Agreement.

                                </li>
                                <li >

                                    Notwithstanding anything contained in paragraph 17.6, if the
                                    amount withheld pursuant to paragraph 17.6 is insufficient to
                                    settle Chargebacks Amounts received during the Withholding Term,
                                    then <b> {clientBrandName} </b> is entitled to issue a debit
                                    note seeking reimbursement of the Chargeback Amount. The Client
                                    shall reimburse the Chargeback Amount within thirty (30) days of
                                    receipt of the debit note.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#ccc solid 3px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >18: GOVERNING LAW AND JUDITION</h3>
                            <ol type="1">
                                <li >

                                    This Agreement shall be governed by and construed in accordance
                                    with the laws of India, and any dispute arising out of or in
                                    connection with this Agreement shall be exclusively resolved by
                                    the courts at {clientJurisdiction}.

                                </li>
                                <li >

                                    In the event of any dispute, controversy, claim, or disagreement
                                    between or among the Parties arising out of or in connection
                                    with this Agreement, including any question regarding its
                                    existence, validity, or termination, the Parties shall follow
                                    the dispute resolution procedures set out hereinafter.

                                </li>
                                <li >

                                    Any Party claiming that a dispute has arisen shall give written
                                    notice thereof to the other Parties as soon as practicable after
                                    the occurrence of the event, matter, or thing which is the
                                    subject of such dispute. The notice shall provide details of the
                                    circumstances and nature of the such dispute and of the Party's
                                    claim(s) in relation thereto and shall designate a
                                    representative for negotiations relating to the dispute. The
                                    other Parties shall respond within seven (7) business days of
                                    such notice, each specifying in writing its position in relation
                                    to the dispute and designating a representative with similar
                                    authority to settle the dispute.

                                </li>
                                <li >

                                    If, after 30 (thirty) business days from the date of such
                                    notice, the Parties have failed to reach an amicable settlement,
                                    either Party may explore available legal remedies under the
                                    applicable laws.

                                </li>
                                <li >

                                    This Agreement shall be governed by and construed in accordance
                                    with the laws of India. In the event of any dispute or
                                    difference arising under this Agreement, the Parties shall meet
                                    to arrive at an amicable resolution thereof. In the event that
                                    the Parties fail to arrive at an amicable resolution within 30
                                    days of the dispute having arisen, the matter shall be finally
                                    resolved by arbitration under a Sole Arbitrator under the
                                    provisions of the Indian Arbitration and Conciliation Act 1996
                                    (as amended from time to time). The seat and venue of the
                                    arbitration shall be Ghaziabad, India. The arbitration shall be
                                    conducted in the English language.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <p>
                                IN WITNESS WHEREOF the Parties hereto, have hereunto set and
                                subscribed their respective hands the day, month, and year herein
                                below written.
                            </p>

                            <div >
                                <div >
                                    <p>{clientBusinessName}</p>
                                    <p>{clientBusinessAddress}</p>
                                </div>
                                <div >
                                    <p>{clientUserBusinessName}</p>
                                    <p>{clientUserBusinessAddress}</p>
                                </div>
                            </div>
                            <div  >
                                <div >
                                    <p>Name : {signBy}</p>
                                    <p>Title : {position}</p>
                                    <p>
                                        Authorised Signatory & Company Seal
                                    </p>
                                    <img
                                        src={signImage}
                                        alt="Signature"
                                        style={{ maxHeight: "40px", marginRight: "10px" }}
                                    />
                                </div>
                                <div >
                                    <p>Name : {clientUserAuthorisedPerson}</p>
                                    <p>Title : {clientTitle}</p>
                                    <p>Authorised Signatory </p>
                                    <div id="aadharData"></div>
                                </div>
                            </div>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >19: COMPLIANCE WITH ANTI-BRIBERY, ANTI-CORRUPTION</h3>
                            <ol type="1">
                                <li >

                                    Each Party agrees to comply with all applicable commercial and
                                    public anti-bribery laws ("ANTI-BRIBERY LAWS") which prohibit
                                    corrupt offers of anything of value (either directly or
                                    indirectly) to Government Officials to obtain an improper
                                    commercial/business advantage. Government Officials include any
                                    government employee, candidate for public office; an employee of
                                    government - owned or government – controlled companies, public
                                    international organisation and political parties. Each Party
                                    agrees not to offer, pay, promise or authorise the payment,
                                    directly or through any other Person, of anything of value for
                                    the purpose of inducing or rewarding any favourable action or
                                    influencing or decision in favour of such Party.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3>20: SCOPE OF SERVICES</h3>
                            <ol type="1">
                                <li >

                                    Utilisation of Services or Products: It is imperative to note
                                    that all services provided by <b> {clientBrandName} </b> are
                                    subject to the specific conditions stipulated or defined by the
                                    relevant service issuer, provider, government authority,
                                    including but not limited to NPCI, RBI, or <b>
                                        {clientBrandName} </b>
                                    itself. In the event that the client utilizes the services
                                    offered by <b> {clientBrandName} </b> in connection with any
                                    other activity or violates any defined rules, it is essential to
                                    acknowledge that the client shall assume full responsibility for
                                    all associated penalties, obligations, or consequences thereof.

                                </li>
                                <li >

                                    Wallet and Transactions: The Parties agree to comply with all
                                    applicable laws and regulations related to the prevention of
                                    money laundering in India, including but not limited to the
                                    Prevention of Money Laundering Act, 2002 (PMLA) and its
                                    subsequent amendments, and any other rules or guidelines issued
                                    by the competent authorities from time to time. The parties
                                    shall implement adequate policies, procedures, and internal
                                    controls to ensure compliance with such laws and regulations.
                                    The parties shall conduct customer due diligence and ongoing
                                    monitoring of their clients, as required by the PMLA, and report
                                    any suspicious transactions to the Financial Intelligence Unit
                                    (FIU) in accordance with the prescribed timelines. The parties
                                    shall maintain all necessary records and documentation related
                                    to such transactions as required under the PMLA. In the event of
                                    any breach of this representation, the parties agree to
                                    indemnify and hold each other harmless from any losses, damages,
                                    or expenses incurred as a result of such a breach.

                                </li>
                               
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>


                        <ol start="3" type="3">
                                    
                                <li >

By utilizing our payment service, you are bound by the following
terms and conditions. It is important to note that you are only
permitted to add funds to and transfer payments from the
company's bank account for commercial purposes. Any other use of
the service is strictly prohibited. In the event that you choose
to add or deposit funds via a third-party account, whether
knowingly or unknowingly, or transfer money to an account
involved in any prohibited or suspicious activity, you will be
held solely responsible for any and all consequences that may
arise. Furthermore, it is essential to understand that any funds
transferred or deposited into your account are considered your
earned money, and we do not accept responsibility for any
discrepancies or disputes regarding the ownership of the funds.
Therefore, you bear full responsibility for any claims related
to the money that you have transferred or deposited.

</li>

<li >

We reserve the right to take any action deemed necessary,
including blocking payments, wallets, accounts, cards, or
amounts if we suspect any fraudulent or illegal activity
associated with your account. Additionally, you acknowledge and
agree that any regulatory or law enforcement department may
request information about your account, wallet, transaction, or
other specifics (such as KYC information), and we will share
such information in accordance with the law or regulation.

</li>

                                </ol>                
                        </div>



                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol start="5" type="5">
                              
                                <li >

                                    That both the parties acknowledge and agree that all applicable
                                    laws and regulations governing payments in India, as described
                                    by the Cyber Cell and the Government of India, are applicable to
                                    your use of our payment service. You agree to comply with all
                                    such laws and regulations in connection with your use of our
                                    payment service. We disclaim any and all liability for any
                                    damages or losses arising from your failure to comply with such
                                    laws and regulations. You further acknowledge and agree that you
                                    are responsible for any and all actions were taken by us or any
                                    regulatory or law enforcement department with respect to your
                                    account.

                                </li>
                                <li >

                                    The client must maintain a pre-funded monetary balance with
                                    <b> {clientBrandName} </b> up to a mutually agreed monetary
                                    limit ("Wallet"). <b> {clientBrandName} </b> will credit the
                                    Wallet only upon receipt of clear funds through NEFT/RTGS/IMPS
                                    fund transfer mode only and from the Bank account maintained in
                                    the name of the client Entity. The first-time credit into the
                                    Wallet must be for a minimum amount of Rs. 100,000. Cash
                                    deposits into the designated <b> {clientBrandName} </b> bank
                                    account will not be accepted for crediting the client's Wallet.

                                </li>
                                <li >

                                    The client must periodically replenish the utilized Wallet
                                    balance by depositing money into a designated bank account of <b>
                                        {clientBrandName} </b>.
                                    If the available wallet balance is insufficient to process
                                    the transactions, <b> {clientBrandName} </b> systems will
                                    decline them. No credit facility will be extended by <b>
                                        {clientBrandName} </b>
                                    for updating the Wallet balance without payment from the
                                    client into the designated Bank account of <b>
                                        {clientBrandName} </b>.


                                </li>
                                <li >

                                    The client can initiate and accept payment collection
                                    transactions ("Transaction(s)") from end customers only up to
                                    the available balance in the Wallet. Any requests to settle
                                    Transactions upon which the balance in the Wallet is
                                    insufficient shall be declined by <b> {clientBrandName} </b>.
                                    The client shall not pledge to the credit of
                                    <b> {clientBrandName} </b> or undertake obligations for and on
                                    behalf of <b> {clientBrandName} </b>.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ol start="10" type="10">
                                <li >

                                    Settlement and processing of Transactions shall be as per the
                                    mechanism prescribed in the 'Application Programming Interface'
                                    (API) Document and other technical details provided to the
                                    client by <b> {clientBrandName} </b>.

                                </li>
                                <li >

                                    Reconciliation The client must reconcile its Transactions by
                                    downloading data from the  {clientBrandName}  System on a daily
                                    basis. If any differences are observed by the client, it shall
                                    escalate the same to the designated contact point at
                                    {clientBrandName}  for resolution.

                                </li>
                                <li >

                                    Invalid Transactions In the event of invalid transaction
                                    requests,  {clientBrandName}  shall not be held liable except
                                    to the extent of prompt reversal of transaction value into the
                                    wallet, if at all debited from the wallet. Invalidity can arise
                                    from incorrect transaction request parameters or transactions
                                    not compliant with  {clientBrandName} 's API, technical
                                    guidelines, or service provider business rules.

                                </li>
                                <li >

                                    Pursuant to the signing of this agreement, access to the
                                    {clientBrandName}  System shall be activated upon payment of an
                                    upfront non-refundable fee towards API costs (as indicated
                                    below) at the time of execution of this agreement.

                                </li>

                                <table >
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Amount (in Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Upfront, Non-refundable cost</td>
                                            <td>{clientOnboardingFee}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <li >

                                    As full and final consideration for providing the Services, the
                                    client shall be entitled to commission as follows. Statutory
                                    deductions like TDS will be applicable on the commission
                                    Payouts:

                                </li>
                                <li >

                                    Unless otherwise requested by the client in writing, the
                                    commissions payable to the client shall be paid in the form of
                                    corresponding extra credits to the Wallet by the
                                    {clientBrandName} .

                                </li>

                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 className="heading">INDEMNITY</h3>
                            <ol type="1">
                                <li >

                                    "{clientBrandName} System" shall mean the its platform,
                                    interface, APIs, networks, software, security protocols, and
                                    such back-end systems operated by {clientBrandName} to record
                                    and process the Transactions initiated by client and forward
                                    such transactions to the {clientBrandName} .

                                </li>
                                <li >

                                    {clientBrandName} shall allow the client to integrate with
                                    {clientBrandName} System to process the Transactions and
                                    {clientBrandName} may provide necessary integration assistance
                                    on a cost basis as provided in this Schedule. The client shall
                                    solely bear the related costs and expenses involved in such
                                    integration and in the purchase and maintenance of its own
                                    front-end applications/servers/systems/kiosks, as the case may
                                    be.

                                </li>
                                <li >

                                    {clientBrandName}  shall give necessary instructions to the
                                    client to collect such data from its customers so as to send
                                    technically valid Transaction requests to  {clientBrandName}
                                    for processing.

                                </li>
                                <li >

                                    {clientBrandName}  shall inform the client, reasonably in
                                    advance, of any material changes (for ex., discontinuation of
                                    services, commission structure, etc.) with regard to any
                                    {clientBrandName} , payment collection arrangements, etc. that
                                    may impact the Services in respect of such {clientBrandName}
                                    's.

                                </li>
                                <li >

                                    {clientBrandName}  shall use its reasonable endeavours to avoid
                                    any breakdown/interruption or any technical flaw resulting in
                                    any delay in providing/suspension of access to the
                                    {clientBrandName}  System.

                                </li>
                                <li >

                                    {clientBrandName}  shall provide appropriate ‘customer support
                                    services to the client in respect of any customer queries
                                    provided only if the client has been unable to resolve such
                                    queries. The client shall be provided with designated points of
                                    contact in  {clientBrandName}  for the resolution of its
                                    queries.

                                </li>
                            </ol>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 className="heading">22: CLIENT OBLIGATION</h3>
                            <ol type="1">
                                <li >

                                    The client shall offer Services and process Transactions in good
                                    faith and upon the terms of this Agreement. It shall maintain
                                    proper records and financial details of Transactions so as to
                                    enable reconciliation and audit as deemed necessary by
                                    {clientBrandName} .

                                </li>
                                <li >

                                    The client shall coordinate with  {clientBrandName}  to perform
                                    the necessary integration at the backend to enable the
                                    Transactions.

                                </li>
                                <li >

                                    Customer Support. The client shall manage and administer its own
                                    'customer care' infrastructure at its own cost and shall address
                                    any queries from its customers. In order to provide 'customer
                                    care', the client may also rely on the {clientBrandName}
                                    system, to which it has access. In case the client is unable to
                                    resolve any customer queries, it may escalate such queries to
                                    {clientBrandName} through the {clientBrandName} System. It
                                    is clarified that the client shall not forward or refer
                                    customers directly to {clientBrandName} .

                                </li>
                            </ol>
                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3>22: REFUNDS</h3>

                            <ol type="1">
                                <li >

                                    The Client agrees and acknowledges that subject to availability
                                    of funds received in the Nodal Account pursuant to Transactions,
                                    the Client is entitled to effect Refunds.

                                </li>
                                <li >

                                    The Client further agrees and acknowledges that initiation of
                                    Refunds is at the discretion of the Client and
                                    {clientBrandName} shall process a Refund only upon initiation
                                    of the same on the Dashboard.

                                </li>
                                <li >

                                    All Refunds initiated by the Client shall be routed through the
                                    same Acquirer that processed the Transaction Amount related to
                                    the Refund.

                                </li>
                            </ol>

                        </div>


                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3>24: RESTRICTIONS ON USE OF SERVICES</h3>
                            The Client hereby acknowledges and agrees to undertake all necessary
                            measures to ensure that the transactions facilitated with the
                            Provider strictly comply with applicable laws and regulations. The
                            Client expressly confirms that said transactions shall not involve
                            the offer, sale, or purchase of the products and/or services
                            specifically enumerated herein, and further affirms that the Client
                            is neither engaged in nor associated with the industry related to
                            such products and/or services.
                            <ul>
                                <li >

                                    Adult goods and services which includes pornography and other
                                    sexually suggestive materials (including literature, imagery and
                                    other media); escort or prostitution services; Website access
                                    and/or Website memberships of pornography or illegal sites;

                                </li>
                                <li >

                                    Alcohol which includes alcohol or alcoholic beverages such as
                                    beer, liquor, wine, or champagne;

                                </li>
                                <li >

                                    Body parts which include organs or other body parts;

                                </li>
                                <li >

                                    Bulk marketing tools which include email lists, software, or
                                    other products enabling unsolicited email messages (spam);

                                </li>
                                <li >

                                    Cable descramblers and black boxes which includes devices
                                    intended to obtain cable and satellite signals for free;

                                </li>
                                <li >

                                    Child pornography which includes pornographic materials
                                    involving minors;

                                </li>
                                <li >

                                    Copyright unlocking devices which include Mod chips or other
                                    devices designed to circumvent copyright protection;

                                </li>
                                <li >

                                    Copyrighted media which includes unauthorized copies of books,
                                    music, movies, and other licensed or protected materials;

                                </li>
                                <li >

                                    Copyrighted software which includes unauthorized copies of
                                    software, video games and other licensed or protected materials,
                                    including OEM or bundled software;

                                </li>
                                <li >

                                    Counterfeit and unauthorized goods which includes replicas or
                                    imitations of designer goods; items without a celebrity
                                    endorsement that would normally require such an association;
                                    fake autographs, counterfeit stamps, and other potentially
                                    unauthorized goods;

                                </li>
                                <li >

                                    Drugs and drug paraphernalia which includes illegal drugs and
                                    drug accessories, including herbal drugs like salvia and magic
                                    mushrooms;

                                </li>

                            </ul>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ul>
                                <li >

                                    Drug test circumvention aids which include drug cleansing
                                    shakes, urine test additives, and related items;

                                </li>
                                <li >

                                    Endangered species which includes plants, animals or other
                                    organisms (including product derivatives) in danger of
                                    extinction;

                                </li>
                                <li >

                                    Gaming/gambling which includes lottery tickets, sports bets,
                                    memberships/ enrolment in online gambling sites, and related
                                    content;

                                </li>
                                <li >

                                    Government IDs or documents which includes fake IDs, passports,
                                    diplomas, and noble titles;

                                </li>
                                <li >

                                    Hacking and cracking materials which includes manuals, how-to
                                    guides, information, or equipment

                                </li>
                                <li >

                                    enabling illegal access to software, servers, website, or other
                                    protected property;

                                </li>
                                <li >

                                    Illegal goods which include materials, products, or information
                                    promoting illegal goods or enabling illegal acts;

                                </li>
                                <li >

                                    Miracle cures which include unsubstantiated cures, remedies or
                                    other items marketed as quick health fixes;

                                </li>
                                <li >

                                    Offensive goods which include literature, products or other
                                    materials that:

                                </li>
                                <li >

                                    Defame or slander any person or groups of people based on race,
                                    ethnicity, national origin, religion, sex, or other factors;

                                </li>
                                <li >
                                    Encourage or incite violent acts;
                                </li>
                                <li >
                                    Promote intolerance or hatred.
                                </li>
                                <li >

                                    Offensive goods, crime which includes crime scene photos or
                                    items, such as personal belongings, associated with criminals;

                                </li>
                                <li >

                                    Pyrotechnic devices, combustibles, corrosives and hazardous
                                    materials which includes explosives and related goods; toxic,
                                    flammable, and radioactive materials and substances;

                                </li>
                                <li >

                                    Regulated goods which include air bags; batteries containing
                                    mercury; Freon or similar substances/refrigerants;
                                    chemical/industrial solvents; government uniforms; car titles;
                                    license plates; police badges and law enforcement equipment;
                                    lock-picking devices; pesticides; postage meters; recalled
                                    items; slot machines; surveillance equipment; goods regulated by
                                    government or other agency specifications;

                                </li>
                                <li >

                                    Securities which include government bonds or related financial
                                    products;

                                </li>
                                <li >

                                    Tobacco and cigarettes which includes cigarettes, cigars,
                                    chewing tobacco, and related products;

                                </li>

                            </ul>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ul>
                                <li >

                                    Traffic devices which include radar detectors/jammers, license
                                    plate covers, traffic signal changers, and related products;

                                </li>
                                <li >

                                    Weapons which include firearms, ammunition, knives, brass
                                    knuckles, gun parts, and other armaments;

                                </li>
                                <li >

                                    Wholesale currency which includes discounted currencies or
                                    currency exchanges;

                                </li>
                                <li >

                                    Live animals or hides/skins/teeth, nails and other parts etc. of
                                    animals;

                                </li>
                                <li >
                                    Multi-Level Marketing collection fees;
                                </li>
                                <li >

                                    Matrix sites or sites using a matrix scheme approach;

                                </li>
                                <li >

                                    Work-at-home approach and/or Work-at-home information;

                                </li>
                                <li >
                                    Drop-shipped merchandise;
                                </li>
                                <li >

                                    Any product or service which is not in compliance with all
                                    applicable laws and regulations whether federal, state, local or
                                    international, including the laws of India;

                                </li>
                                <li >

                                    The Merchant providing services that have the potential of
                                    casting the Payment Gateway Facilitators in a poor light and/or
                                    that may be prone to Buy & Deny attitude of the cardholders when
                                    billed (e.g., adult material/ Mature content/Escort services/
                                    friend finders) and thus leading to chargeback and fraud losses;

                                </li>
                                <li >

                                    Businesses or website that operate within the scope of laws
                                    which are not absolutely clear or are ambiguous in nature (e.g.,
                                    Web-based telephony, Website supplying medicines or controlled
                                    substances, website that promise online match-making);

                                </li>
                                <li >

                                    Businesses out rightly banned by law e.g., Betting & Gambling/
                                    publications or content that is likely to be interpreted by the
                                    authorities as leading to moral turpitude or decadence or incite
                                    caste/communal tensions, lotteries/sweepstakes & games of
                                    chance;

                                </li>
                                <li >

                                    The Merchant who deals in intangible goods/ services (e.g.,
                                    Software download/ Health/ Beauty Products), and businesses
                                    involved in pyramid marketing schemes or get-rich-quick schemes.
                                    Any other product or Service, which in the sole opinion of
                                    either the Partner Bank or the Acquiring, is detrimental to the
                                    image and interests of either of them / both of them, as
                                    communicated by either of them/ both of them to the Merchant
                                    from time to time. This shall be without prejudice to any other
                                    terms & conditions mentioned in this Agreement;

                                </li>
                            </ul>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <ul>


                                <li >Mailing lists </li>
                                <li >

                                    Virtual currency, cryptocurrency and other crypto products (like
                                    non-fungible tokens or NFTs), prohibited investments for
                                    commercial gain or credits that can be monetized, re-sold or
                                    converted to physical or digital goods or services or otherwise
                                    exit the virtual world.

                                </li>
                                <li >
                                    Money laundering services.
                                </li>
                                <li >
                                    Database providers (for tele-callers).
                                </li>
                                <li >
                                    Bidding/Auction houses.
                                </li>
                                <li >

                                    Activities prohibited by the Telecom Regulatory Authority of
                                    India

                                </li>
                                <li >

                                    Any other activities prohibited by applicable law

                                </li>
                            </ul>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#c7c7c7 solid 2px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <h3 >25: TERMINATION</h3>
                            <ol type="1">




                                <li >


                                    In case of termination of Agreement, any credit balance in the
                                    Wallet shall, either be utilized by the client during the notice
                                    period or shall be refunded to the client upon Termination within 1
                                    month.

                                </li>
                            </ol>


                            <div style={{ fontFamily: "Arial, sans-serif", width: "95%", margin: "20px auto" }}>
                                {/* Signature Area */}
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        backgroundColor: "#f0f8ff",
                                        padding: "10px",
                                        border: "1px solid #ddd",
                                        borderRadius: "8px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <img
                                        src={signImage}// Replace with actual signature image URL
                                        alt="Signature"
                                        style={{ maxHeight: "40px", marginRight: "10px" }}
                                    />
                                    <span style={{ fontSize: "20px", fontWeight: "bold", color: "#444" }}>1</span>
                                </div>

                                {/* Employee and Company Details */}
                                <div style={{ marginBottom: "20px" }}>
                                    <p style={{ fontSize: "16px", margin: "5px 0" }}>
                                        {signBy}, {position}
                                    </p>
                                    <p style={{ fontSize: "16px", margin: "5px 0" }}>{company}</p>
                                </div>


                                <div id="aadharData"></div>




                                <p style={{ textalign: "center", fontsize: "26px" }} className="break_here">
                                    Audit Trail
                                </p>
                                <section>
                                    <table border="1" cellpadding="10" style={{ borderColor: "#ccc" }}>
                                        <tr>
                                            <td style={{ fontweight: "500", fontsize: "large" }}>Document Name</td>
                                            {/* <td>${documentName}</td> */}
                                            <td>documentName</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontweight: "500", fontsize: "large" }}>Document ID(s)</td>
                                            {/* <td>${documentId}</td> */}
                                            <td>$documentId</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontweight: "500", fontsize: "large" }}>Document Hash</td>
                                            {/* <td>${documentHash}</td> */}
                                            <td>documentHash</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontweight: "500", fontsize: "large" }}>Status</td>
                                            {/* <td>${documentStatus}</td> */}
                                            <td>documentStatus</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontweight: "500", fontsize: "large" }}>Date</td>
                                            {/* <td>${signingDate}</td> */}
                                            <td>signingDate</td>
                                        </tr>
                                    </table>
                                    <br />

                                </section>


                                <div>

                                </div>


                            </div>
                        </div>

                        <div style={{ width: "595px", height: "842px", border: "#ccc solid 3px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                            <p>
                                This document has been signed through neoesign.com
                            </p>
                            <table border="1" cellpadding="10" style={{ borderColor: "#ccc" }}>
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Date</th>
                                        <th>Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>${clientBrandName} ithead@ ${clientBrandName} .co.in</td>
                                        <td>Tue Mar 26 2024: 7:10:48 PM</td>
                                        <td>
                                            <p><strong>Activity Type : </strong> Request Generated</p>
                                            <p><strong>Notifications: </strong> e.g...</p>
                                            <p><strong>Email: </strong>{clientBusinessEmail}</p>
                                            <p><strong>IP :</strong> 43.205.33.237</p>
                                            <p><strong>Co-ordinates :</strong>{latitude},{longitude}</p>
                                            <p><strong>Real Time Image Captured</strong></p>
                                            <img
                                                style={{ width: "200px" }}
                                                src="data:image/*;base64,${}"
                                                alt="dummy-image"
                                            />

                                            <p>
                                                <strong>OS :</strong> server
                                                <strong> Browser:</strong>  NA <strong>Device: </strong> other
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style={{ fontFamily:"Times New Roman", fontweight:"normal", width: "595px", height: "842px", border: "#ccc solid 3px", margin: "auto", marginTop: "20px", padding: "20px" }}>

                            <div style={headerStyle}>
                                <h2>Audit Report of Service Agreement</h2>
                                <p>
                                    <strong>Agreement Type:</strong> Service Agreement <br />
                                    <strong>Agreement ID:</strong> 12335678909876567 <br />
                                    <strong>Agreement Date:</strong> 25th September 2024
                                </p>
                            </div>

                            

                            <table border="1" cellpadding="10" style={{ borderColor: "#ccc", fontFamily:"Times New Roman" }}>


                                            <thead>
                                                    <tr>
                                                        <th style={thTdStyle}>&nbsp;</th>
                                                        <th style={thTdStyle}>First Party Details</th>
                                                        <th style={thTdStyle}>Second Party Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td><strong>Entity Name:</strong> </td>
                                                    <td>  {company}  </td>
                                                    <td>   {company}  </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Signatory Name:</strong> </td>
                                                    <td>  {signBy}  </td>
                                                    <td>    {signBy} </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Email ID:</strong> </td>
                                                    <td>   ankur.gupta@tramo.in   </td>
                                                    <td>    ankur.gupta@tramo.in  </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Document Opened on:</strong> </td>
                                                    <td>    {pageOpenTime}   </td>
                                                    <td>     {pageOpenTime} </td>
                                                </tr>

                                                
                                                <tr>
                                                    <td><strong>Document Signed on:</strong> </td>
                                                    <td>    {dayOfAgreement}.{monthName}.{yearOfAgreement}  {time}    </td>
                                                    <td>      {dayOfAgreement}.{monthName}.{yearOfAgreement}  {time}  </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Latitude:</strong> </td>
                                                    <td>     {latitude}    </td>
                                                    <td>        {latitude} </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Longitude:</strong> </td>
                                                    <td>     {longitude}     </td>
                                                    <td>      {longitude}  </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Operating System & Browser:</strong> </td>
                                                    <td>     {operatingSystem},{browser}    </td>
                                                    <td>       {operatingSystem},{browser}  </td>
                                                </tr>

                                               

                                               
                                                </tbody>
                                            </table>
                                            
                                             
                                             
                                       

                            
                        </div>



                        <div style={{ width: "595px", height: "842px", border: "#ccc solid 3px", margin: "auto", marginTop: "20px", padding: "20px" }}>
                                
                        <table border="1" cellpadding="10" style={{ borderColor: "#ccc",  fontFamily:"Times New Roman" }}>

                        <tr>
                                                    <td><strong>IP Address:</strong> </td>
                                                    <td>      {ip}     </td>
                                                    <td>      {ip}  </td>
                                                </tr>
                                <tr>
                                                    <td><strong>Device Type:</strong> </td>
                                                    <td>     {operatingSystem}     </td>
                                                    <td>      {operatingSystem}  </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Sign Type:</strong> </td>
                                                    <td>     Digital  </td>
                                                    <td>     Digital </td>
                                                </tr>

                                                <tr>
                                                    <td><strong>Photo captured:</strong> </td>
                                                    <td>    <img
                                                src={image}
                                                alt="First Party"
                                                style={photoStyle}
                                            />   </td>
                                                    <td>     
                                                        <div id="secondPartyPhoto"></div>
                                                    </td>
                                                </tr>
                                </table>
                        <div  style={{paddingTop:"16px"}}>
                                <strong>Consent and Acknowledgment</strong>
                                <p>
                                    Both parties have acknowledged that they are signing this agreement
                                    with full awareness, understanding, and consciousness of the terms
                                    and conditions. The signing process involved digital capture of the
                                    following:
                                </p>

                                <p><strong>Signature:</strong>
                                    Each party provided their signature digitally by either uploading their signature or drawing it on the screen.
                                </p>
                                <p><strong>Location:</strong>
                                    The latitude and longitude of the signing location were recorded to ensure the authenticity of the signing event.
                                </p>
                               
                            </div>

                                 <p><strong>IP Address and Browser Details:</strong>
                                    The IP address and the browser used during the signing process were captured to ensure a clear record of the digital environment in which the document was signed.
                                </p>
                                <p><strong>Live Photo:</strong>:
                                    A live photo of each signing party was captured and stored as part of the verification. process.
                                </p> <p>
                                    By proceeding with the signing of this agreement, both parties' consent to the collection of this information for audit purposes and confirm that they are not under any duress or undue influence.
                                </p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ESignAgreement