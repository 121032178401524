import React, { useState, useEffect } from "react";
import global from "../../assets/scss/global.module.scss";
import ProviderDetails from "./ProviderDetails";
import AddGstDetails from "./AddGstDetails";
import AddBankDetails from "./AddBankDetails";
import AddManagerDetails from "./AddManagerDetails";
import UploadDocument from "./UploadDocument";
import AddServices from "./AddServices";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import formmodule from "../../assets/scss/form.module.scss";
import AddIcon from "@mui/icons-material/Add";
import Loader from '../../utils/Loader';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddDepartmentInformation from "./AddDepartmentInformation";




const AddProvider = () => {
  const [userData, setProviderDetail] = useState(null);
  const [loading, setLoading] = useState(false);

// const fetchProviderDetail = async () => {
//   try {
//     setLoading(true)
//     const data = await fetcher.get(ApiUrls.REGISTER_DETAIL);
//     if (data?.status === 200) {
//       setProviderDetail(data.response);
//     }else{

//     }
//   } catch (error) {
//     console.error("Error fetching user data:", error);
//   }
//   setLoading(false)
// };
const getProviderData = (userData) => {
  setProviderDetail(userData);
};

useEffect(() => {
  //fetchProviderDetail();
  
}, []);
  return (
    <>
      <div className={global.wrapper}>
      {loading && <Loader />}
        <div className="container-fluid">
          <div  classNameclassName={global.container_inner}>
          <div
          className={`${global.white_bg} ${global.white_bg} ${global.pad_16}  `}>
            <h2>Add Provider</h2>
            </div>

            <ProviderDetails listener={getProviderData} />

            {userData  && ( 
              <>
              <AddGstDetails userData={userData} />

              <AddBankDetails userData={userData} />
  
               {/* <AddManagerDetails userData={userData} />  */}
  
              <AddDepartmentInformation userData={userData} /> 
  
              <UploadDocument userData={userData} />
  
              <AddServices userData={userData} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProvider;
