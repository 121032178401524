import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import fetcher from '../../utils/fetcher';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isAuthenticated } from '../../utils/authGuard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { AUTHORITY, PageUrls } from "../../utils/constants";
import SnackBar from '../shared/SnackBar';
import { userAuthority } from '../../utils/authGuard';
import { Container } from 'react-bootstrap';
import logoLogo from '../../assets/images/tramo-logo.svg';
import logoLogoTramo from '../../assets/images/logo-tramo-login.svg';
import login from './Login.module.scss';
import global from '../../assets/scss/global.module.scss';


const Login = () => {
  const navigate = useNavigate();
  const isAuthentic = isAuthenticated();
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);  // new state for OTP input
  const [otp, setOtp] = useState('');  // new state for OTP value

  const [snackbar, setSnackbar] = useState({
    show: false,
    status: "",
    message: "",
  });
  const toggleSnackbar = (value) => {
    setSnackbar(value);
  };

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
  });

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (values) => {
    console.log('submitting');
    try {
      const res = await fetcher.post('/authenticate', values);
      setSnackbar({
        show: true,
        status: res?.status === 200 ? 'success' : 'error',
        message: res?.status === 200 ? 'Login successfully' : res?.message
      });
      if (res?.status === 200) {
        localStorage.setItem('auth', JSON.stringify(res.response));
        navigateTo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const res = await fetcher.post('/user-api/verify-otp', {
        email: formik.values.email,
        otp: otp
      });
      setSnackbar({
        show: true,
        status: res?.status === 200 ? 'success' : 'error',
        message: res?.status === 200 ? 'Login successfully' : res?.message
      });
      if (res?.status === 200) {
        localStorage.setItem('auth', JSON.stringify(res.response));
        navigateTo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const navigateTo = () => {
    navigate(PageUrls.DASHBOARD);
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isAuthentic) {
      navigateTo();
    }
  }, []);

  return (
    <>
      <SnackBar  {...snackbar} onClose={toggleSnackbar} />
      <div className={login.background_color}>

        <div className="container">
          <div className='row'>




            <div className='col-sm-6'>

              <div className={`${global.text_left} ${global.mar_top_28}`}><img src={logoLogo} /></div>

              <div className={global.text_center} style={{ marginTop: '100px' }}>
                <img src={logoLogoTramo} />
              </div>

              <div className={login.login_left_space}>
                {/* <h1>Sign In</h1> */}
                {!showOtpInput ? (
                  <>
                    <div className='form-group'>
                      <label className={login.label_control}>Email ID<span className={login.red}>*</span></label>
                      <input placeholder='Enter email id'
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        type="text"
                        className={global.form_control}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className={login.errorMsg}>{formik.errors.email}</div>
                      )}
                    </div>
                    <div className='form-group'>
                      <label className={login.label_control}>Password<span className={login.red}>*</span></label>
                      <div className={login.passwrordField}>
                        <input placeholder='Enter password'
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          type={showPassword ? 'text' : 'password'}
                          className={global.form_control}
                        />
                        <Button
                          type="button"
                          variant="text"
                          className={login.password_icon}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </Button>
                      </div>
                      {formik.touched.password && formik.errors.password && (
                        <div className={login.errorMsg}>{formik.errors.password}</div>
                      )}
                    </div>
                    <button type="submit" className={login.submit_button} onClick={formik.handleSubmit}>Sign In</button>
                    <div className={login.forget_password}>
                      <a href="#">Forgot password ?</a>
                    </div>
                  </>
                ) : (
                  <div className='form-group'>
                    <label className='label-control'>Enter OTP<span>*</span></label>
                    <input
                      name="otp"
                      onChange={handleOtpChange}
                      value={otp}
                      type="text"
                      className="form-control"
                    />
                    <button type="button" className='btn btn-primary mb-3' onClick={handleOtpSubmit}>Verify OTP</button>
                  </div>
                )}
              </div>
            </div>

            <div className='col-sm-6' style={{ backgroundColor: '#f0f4ff' }}>
              <div className={login.login_rhs}>
                <p className={login.rhs_bottom_text}>
                  Transforming the way under banked communities manage money with intelligent banking.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>


    </>
  );
}

export default Login;
