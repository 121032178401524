import React, { useEffect, useState } from "react";
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  FormControl,
  InputLabel,
  Switch,
} from "@mui/material";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import global from "../../assets/scss/global.module.scss";
import Loader from "../../utils/Loader";
import { Button } from "react-bootstrap";
import StatusConfirmationModal from "./StatusConfirmationModal";
import ResetPasswordModal from './ResetPassword'
import { useNavigate } from "react-router-dom";
import ApiLeadDetail from './detail/ApiUserDetail';

const ApiUsers = () => {
  const [filteredLeads, setFilteredLeads] = useState([]); // Store filtered leads if any filter is applied
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Display 7 items per page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLeads = (filteredLeads || []).slice(indexOfFirstItem, indexOfLastItem);

  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    filterType: "entityName", // Default filter type
    filterValue: "",
    status: "",
    startDate: "",
    endDate: "",
  });

  const [statuses] = useState(["ACTIVE", "INACTIVE"]); // Sample status list
  const [modalVisible, setModalVisible] = useState(false);
  const [ResetModalVisible, setResetModalVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const filterTypes = [
    { label: "Entity Name", value: "entityName" },
    { label: "Auth. Person Name", value: "authPersonName" },
    { label: "Lead ID", value: "leadId" },
    { label: "Email Id", value: "emailId" },
    { label: "Mobile Number", value: "mobileNumber" },
  ];
  const [SelectedApiLeadId, setSelectedApiLeadId] = useState("");
  const [ApiLeadClicked, setApiLeadClicked] = useState(false) 

   const navigate = useNavigate();
   const totalPages = Math.ceil((filteredLeads?.length || 0) / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchLeads(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      fetchLeads(prevPage);
    }
  };
  const handleConfirmAction = () => {
    // Perform API call or update user status logic
    console.log(`${actionType} user:`, selectedUser);
    setModalVisible(false);
    setSelectedUser(null);
  };
  const handleCancelAction = () => {
    setModalVisible(false);
    setSelectedUser(null);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchLeads(pageNumber);
  };

  const openApiDetail = (leadId) => {
    setSelectedApiLeadId(leadId);
   
    setApiLeadClicked(true);
    
  };


  const handleResetOpenModal = () => {
    setResetModalVisible(true);
  };

  const handleResetCloseModal = () => {
    setResetModalVisible(false);
  };

  useEffect(() => {
    fetchLeads(1);
  }, []);

  const fetchLeads = async (page) => {
    try {
      setLoading(true);
      const data = await fetcher.get(`${ApiUrls.FETCH_API_USER}?page=${page - 1}`); // Page as 0-based index

      if (data?.status === 200) {
        setLeads(data.response.users);
        setFilteredLeads(data.response.users); // Initialize filteredLeads with all leads
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const clearFilters = () => {
    setFilters({
      filterType: "entityName",
      filterValue: "",
      status: "",
      startDate: "",
      endDate: "",
    });
    setFilteredLeads(leads); // Reset to full leads list
  };

  const searchFilters = () => {
    const { filterType, filterValue, status, startDate, endDate } = filters;

    const filtered = leads.filter((lead) => {
      const isMatchType =
        !filterValue ||
        lead[filterType]
          ?.toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      const isMatchStatus = !status || lead.status === status;
      const isMatchStartDate =
        !startDate || new Date(lead.createdAt * 1000) >= new Date(startDate);
      const isMatchEndDate =
        !endDate || new Date(lead.createdAt * 1000) <= new Date(endDate);

      return isMatchType && isMatchStatus && isMatchStartDate && isMatchEndDate;
    });

    setFilteredLeads(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  };

  const downloadData = () => {
    alert("Download functionality not implemented");
  };
  const handleToggleStatus = (user) => {
    setSelectedUser(user);
    setActionType(user.status === "ACTIVE" ? "Deactivate" : "Activate");
    setModalVisible(true);
  };
  return (
    <div className={`${global.gray_bg} ${global.pad_16} `}>
      {loading && <Loader />}
      <div class="container-fluid">
        <div
          className={`${global.white_bg} ${global.white_bg} ${global.pad_16}  `}
        >

          
          <Typography variant="h1" gutterBottom>
            Api User List
          </Typography>
        </div>
        {!ApiLeadClicked && (
          <>
        <div className="filter-bar">
          <div
            className={`${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16}  ${global.pad_16}  `}
          >
            <FormControl
              variant="outlined"
              style={{ width: "120px", marginRight: "8px" }}
            >
              <InputLabel>Select an option</InputLabel>

              <Select
                value={filters.filterType}
                onChange={handleChange}
                name="filterType"
                label="Select an option"
              >
                {filterTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              name="filterValue"
              label={`Enter ${
                filters.filterType === "entityName"
                  ? "Entity Name"
                  : filters.filterType
              }`}
              value={filters.filterValue}
              onChange={handleChange}

               style={{marginRight:"5px"}}
            />

            <FormControl
              variant="outlined"
              style={{ width: "120px", marginRight: "8px" }}
            >
              <InputLabel>Select an option</InputLabel>
              <Select
                value={filters.status}
                onChange={handleChange}
                name="status"
                label="Select an Status"
              >
                {statuses.map((type) => (
                  <MenuItem key={type.status} value={type.status}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              type="date"
              name="startDate"
              label="Start Date"
              InputLabelProps={{ shrink: true }}
              value={filters.startDate}
              onChange={handleChange}
              style={{marginRight:"5px"}}
            />

            <TextField
              variant="outlined"
              type="date"
              name="endDate"
              label="Start Date"
              InputLabelProps={{ shrink: true }}
              value={filters.endDate}
              onChange={handleChange}
            />

            <button
              className={`${global.clear_btn} ${global.lead_common_btn} `}
              onClick={clearFilters}
            >
              Clear
            </button>
            <button
              className={`${global.submit_btn_1} ${global.lead_common_btn} `}
              onClick={searchFilters}
            >
              {" "}
              Search <i className={global.lead_search_icon}></i>
            </button>
            <button
              className={`${global.download_btn_1} ${global.lead_common_btn} `}
              onClick={downloadData}
            >
              Download <i className={global.lead_download_icon}></i>
            </button>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#EAF6FF" }}>
              <TableRow>
                <TableCell>Onboarding Date & Time</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Entity Name / Auth. Person Name</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Wallet Balance</TableCell>
                <TableCell>Settlement Wallet Balance</TableCell>
                <TableCell>Associated Scheme</TableCell>
                <TableCell>Loan Ammount</TableCell>
                <TableCell>Overdraft Limit</TableCell>
                <TableCell>Queued Limit</TableCell>
                <TableCell>Password Reset</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={global.gray_lead_row}>
              {currentLeads.map((lead) => (
                                <TableRow key={lead.leadUserId} onClick={() => openApiDetail(lead.leadUserId)} style={{ cursor: 'pointer' }}>
                  {/* Onboarding Date & Time */}
                  <TableCell>
                    {lead.createdOn
                      ? new Date(lead.createdOn * 1000).toLocaleString()
                      : "N/A"}
                  </TableCell>

                  {/* User Type */}
                  <TableCell>{lead.userType || "API User"}</TableCell>

                  {/* Entity Name / Auth. Person Name */}
                  <TableCell>
                    {`${lead.userName || "An"} / ${
                      lead.authorizedPersonName || "N/A"
                    }`}
                  </TableCell>

                  {/* User ID */}
                  <TableCell>{lead.leadUserId || "N/A"}</TableCell>

                  {/* Wallet Balance */}
                  <TableCell>
                    <div>
                      {lead.userWallet?.walletLedgerBalance !== undefined
                        ? `₹${lead.userWallet.walletLedgerBalance}`
                        : "L 0000"}
                    </div>
                    <div>
                      {lead.userWallet?.walletAvailableBalance !== undefined
                        ? `₹${lead.userWallet.walletAvailableBalance}`
                        : "A 0000"}
                    </div>
                  </TableCell>

                  {/* Settlement Wallet Balance */}
                  <TableCell>
                    <div>
                      {lead.userWallet?.settlementLedgerBalance !== undefined
                        ? `₹${lead.userWallet.settlementLedgerBalance}`
                        : "L 906543"}
                    </div>
                    <div>
                      {lead.userWallet?.settlementAvailableBalance !== undefined
                        ? `₹${lead.userWallet.settlementAvailableBalance}`
                        : "A 9120"}
                    </div>
                  </TableCell>

                  <TableCell>
                  {/* Associated Scheme */}
                  <Button className={global.outlined_btn}>View</Button>
                  </TableCell>

                  {/* Loan Amount */}
                  <TableCell>
                    {lead.loanAmount !== undefined
                      ? `₹${lead.loanAmount}`
                      : "$000"}
                  </TableCell>

                  {/* Overdraft Limit */}
                  <TableCell>
                    <div>
                      {lead.userWallet?.overdraftLimit !== undefined
                        ? `₹${lead.userWallet.overdraftLimit}`
                        : "L 00000"}
                    </div>
                    <div>
                      {lead.userWallet?.overdraftLimit !== undefined
                        ? `₹${lead.userWallet.overdraftLimit}`
                        : "A 00000"}
                    </div>
                  </TableCell>

                  {/* Queued Limit */}
                  <TableCell>
                    <div>
                      {lead.userWallet?.queuedLimit !== undefined
                        ? `₹${lead.userWallet.queuedLimit}`
                        : "L 092192910"}
                    </div>
                    <div>
                      {lead.userWallet?.queuedLimit !== undefined
                        ? `₹${lead.userWallet.queuedLimit}`
                        : "A 092192910"}
                    </div>
                  </TableCell>

                  {/* Password Reset */}
                  <TableCell>
                  <Button
          onClick={(e) => {
            e.stopPropagation(); // Prevent event from bubbling up to TableRow
            handleResetOpenModal();
          }}
        >
          Reset Password
        </Button>
                  </TableCell>

                  {/* Status */}
                  <TableCell>
                    <Switch
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event from bubbling up to TableRow
                        handleToggleStatus(lead);
                      }}
                      className={`${global.toggle_btn} ${
                        lead.isActive === 1
                          ? global.active
                          : global.inactive
                      }`}
                    >
                      {lead.isActive === 1 ? "ACTIVE" : "INACTIVE"}
                    </Switch>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <StatusConfirmationModal
            show={modalVisible}
            actionType={actionType}
            onConfirm={handleConfirmAction}
            onCancel={handleCancelAction}
          />
           <ResetPasswordModal 
        isVisible={ResetModalVisible} 
        onClose={handleResetCloseModal} 
      />
        </TableContainer>

        {/* Pagination Controls aligned to the right */}
        <div
          className={`${global.pagination} ${global.pagination_1} `}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            onClick={handlePrevPage}
            className={global.prev}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageClick(index + 1)}
              // className={currentPage === index + 1 ? "page_num active" : "page_num"}
              className={`${global.page_num} ${
                currentPage === index + 1 ? global.active : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            className={global.next}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      

        </> )}

        {ApiLeadClicked && <ApiLeadDetail leadId={SelectedApiLeadId} />}
      </div>
    </div>
  );
};

export default ApiUsers;
