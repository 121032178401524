import React, { useState } from 'react';
import { Typography, TextField, Button, Modal, Box } from '@mui/material';

const ResetPasswordModal = ({ isVisible, onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [failureModalVisible, setFailureModalVisible] = useState(false);

  const handlePasswordChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleSubmit = () => {
    if (!newPassword || !confirmPassword) {
      setError('Both fields are required.');
      setFailureModalVisible(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      setFailureModalVisible(true);
      return;
    }

    // Add API call logic here if needed
    setError('');
    setSuccessModalVisible(true);
  };

  const handleCloseModals = () => {
    setSuccessModalVisible(false);
    setFailureModalVisible(false);
    onClose();
  };

  return (
    <Modal open={isVisible} onClose={onClose}>
      <Box
        sx={{
          width: 400,
          backgroundColor: 'white',
          margin: 'auto',
          marginTop: '10%',
          padding: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Reset Password
        </Typography>
        <TextField
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={handlePasswordChange(setNewPassword)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={handlePasswordChange(setConfirmPassword)}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>

        {/* Success Modal */}
        <Modal open={successModalVisible} onClose={handleCloseModals}>
          <Box
            sx={{
              width: 300,
              backgroundColor: 'white',
              margin: 'auto',
              marginTop: '15%',
              padding: 4,
              borderRadius: 2,
              textAlign: 'center',
              boxShadow: 24,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Password Reset Successful!
            </Typography>
            <Button variant="contained" color="primary" onClick={handleCloseModals}>
              OK
            </Button>
          </Box>
        </Modal>

        {/* Failure Modal */}
        <Modal open={failureModalVisible} onClose={handleCloseModals}>
          <Box
            sx={{
              width: 300,
              backgroundColor: 'white',
              margin: 'auto',
              marginTop: '15%',
              padding: 4,
              borderRadius: 2,
              textAlign: 'center',
              boxShadow: 24,
            }}
          >
            <Typography variant="h6" gutterBottom color="error">
              {error}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleCloseModals}>
              OK
            </Button>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
