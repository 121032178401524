import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const StatusConfirmationModal = ({ show, actionType, onConfirm, onCancel }) => {
  return (
    <Modal show={show} onHide={onCancel} style={{
      position: 'absolute',
      top: '20%',
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: '8px',
    
    }}>
      <Modal.Header closeButton>
        <Modal.Title>{`Are you sure you want to ${actionType}?`}</Modal.Title>
      </Modal.Header>
     
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusConfirmationModal;
