import React, { useState, useEffect } from "react";
import fetcher from "../../utils/fetcher";
import Loader from "../../utils/Loader"
import {
  Modal,
  Box,
  Typography,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch
} from "@mui/material";
import { ApiUrls } from "../../utils/constants";
import global from '../../assets/scss/global.module.scss'
import { useNavigate } from 'react-router-dom';

const EditScheme = ({ isOpen, onClose, schemeId }) => {
  const [loading, setLoading] = useState(false);
  const [schemeName, setSchemeName] = useState("");
  const [schemeDescription, setSchemeDescription] = useState("");
  const [payloadService, setPayloadService] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState(null); 
  const [selectedSubcatId, setSelectedSubcatId] = useState(null);
  const [categoryKeys, setCategoryKeys] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errors, setErrors] = useState({ schemeName: false, schemeDescription: false });
  
  
  const navigate = useNavigate();
  // Fetch Scheme Data
  const fetchSchemeById = async (schemeId) => {
    setLoading(true);
    try {
      const response = await fetcher.get(
        ApiUrls.FETCH_SCHEMES_BYIDS,
        { schemeId }
      );
      if (response?.status === 200) {
        const { name, desc, cat, subcat, data } = response.response;

        setSchemeName(name);
        setSchemeDescription(desc);
          setSelectedCatId(cat);
          setSelectedSubcatId(subcat);
          const keys = [];
          data.forEach((service) => {
            service.rows.forEach((row) => {
              row.commdata.forEach((comm) => {
                const formattedKey = comm.key.toLowerCase().replace(/\s+/g, "");
                if (!keys.includes(formattedKey)) {
                  keys.push(formattedKey);
                }
              });
            });
          });
          const updatedServices = data.map((service) => ({
            ...service,
            isChecked: true,
          }));
          setCategoryKeys(keys)
          setPayloadService({ data: updatedServices });
      } else {
        console.error("Failed to fetch scheme:", response.message);
      }
    } catch (error) {
      console.error("Error fetching scheme:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (isOpen) {
      fetchSchemeById(schemeId);
    }
  }, [isOpen, schemeId]);
  const handleToggle = () => {
    setToggled(!toggled);
  };

 
  const handleCheckboxChange = (serviceId) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId) {
        return {
          ...service,
          isChecked: !service.isChecked, // Toggle isChecked
        };
      }
      return service; // Return unchanged service for others
    });

    setPayloadService({ data: updatedServices });
  };

  const handleRowChange = (e, serviceId, field, cell,rowIndex) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId && service.isChecked === true) {
        // Update rows correctly
        const updatedRows = service.rows.map((row,index) => {
          if(rowIndex === index){
            const updatedCommdata = row.commdata.map((commdata) =>
              commdata.key === cell
                ? { ...commdata, [field]: e.target.value }
                : commdata
            );
            return { ...row, commdata: updatedCommdata }; // Update the commdata in the row
          }else{
            return {...row}
          }
          
        });

        return {
          ...service,
          rows: updatedRows, // Replace the rows with updated rows
        };
      }
      return service; // Return unchanged service for others
    });

    setPayloadService({ data: updatedServices });
  };
  const handleRowMinMaxChange = (e, serviceId, field,rowIndex) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId && service.isChecked === true) {
        const updatedRows = service.rows.map((row,index) => {
          if(rowIndex === index){
            return { ...row, [field]: e.target.value };
          }else{
            return { ...row}
          }
        });

        return {
          ...service,
          rows: updatedRows,
        };
     
      }
      return service;
    });

    setPayloadService({ data: updatedServices });
  };

  

  const handleSave = async () => {
    const newErrors = {
      schemeName: !schemeName,
      schemeDescription: !schemeDescription,
    };
    setErrors(newErrors);

    if (newErrors.schemeName || newErrors.schemeDescription) {
      return;
    }
    const data = payloadService.data.filter(
      (service) => service.isChecked === true
    );
    const payload = {
      name: schemeName,
      desc: schemeDescription,
      schedeleAt: 1731235282,
      cat: selectedCatId,
      subcat: selectedSubcatId,
      schemeType: 1,
      id: schemeId,
      data,
    };

    console.log("Final Payload:", payload);

    const verifyRes = await fetcher.post(ApiUrls.SAVE_SCHEME, payload);
    if (verifyRes?.status === 200 && verifyRes.response.status === 200) {
      console.log("Final Payload SAVED", payload);
      setSuccessModal(true);
    } else {
      console.log("Final Payload Failed", payload);
      setSuccessModal(true);
    }
  };




  const DynamicTable = ({ data }) => {
    return (
      <div style={{marginTop:"24px"}}>
      <table style={{ width: "2000px", borderCollapse: "collapse", border: "1px solid #D6D9E1" }}>
        <thead>
          <tr>
          <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}></th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>ID</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Operator Name</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Service Name</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Min Default</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Max Default</th>
            {data[0]?.rows[0]?.commdata.map((comm, index) => (
              <th
                key={`group-header-${index}`}
                colSpan={comm.key === "apiuser" || comm.key === "agent" ? 4 : 2}
                style={{ border: "1px solid #D6D9E1", textAlign: "center", padding: "10px", backgroundColor: "#F9F7C8" }}
              >
                {comm.key.toUpperCase()}
              </th>
            ))}
          </tr>
          <tr>
            {data[0]?.rows[0]?.commdata.map((item, index) => (
              item.key === "apiuser" || item.key === "agent" ? (
                <>
                  <th key={`sub-header-charge-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px" , backgroundColor: "#EAF6FF" }}>Charge</th>
                  <th key={`sub-header-tds-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>TDS</th>
                  <th key={`sub-header-type-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Type</th>
                  <th key={`sub-header-value-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Value</th>
                </>
              ) : (
                <>
                  <th key={`sub-header-type-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Type</th>
                  <th key={`sub-header-value-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Value</th>
                </>
              )
            ))}
          </tr>
        </thead>
        <tbody>

    
          {data.map((service,dataIndex) =>
            service.rows.map((row, rowIndex) => (
              <tr key={`${service.serviceid}-${rowIndex}`}>
                {rowIndex === 0 ? (
                  <>
                   <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      <Checkbox      checked={service.isChecked}
                       onChange={() => handleCheckboxChange(service.serviceid)}/>
                    </td>
                    <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      {service.serviceid}
                    </td>
                    <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                    {service.operatorName}
                    </td>
                    <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                    

                    <Box> <img src={service.operatorURL}  alt={`${service.operatorName} Logo`}  style={{ maxWidth: "35px",  maxHeight: "35px",  marginBottom: "8px",  }}/>
                          <Typography variant="body2"> {service.serviceName} </Typography>
                    </Box>  
                    </td>
                  </>
                ) : null}
                <td style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                <TextField  key={`minslab-${service.serviceid}-${rowIndex}`} style={{width:"100px;"}} type="text" value={row.minslab} onChange={(e) =>  handleRowMinMaxChange(e, service.serviceid, "minslab",rowIndex)  }/>
                </td>
                <td  className={global.plus_container} style={{ border: "1px solid #D6D9E1", padding: "10px", position:"relative" }}>
                 
                  <TextField key={`maxslab-${service.serviceid}-${rowIndex}`} className={global.form_control} type="text"  value= {row.maxslab}  onChange={(e) =>  handleRowMinMaxChange(e, service.serviceid, "maxslab",rowIndex)  }  />
                 
                  {rowIndex === service.rows.length - 1 && (<span style={{position:"absolute", right:"-30px", top:"35px", width:"60px"}}>
                   <button onClick={() => removeRow(service.serviceid, rowIndex)} style={{ marginRight: "10px", width:"20px", height:"20px", lineHeight:"16px", background:"#ccc", color:"#000", marginBottom:"5px"}}> - </button><br/>
                   <button onClick={() => addRow(service.serviceid)} style={{  width:"20px", height:"20px", lineHeight:"16px", background:"#ccc", color:"#000"}}>+</button>
                </span>)}
                </td>
                {row.commdata.map((comm, commIndex) => (
                 comm.key === "apiuser" || comm.key === "agent" ? ( <>
                    <td key={`charge-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      
                      <TableCell>
                             
                                  <select key={`charge-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`}
                                          value={comm.charge || ""} id="charge" className={global.form_control}  onChange={(e) =>
                                      handleRowChange(  e,  service.serviceid,  "charge", comm.key ,rowIndex )
                                    }  >
                                    <option value="1">  Charge  </option>
                                    <option value="2">  Commission  </option>
                                  </select>
                                </TableCell>
                    </td>
                    <td key={`tds-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
              
                      <TableCell>
                                  <div> <select  key={`tds-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`}
                                              value={comm.tds || ""}     className={global.form_control}  id="tds"  onChange={(e) =>
                                    handleRowChange(  e,  service.serviceid,  "tds",  comm.key ,rowIndex )  }  >
                                      <option value="1">  Yes  </option>
                                      <option value="2">  No  </option>
                                    </select>
                                  </div>
                                </TableCell>
                    </td>
                    <td key={`type-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      
                      <TableCell>
                                  {" "}
                                  <select key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} 
                                  value={comm.type || ""} className={global.form_control}  id="type"  onChange={(e) =>
                                      handleRowChange(  e,  service.serviceid,  "type",  comm.key  )  }  >
                                    <option value="1">₹</option>
                                    <option value="2">%</option>
                                  </select>
                                </TableCell>
                    </td>
                    <td key={`value-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                   
                      <TableCell>
                                  <TextField  key={`value-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} id="value"  type="text" 
                                  value={comm.value || ""} onChange={(e) =>
                                      handleRowChange(  e,  service.serviceid,  "value", comm.key ,rowIndex )  }  />
                                </TableCell>
                    </td>
                  </>
                  ) : (
                    <>
                      <td key={`charge-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                     
                      <TableCell>
                                  {" "}
                                  <select key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} className={global.form_control}  id="type" 
                                  value={comm.type || ""} onChange={(e) =>
                                      handleRowChange(  e,  service.serviceid,  "type",  comm.key ,rowIndex )  }  >
                                    <option value="1">  Yes  </option>
                                    <option value="2">  No  </option>
                                  </select>
                                </TableCell>
                    </td>
                    <td key={`value-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                 
                      <TableCell>
                                  <TextField key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} id="value"  type="text"  
                                  value={comm.value || ""}  onChange={(e) =>  
                                  handleRowChange(  e,  service.serviceid,   "value",  comm.key ,rowIndex )  }  />
                                </TableCell>
                    </td>
                    </>
                  )
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      </div>
    );
  };


  const addRow = (serviceId) => {
    setPayloadService((prevPayload) => {
      // const categoryKeys = sectionsCsv.split(",").map((category) =>
      //   category.toLowerCase().replace(/\s+/g, "") // Convert to lowercase and remove spaces for keys
      // );
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: [...item.rows,
                  {
                    minslab: "0",
                    maxslab: "10",
                    commdata: categoryKeys.map((key) => {
                      if (key === "apiuser" || key === "agent") {
                        return {
                          key: key,
                          charge: "1", 
                          tds: "1",    
                          type: "1",   
                          value: "0", 
                        };
                      } else {
                        return {
                          key: key,
                          type: "1",   
                          value: "0",  
                        };
                      }
                    }),
                  },
                ],
              }
            : item
        ),
      };
    });
  };

  const removeRow = (serviceId, rowIndex) => {
    setPayloadService((prevPayload) => {
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: item.rows.filter((_, index) => index !== rowIndex), // Remove the specific row by index
              }
            : item
        ),
      };
    });
  };  

  return (
    <Modal open={isOpen} onClose={onClose} fullScreen>
      <Box
        bgcolor="white"
        p={4}
        borderRadius="8px"
        sx={{
          height: "80vh",
          overflowY: "auto",
          margin: "8%",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            

            <div className={global.wrapper_form} style={{margin:"0px", width:"2000px"}}>
          
            <Box>
      <Typography variant="h3" gutterBottom>
        Edit Scheme 
      </Typography>

       
      </Box>
          

          <div className={`${global.row} ${global.mar_top_24}`}>
                <div className={global.col_s_2}>
                  <div className={global.form_group}>
                    <label>Scheme Name & ID</label>
                    <input
                      type="text"
                      placeholder="Scheme Name & ID"
                      className={global.form_control}
                      onChange={(e) => {
                        setSchemeName(e.target.value);
                        setErrors((prev) => ({ ...prev, schemeName: !e.target.value }));
                      }}
                      value={schemeName}
                      required
                    />
                    {errors.schemeName && <span className={global.error}>Scheme Name is required</span>}
                  </div>
                </div>
                <div className={global.col_s_2}>
                  <div className={global.form_group}>
                    <label>Scheme Description</label>
                    <textarea
                      placeholder="Scheme Description"
                      className={global.form_control}
                      rows={4}
                      onChange={(e) => {
                        setSchemeDescription(e.target.value);
                        setErrors((prev) => ({ ...prev, schemeDescription: !e.target.value }));
                      }}
                      value={schemeDescription}
                      required
                    />
                    {errors.schemeDescription && <span className={global.error}>Scheme Description is required</span>}
                  </div>
                </div>

              <div className={global.col_s_2}>
                  <div className={global.form_group}>
                    <label>Actions</label>
                    <div>
                    <button className={global.view_btn}>
                      Edit
                    </button>
                    <button className={global.delete_btn}>
                      Delete
                    </button>
                    <Switch
                      variant="contained"
                      color={toggled ? 'success' : 'default'}
                      onClick={handleToggle}
                    >
                      {toggled ? 'Disable' : 'Enable'}
                    </Switch>
                    </div>
                   </div>
                </div> 
            </div>
             
     
            
            </div>
            
            <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            
            {payloadService?.data?.length && (
            <DynamicTable data={payloadService.data} />
          )}
                 
               
          </div>

            <div className={ `${global.row} ${global.mar_top_24}`}>
                  <div className={global.col_s_2}>
                  <button className={global.submit_primary_btn}
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? <Loader /> : "Save Changes"}
              </button>
              </div>
              <div className={global.col_s_2}>
              <button  className={global.submit_seconday_btn} onClick={onClose}>
                Cancel
              </button>
                  </div>
            </div>
            <Modal
        open={successModal}
        onClose={() => setSuccessModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: 300,
            textAlign: "center",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Scheme Updated!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
             Scheme has been successfully Updated.
          </Typography>
          <Button
      sx={{ mt: 3 }}
      variant="contained"
      color="primary"
      onClick={() => {
        setSuccessModal(false);
        navigate('/scheme_list');
      }}
    >
      Done
    </Button>
        </Box>
      </Modal>
          </>
        )}
      </Box>
    </Modal>

    
  );
};

export default EditScheme;
