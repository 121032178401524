import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useronboard from './UserOnboard.module.scss';
import global from '../../assets/scss/global.module.scss';
import fetcher from '../../utils/fetcher';
import { ApiUrls, DepartmentType} from '../../utils/constants';
import Loader from '../../utils/Loader';


const AddDepartmentInformation = ({ userData }) => {
    const [showParticipantsThanks, setshowParticipantsThanks] = useState(false);
    const [loading ,setLoading] = useState(false)
    const [participants, setParticipants] = useState({
        operation: [],
        finance: [],
        it: []
    });

    const [showForm, setShowForm] = useState({
        operation: false,
        finance: false,
        it: false
    });

    const handleAddClick = (dept) => {
        setShowForm((prev) => ({ ...prev, [dept]: true }));
    };

    const participantSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string()
            .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
            .required('Phone number is required'),
    });

    useEffect(() => {
        if (userData!=null && userData.providerDepartmentParticipants && userData.providerDepartmentParticipants.length > 0) {
            setParticipants({
                operation: userData.providerDepartmentParticipants.filter(participant => participant.departmentType === DepartmentType.operation),
                finance: userData.providerDepartmentParticipants.filter(participant => participant.departmentType === DepartmentType.finance),
                it: userData.providerDepartmentParticipants.filter(participant => participant.departmentType === DepartmentType.it)
            });
        }
    }, []);

    const DepartmentParticipantForm = ({ dept }) => {
        const formik = useFormik({
            initialValues: {
                providerUserId: userData.providerUserId,
                departmentType: DepartmentType[dept] || null,
                name: '',
                email: '',
                phone: '',
            },
            validationSchema: participantSchema,
            onSubmit: (values) => {
                saveParticipantData(dept, values);
            },
        });

        const saveParticipantData = async (dept, values) => {
            setLoading(true)
            const res = await fetcher.post(ApiUrls.PROVIDER_SAVE_DEPT_PARTICIPANTS, values);
            if (res?.status === 200) {
                setParticipants((prev) => ({
                    ...prev,
                    [dept]: [...prev[dept], res.response]
                }));
                setShowForm((prev) => ({ ...prev, [dept]: false }));
            }
            setLoading(false)
        };

        return (
            <form onSubmit={formik.handleSubmit}>

                <div className={`${global.row} ${global.mar_top_16}`}>
                {loading && <Loader />}
                    <div className={global.col_s_6}>
                        <div className={global.form_group}>
                            <label>Person Name</label>
                            <input
                                className={global.form_control}
                                placeholder='Enter Name'
                                type='text'
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.name && <div className={`${global.error_message} ${global.text_right}`}>{formik.errors.name}</div>}
                        </div>


                    </div>

                    <div className={global.col_s_6}>
                        <div className={global.form_group}>
                            <label>Person E-mail</label>
                            <input
                                className={global.form_control}
                                placeholder='Enter E-mail'
                                type='text'
                                name='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.email && <div className={`${global.error_message} ${global.text_right}`}>{formik.errors.email}</div>}
                        </div>
                    </div>

                </div>

                <div className={global.row}>

                    <div className={global.col_s_6}>
                        <div className={global.form_group}>
                            <label>Person Phone</label>
                            <input
    className={global.form_control}
    placeholder="Phone Number"
    type="tel"
    name="phone"
    value={formik.values.phone}
    inputMode="numeric"         // Opens numeric keyboard on mobile
    pattern="[0-9]*"            // Restricts to digits only (works on form submission)
    onChange={(e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove any non-numeric character
        formik.setFieldValue('phone', value);
    }}
/>

                            {formik.errors.phone && <div className={`${global.error_message} ${global.text_right}`}>{formik.errors.phone}</div>}
                        </div>
                    </div>

                    <div className={`${global.col_s_6} ${global.mar_top_28}`}>

                        <input
                            type='submit'
                            className={`${global.submit_primary_btn} ${global.display_inline_blk}`}
                            value="Submit"
                        />
                    </div>
                </div>

            </form>
        );
    };

    return (
        <>
        <div>
      <div className={global.wrapper_form}>
        <div className={` ${global.d_flex} ${global.d_flex_l_r}`} style={{marginBottom:'20px'}}>
          <h3>04 Add Manager Details</h3>
        </div>
            
                <Accordion activeIndex={0}>
                    {['operation', 'finance', 'it'].map((dept, idx) => (
                        <AccordionTab key={idx} header={
                            <div className="accordion-header">
                                <div className={`${global.row} `}>
                                    <div className={global.col_s_11}>
                                    <span>
    {dept === 'it'
        ? 'IT Department'
        : `${dept.charAt(0).toUpperCase() + dept.slice(1)} Department`}
</span>

                                    </div>

                                     <div className={ `${useronboard.no_participants_message} ${useronboard.bank_add_new}`}>
                                        <input title='Add New Participants '
                                            type="submit"
                                            className={useronboard.add_bank_account}
                                            value="+ Add"
                                            onClick={() => handleAddClick(dept)}
                                        />
                                   </div>

                                </div>
                            </div>
                        } >

                            {participants[dept].length > 0 ? (
                                <>

                                    {/* <div className={useronboard.no_participants_message}>
                                        <input title='Add New Participants '
                                            type="submit"
                                            className={useronboard.add_bank_account }
                                            value="+ Add"
                                            onClick={() => handleAddClick(dept)}
                                            style={{marginBottom: "1.25rem"}}
                                        />
                                    </div> */}


                                    {participants[dept].map((participant, index) => (
                                        <div key={participant.id} className={useronboard.edit_participants}>
                                            <div className={`${global.d_flex} ${global.d_flex_align_item} ${global.d_flex_l_r}`}>
                                                <ul className={global.d_flex}>
                                                    <li className={global.d_flex}>
                                                        <i className={useronboard.participants_number}>
                                                            {(index + 1).toString().padStart(2, '0')}
                                                        </i>

                                                        <i class={useronboard.first_character}>{participant.name}</i>
                                                    </li>
                                                    <li><span className={useronboard.participants_full_name}>{participant.name}</span></li>
                                                </ul>
                                                <span>
                                                    <a href="#"><i className={global.edit_icon}></i></a>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) :
                                
                               <>
                               </>
                              
                            }

                            {/* Add New Participant Form */}
                            {showForm[dept] && (
                                <DepartmentParticipantForm dept={dept} />
                            )}
                        </AccordionTab>
                    ))}
                </Accordion>
            {/* {showParticipantsThanks && (<ParticipantsThanks onClose={onSubmitListener} />)} */}

            </div>
            </div>
        </>
    );
};

export default AddDepartmentInformation;