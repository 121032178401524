import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import MyWishlistedIP from "./MyWishlistedIP";
import ProductionAccess from "./ProductionAccess";
import CallBackURL from "./CallBackURL";
import APIDocuments from "./APIDocuments";

const DeveloperZone = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Content for each tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <MyWishlistedIP />;
      case 1:
        return <ProductionAccess />;
      case 2:
        return <CallBackURL />;
      case 3:
        return <APIDocuments />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Heading */}
      <Typography
        variant="h6"
        sx={{
          textAlign: "left",
          marginBottom: 2,
          backgroundColor: "#EAF6FF",
          fontWeight: "600",
          color: "#06226A",
        }}
      >
        Developer Zone
      </Typography>

      {/* Tabs */}
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          sx={{
            "& .MuiTab-root": {
              textTransform: "none",
              fontWeight: 500,
              padding: "8px 16px",
              minWidth: 100,
              position: "relative",
              "&.Mui-selected": {
                color: "#000",
              },
            },
          }}
          TabIndicatorProps={{
            style: {
              display: "none", // Hides the default MUI tab indicator
            },
          }}
        >
          <Tab
            label="My Wishlisted IP"
            sx={{
              "&.Mui-selected::after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "3px",
                backgroundColor: "#DC2626",
                position: "absolute",
                bottom: 0,
                left: 0,
              },
            }}
          />
          <Tab
            label="Production Access"
            sx={{
              "&.Mui-selected::after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "3px",
                backgroundColor: "#DC2626",
                position: "absolute",
                bottom: 0,
                left: 0,
              },
            }}
          />
          <Tab
            label="Call back URL"
            sx={{
              "&.Mui-selected::after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "3px",
                backgroundColor: "#DC2626",
                position: "absolute",
                bottom: 0,
                left: 0,
              },
            }}
          />
          <Tab
            label="API Documents"
            sx={{
              "&.Mui-selected::after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "3px",
                backgroundColor: "#DC2626",
                position: "absolute",
                bottom: 0,
                left: 0,
              },
            }}
          />
        </Tabs>
      </Box>

      {/* Tab Content */}
      <Box
        sx={{
          marginTop: 2,
          padding: 2,
          border: "1px solid #E0E0E0",
          borderRadius: 1,
          backgroundColor: "#FFFFFF",
        }}
      >
        {renderTabContent()}
      </Box>
    </Box>
  );
};

export default DeveloperZone;
