import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";

const SelectedUserModal = ({ open, onClose, dummyUsers }) => {
  const [searchBy, setSearchBy] = useState(""); // Track selected search type
  const [searchInput, setSearchInput] = useState(""); // Track search input
  const [selectedUsers, setSelectedUsers] = useState([]); // Track selected users

  const handleUserSelection = (user) => {
    setSelectedUsers((prev) =>
      prev.includes(user)
        ? prev.filter((u) => u !== user)
        : [...prev, user]
    );
  };

  const filteredUsers = dummyUsers.filter((user) =>
    searchBy === "Entity Name"
      ? user.name.toLowerCase().includes(searchInput.toLowerCase())
      : user.id.toString().includes(searchInput)
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: "400px",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select User
        </Typography>

        {/* Search By Dropdown and Input */}
        <Box display="flex" gap={2} alignItems="center" mb={2}>
          <FormControl fullWidth>
            <InputLabel id="search-by-label">Search By</InputLabel>
            <Select
              labelId="search-by-label"
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
              label="Search By"
            >
              <MenuItem value="Entity Name">Entity Name</MenuItem>
              <MenuItem value="User ID">User ID</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            placeholder="Enter Input"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            disabled={!searchBy} // Disable if no search type selected
          />
        </Box>

        {/* User List */}
        <List>
          {filteredUsers.map((user) => (
            <ListItem
              key={user.id}
              button
              onClick={() => handleUserSelection(user)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={selectedUsers.includes(user)}
                  edge="start"
                />
              </ListItemIcon>
              <ListItemText
                primary={user.name}
                secondary={`Api User (${user.id})`}
              />
            </ListItem>
          ))}
        </List>

        {/* Done Button */}
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log("Selected Users:", selectedUsers);
              onClose();
            }}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SelectedUserModal;
