import React, { useState, useEffect } from 'react';
import global from "../../assets/scss/global.module.scss";
import formmodule from "../../assets/scss/form.module.scss";
import fetcher from "../../utils/fetcher";
import { ApiUrls, getLocation } from "../../utils/constants";
import * as Yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFormik } from 'formik';
import Loader from '../../utils/Loader';
import {statesAndUTs} from '../../utils/constants';

const AddGstDetails = ({userData}) => {
  const [selectedOption, setSelectedOption] = useState("first");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [constitutionTypes, setConstitutionTypes] = useState([]);  // Store fetched constitution types
  const [selectedConstitution, setSelectedConstitution] = useState('PRIVATE_LIMITED_COMPANY');  // Store selected value
  const [isDisabled, setIsDisabled] = useState(true);
  const [showFetchData, setShowFetchData] = useState(false);
  const [isShowFormSubmit, setShowFormSubmit] = useState(false);
  const [showWithGSTINDialog, setShowWithGSTINDialog] = useState(false);
  const [showWithOutGSTINDialog, setshowWithOutGSTINDialog] =useState(false);
  const [showFields, setShowFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const getPanFromGstin = (gstin) => {
    if (gstin && gstin.length === 15) {
      return gstin.slice(2, 12); // Extracts characters 3 to 12 (index 2 to 11)
    } else {
      throw new Error("Invalid GSTIN format");
    }
  };

  const verifyGstin = async (gstNumber) => {
    setShowFields(false);
    setShowFetchData(false)
    setLoading(true);
    const location = await getLocation();
    const payload = {
      userId: userData.providerUserId,  
      gstNumber: gstNumber,
      latitude: location.latitude,  
      longitude: location.longitude, 
    };

    const resOtp = await fetcher.post(ApiUrls.VERIFY_GSTIN, payload);
    if (resOtp?.status === 200 && resOtp?.response.status === 200) {
      const data = resOtp.response.response.data.gstDetails;
      const pan = getPanFromGstin(data.gstin);

      // Update formData with fetched details
      setFormData((prevData) => ({
        ...prevData,
        gstinNumber: data.gstin,
        gstinAvail: 1,
        businessName: data.lgnm,
        constitutionType: data.constitutionType,
        constitutionTypeName: data.constitutionTypeName,
        registrationDate: data.rgdt,
        stateCode: data.stjCd,
        address1: data.pradr.addr.bno,
        address2: data.pradr.addr.st,
        pinCode: data.pradr.addr.pncd,
        city: data.pradr.addr.loc || data.pradr.addr.stcd, 
        district: data.pradr.addr.dst,
        stateJurisdiction: data.pradr.addr.stcd,
        status:data.sts,
        businessPAN: pan,
        country:'India'
      }));
      setShowFields(true);
      setShowFetchData(true)
    setShowFormSubmit(true)
    setLoading(false)
    }else {
      formik.setErrors({
        ...formik.errors,
        gstinNumber: resOtp?.response?.message || resOtp?.message
      });
      formik.setTouched({ gstinNumber: true }, true);
      setLoading(false)
    }
  };
  const handleOptionChange = (value) => {
    formik.setFieldValue('gstinAvail', value)
    if(value === 1){
      setShowFetchData(false)
      setShowFormSubmit(false)
    }else if(value === 0){
      setShowFormSubmit(true)
    }
};

  const handleGSTINSubmit = () => {
    verifyGstin(formik.values.gstinNumber)
  };
  const handleSubmitGSTINData = async () => {
    if(formik.values.gstinAvail === 1){
      const formErrors = await formik.validateForm(); // Trigger Formik validation
    
      // If there are form errors, set them and prevent form submission
      if (Object.keys(formErrors).length > 0) {
        formik.setTouched({
          businessName: true,
          constitutionType: true,
          gstinNumber: true,
          incorporationDate: true,
          businessPAN: true,
          address1: true,
          address2: true,
          pinCode: true,
          city: true,
          district: true,
          stateJurisdiction: true,
          country: true
        });  // Mark fields as touched to show errors
        return;  // Prevent further action if there are errors
      }
      onSubmitListener();
    }else if(formik.values.gstinAvail === 0){
      const formErrors = await formik.validateForm(); // Trigger Formik validation
    
      // If there are form errors, set them and prevent form submission
      if (Object.keys(formErrors).length > 0) {
        formik.setTouched({
          businessName: true,
          constitutionType: true,
          gstinNumber: true,
          incorporationDate: true,
          businessPAN: true,
          address1: true,
          address2: true,
          pinCode: true,
          city: true,
          district: true,
          stateJurisdiction: true,
          country: true
        });  // Mark fields as touched to show errors
        return;  // Prevent further action if there are errors
      }
      onSubmitListener();
    }
  };
  const saveBusinessData = async () => {
    setLoading(true)
    const gstinData = {
      providerUserId: userData?.providerUserId || 1, // Assuming this is coming from userData
      constitutionType: formik.values.constitutionType,  // Already selected constitution type
      gstinAvail: formik.values.gstinAvail,
      gstin: formik.values.gstinNumber,  // GSTIN value from Formik
      constitutionTypeName: formik.values.constitutionTypeName || '',
      name: userData?.businessName || formik.values.businessName,  // Business name from Formik
      incorporationDate: formik.values.incorporationDate,  // Incorporation date from Formik
      pan: formik.values.businessPAN,  // PAN from Formik
      registeredAddress1: formik.values.address1,  // Address 1 from Formik
      registeredAddress2: formik.values.address2,  // Address 2 from Formik
      pinCode: formik.values.pinCode,  // Pin code from Formik
      city: formik.values.city,  // City from Formik
      district: formik.values.district,  // District from Formik
      stateJurisdiction: formik.values.stateJurisdiction,  // State jurisdiction from Formik
      country: formik.values.country  // Country from Formik
    };

    try {
     
     const res = await fetcher.post(ApiUrls.PROVIDER_SAVE_BUSINESS_DETAILS, gstinData);
      if (res?.status === 200) {
        userData.leadBusinessDetails = res.response;
      //  nextStep(2);
        setshowWithOutGSTINDialog(false);
        setShowWithGSTINDialog(false)
      } else if (res?.status !== 200) {
        console.error('Error submitting form data:', res.message);
      }else {
        console.error('Error submitting form data:',  res.message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false)
  };
  const  onSubmitListener = () => {
    setLoading(true)
    if(formik.values.gstinAvail === 1){
      saveBusinessData();
    }else if(formik.values.gstinAvail === 0){
      saveBusinessData();
    }
    setLoading(false)
  };

  // const [formData, setFormData] = useState({
  //   gstinNumber: '',
  //   gstinAvail: 1,
  //   incorporationDate: '',
  //   businessPAN: '',
  //   businessName: '',  // For fetched data
  //   constitutionType: '',  // For fetched data
  //   constitutionTypeName: '',
  //   registrationDate: '',  // For fetched data
  //   stateCode: '',  // For fetched data
  //   address1: '',  // For fetched data
  //   address2: '',  // For fetched data
  //   pinCode: '',  // For fetched data
  //   city: '',  // For fetched data
  //   district: '',  // For fetched data
  //   stateJurisdiction: '',  // For fetched data
  //   country: '', 
  //   status:'' // For fetched data
  // });

  useEffect(() => {
    if (userData?.providerBusinessDetails) {
      setFormData({
        gstinNumber: userData.providerBusinessDetails.gstin,
        gstinAvail: userData.providerBusinessDetails.gstinAvail,
        incorporationDate: userData.providerBusinessDetails.incorporationDate,
        businessPAN: userData.providerBusinessDetails.pan,
        businessName: userData.providerBusinessDetails.name,
        constitutionType: userData.providerBusinessDetails.constitutionType,
        constitutionTypeName: userData.providerBusinessDetails.constitutionTypeName,
        registrationDate: '',//userData.providerBusinessDetails.createdAt,
        stateCode: '', // Update with a mapped field if necessary
        address1: userData.providerBusinessDetails.registeredAddress1,
        address2: userData.providerBusinessDetails.registeredAddress2,
        pinCode: userData.providerBusinessDetails.pinCode,
        city: userData.providerBusinessDetails.city,
        district: userData.providerBusinessDetails.district,
        stateJurisdiction: userData.providerBusinessDetails.stateJurisdiction,
        country: userData.providerBusinessDetails.country,
        status: userData.providerBusinessDetails.status,
      });
      setShowFields(true);
      setShowFetchData(true)
    setShowFormSubmit(false)
    }
  }, [userData]);
  

  const [formData, setFormData] = useState({
    gstinNumber: userData?.providerBusinessDetails?.gstin || '',
    gstinAvail: userData?.providerBusinessDetails?.gstinAvail || 1,
    incorporationDate: userData?.providerBusinessDetails?.incorporationDate || '',
    businessPAN: userData?.providerBusinessDetails?.pan || '',
    businessName: userData?.providerBusinessDetails?.name || '',
    constitutionType: userData?.providerBusinessDetails?.constitutionType || '',
    constitutionTypeName: userData?.providerBusinessDetails?.constitutionTypeName || '',
    registrationDate: userData?.providerBusinessDetails?.createdAt || '',
    stateCode: '', // Map this to correct field if available
    address1: userData?.providerBusinessDetails?.registeredAddress1 || '',
    address2: userData?.providerBusinessDetails?.registeredAddress2 || '',
    pinCode: userData?.providerBusinessDetails?.pinCode || '',
    city: userData?.providerBusinessDetails?.city || '',
    district: userData?.providerBusinessDetails?.district || '',
    stateJurisdiction: userData?.providerBusinessDetails?.stateJurisdiction || '',
    country: userData?.providerBusinessDetails?.country || 'India',
    status: userData?.providerBusinessDetails?.status || 'Active',
  });
  


  const validationSchema = Yup.object().shape({
    gstinNumber: Yup.string().when('gstinAvail', (req, schema) => {
      if (req[0] === 1) {
          return schema.required('GSTIN Number is required.')
            .matches(
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                'Invalid GSTIN format. Ensure it follows the format: 15 characters with proper alphanumeric structure.'
            )
      }
    }),
    incorporationDate: Yup.string()
        .required('Business Incorporation Date is mandatory.'),
    businessPAN: Yup.string()
        .required('Business PAN is required.')
        .matches(
            /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
            'Invalid PAN format. It should consist of 5 alphabets followed by 4 digits and 1 alphabet.'
        ),
    businessName: Yup.string()
        .required('Business Name is required.')
        .max(100, 'Business name cannot exceed 100 characters.'),
    constitutionType: Yup.string()
        .required('Constitution Type is required.'),
   
    address1: Yup.string()
        .required('Address Line 1 is required.')
        .max(255, 'Address Line 1 cannot exceed 255 characters.'),
    address2: Yup.string()
        .required('Address Line 2 is required.')
        .max(255, 'Address Line 2 cannot exceed 255 characters.'),
    pinCode: Yup.string()
        .required('Pin Code is required.')
        .matches(
            /^\d{6}$/,
            'Invalid Pin Code. It should consist of exactly 6 digits.'
        ),
    city: Yup.string()
        .required('City is required.')
        .max(100, 'City name cannot exceed 100 characters.'),
    district: Yup.string()
        .required('District is required.')
        .max(100, 'District name cannot exceed 100 characters.'),
    stateJurisdiction: Yup.string()
        .required('State Jurisdiction is required.'),
    country: Yup.string()
        .required('Country is required.')
        .max(100, 'Country name cannot exceed 100 characters.'),
});


const formik = useFormik({
  initialValues: formData,
  validationSchema: validationSchema,
  onSubmit: saveBusinessData,
  enableReinitialize: true,  // Reinitialize when formData changes
});
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
     
      status:formData.gstinAvail === 1 ? 'Active' : '',
    }));
   fetchConstitutionTypes();
}, []);

useEffect(() => {
  if (formik.values.gstinNumber) {
    formik.validateField('gstinNumber'); // Validate the GSTIN field
  }
}, [formik.values.gstinNumber]);


 // Fetch constitution types from API
 const fetchConstitutionTypes = async () => {
  try {
   
      
     const res = await fetcher.get(ApiUrls.LEAD_CONSTITUTION_TYPE, '');
      if (res?.status  === 200) {
          setConstitutionTypes(res.response);  // Populate the dropdown with response
      } else if (res?.status !== 200) {
    
      }else {
         // throw new Error('Failed to fetch data');
      }
  } catch (error) {
     // setError(error.message);
  } finally {

  }
};
  return (
    <div>
      <div className={global.wrapper_form}>
      {loading && <Loader />}
        <h3>02 Add Details</h3>
        <h5> Do you want to continue...</h5>
        <div className={`${global.row} ${global.mar_top_8} `}>
          <div className={global.col_s_6}>
            <div
              className={`${global.form_group} ${global.mar_top_16} ${global.form_group_gray_bor}`}
            >
              <label className={formmodule.radio_button}>
                <i className={formmodule.label_text}>We are GSTIN registered</i>
                <input
                  type="radio"
                  value="1"
                  checked={formik.values.gstinAvail === 1} 
                  onChange={() => handleOptionChange(1)}  // Update formik value on change
                  name="gstinAvail"
                />
                <span className={formmodule.checkmark}></span>
              </label>
            </div>
          </div>
          <div className={global.col_s_6}>
            <div
              className={`${global.form_group} ${global.mar_top_16} ${global.form_group_gray_bor}`}
            >
              <label className={formmodule.radio_button}>
                <i className={formmodule.label_text}>
                  We are non GSTIN registered
                </i>
                <input
                  type="radio"
                  value="0"
                  checked={formik.values.gstinAvail === 0} 
                  onChange={() => handleOptionChange(0)}  // Update formik value on change
                  name="gstinAvail"
                />
                <span className={formmodule.checkmark}></span>
              </label>
            </div>
          </div>
        </div>

        {formik.values.gstinAvail === 1 && (
          <>
            <div className={`${global.row} ${global.mar_top_8} `}>
              <div className={global.col_s_6}>
                <div className={`${global.row} ${global.mar_top_8} `}>
                  <div className={global.col_s_8}>
                    <div className={global.form_group}>
                    <label>GSTIN Number    {showFields && <a href="">Change GSTIN Number</a>}</label>
                                  <input name='gstinNumber' className={ ` ${global.form_control}`}
                                  placeholder='Enter Your GSTIN no.' 
                                  type='text' 
                                  onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    formik.handleChange(e);
                                  }}
                                    value={formik.values.gstinNumber} 
                                    />
                                  {formik.touched.gstinNumber && formik.errors.gstinNumber && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.gstinNumber}</div>
                                  )}
                    </div>
                  </div>
                  <div className={`${global.col_s_4} ${global.mar_top_28} `}>
                    <div className={global.form_group}>
                    <input
                      type="submit"
                      onClick={() => {
                        if (formik.isValid) {
                          handleGSTINSubmit();
                        }
                      }}
                      className={`${!formik.isValid ?   global.submit_primary_btn: global.submit_primary_btn} ${global.display_inline_blk}`}
                      value="Fetch Data"
                      disabled={!formik.values.gstinNumber}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          

{showFields && (
                        <>
                        <div className={global.row}>
                        <div className={global.col_s_6}>
                        <div className={`${global.form_group} ${global.date_padding_none}`}>
                                  <label>Business Incorporation Date</label>

                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                        className={global.form_control}
                                        name="incorporationDate"
                                        onChange={(date) => formik.setFieldValue('incorporationDate', date ? format(date, 'dd/MM/yyyy') : formik.values.incorporationDate)}
                                        onBlur={formik.handleBlur}
                                        renderInput={(params) => <TextField {...params}
                                        />}
                                    />
                                  </LocalizationProvider>
                                    {formik.touched.incorporationDate && formik.errors.incorporationDate && (
                                      <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.incorporationDate}</div>
                                    )}
                                </div>
                                </div>

                                <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                              <label>Business PAN</label>
                              <input className={ ` ${global.form_control} ${global.disabled_text}`} placeholder='Enter business PAN number' type='text'
                                value={formik.values.businessPAN}  />
                            </div>
                          </div>
                        </div>  
                        </>
                        )}

                        {showFetchData  && (
                          <>
                          <h3 style={{marginBottom:"24px"}}>Fetched Data</h3>
                          <div className={global.row}>
                          <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                              <label>Business Name</label>
                              <input className={ ` ${global.form_control} ${global.disabled_text}`} 
                              placeholder='Tramo' type='text' value={formik.values.businessName}  />
                            </div>

                            <div className={`${global.form_group}`}>
                              <label>Constitution type</label>
                              <input className={ ` ${global.form_control} ${global.disabled_text}`} 
                              placeholder='Sole Proprietorship' type='text'value={formik.values.constitutionType}/>
                            </div>

                            <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>Registration Date</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`}
                                   placeholder='Registration Date' type='text' value={formik.values.registrationDate}/>
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Status</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`}
                                   placeholder='Status' type='text' value={formik.values.status}/>
                                </div>
                              </div>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>State Code</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`}
                                   placeholder='State Code' type='text' value={formik.values.stateCode}/>
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Centre</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`} 
                                  placeholder='Centre' type='text' value={formik.values.status}/>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                              <label>Business Registered Address 1</label>
                              <input className={ ` ${global.form_control} ${global.disabled_text}`}
                              placeholder='Business Registered Address 1' type='text'
                               value={formik.values.address1}/>
                            </div>

                            <div className={`${global.form_group}`}>
                              <label>Business Registered Address 2</label>
                              <input className={ ` ${global.form_control} ${global.disabled_text}`}
                               placeholder='Business Registered Address 2' type='text' 
                               value={formik.values.address2}/>
                            </div>

                            <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>

                                  <label>Pin Code</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`} 
                                  placeholder='Pin Code' type='text'   value={formik.values.pinCode} />

                                </div>
                              </div>
                            </div>

                            <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>City/Village/Town</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`}
                                   placeholder='City/Village/Town' type='text' value={formik.values.city}/>
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>District </label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`}
                                   disabled={isDisabled} placeholder='District ' type='text' 
                                   value={formik.values.district}/>
                                </div>
                              </div>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>State jurisdiction</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`}
                                   disabled={isDisabled} placeholder='State jurisdiction' type='text' 
                                   value={formik.values.stateJurisdiction} />
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Country</label>
                                  <input className={ ` ${global.form_control} ${global.disabled_text}`}
                                  disabled={isDisabled} placeholder='Country' type='text'
                                  value={formik.values.country}  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                          </>
                        )}
          </>
       )}

{formik.values.gstinAvail === 0 && (
                      <>
                        <div className={global.row}>
                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>Business Name</label>
                                  <input className={global.form_control} placeholder='Business Name' type='text'
                                    onChange={formik.handleChange}
                                    value={formik.values.businessName} 
                                    name='businessName' />
                                    {formik.touched.businessName && formik.errors.businessName && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.businessName}</div>
                                  )}
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Constitution type</label>
                                    <select 
                                        name="constitutionType" 
                                        className={global.form_control}
                                        value={formik.values.constitutionType} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option value="">-- Select Constitution Type --</option>
                                        {constitutionTypes.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                    {formik.touched.constitutionType && formik.errors.constitutionType && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.constitutionType}</div>
                                    )}
                                   
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Business Incorporation Date</label>
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                        className={global.form_control}
                                        name="incorporationDate"
                                        onChange={(date) => formik.setFieldValue('incorporationDate', date ? format(date, 'dd/MM/yyyy') : '')}
                                        onBlur={formik.handleBlur}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                  </LocalizationProvider>
                                  
                                   {formik.touched.incorporationDate && formik.errors.incorporationDate && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.incorporationDate}</div>
                                  )}
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Business Pan</label>
                                  <input className={global.form_control} placeholder='AAAPL1234C' type='text' 
                                        onChange={formik.handleChange}
                                        value={formik.values.businessPAN} 
                                         name='businessPAN'/>
                                          {formik.touched.businessPAN && formik.errors.businessPAN && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.businessPAN}</div>
                                  )}
                                </div>

                              </div>

                              <div className={global.col_s_6}>
                                <div className={`${global.form_group}`}>
                                  <label>Business Registered Address 1</label>
                                  <input className={global.form_control} placeholder='Business Registered Address 1' type='text'
                                     onChange={formik.handleChange}
                                     value={formik.values.address1} 
                                      name='address1'/>
                                       {formik.touched.address1 && formik.errors.address1 && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.address1}</div>
                                  )}
                                </div>

                                <div className={`${global.form_group}`}>
                                  <label>Business Registered Address 2</label>
                                  <input className={global.form_control} placeholder='Business Registered Address 2' type='text'
                                     onChange={formik.handleChange}
                                     value={formik.values.address2} 
                                      name='address2' />
                                       {formik.touched.address2 && formik.errors.address2 && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.address2}</div>
                                  )}
                                </div>

                                <div className={global.row}>
                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>Pin Code</label>
                                        <input className={global.form_control} placeholder='Area Pin Code' type='text' 
                                        onChange={formik.handleChange}
                                        value={formik.values.pinCode} 
                                         name='pinCode' />
                                          {formik.touched.pinCode && formik.errors.pinCode && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.pinCode}</div>
                                  )}
                                      </div>
                                      </div>
                                </div>  

                                <div className={global.row}>
                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>City/Village/Town</label>
                                        <input className={global.form_control} placeholder='Enter City/Village/Town Name' type='text' 
                                        onChange={formik.handleChange}
                                        value={formik.values.city} 
                                         name='city' />
                                          {formik.touched.city && formik.errors.city && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.city}</div>
                                  )}
                                      </div>
                                      </div>

                                      <div className={global.col_s_6}>
                                      <div className={`${global.form_group}`}>
                                        <label>District</label>
                                        <input className={global.form_control} placeholder='Enter District' type='text' 
                                         onChange={formik.handleChange}
                                         value={formik.values.district} 
                                          name='district' />
                                           {formik.touched.district && formik.errors.district && (
                                    <div className={`${global.error_message} ${global.text_right} `}>{formik.errors.district}</div>
                                  )}
                                      </div>
                                      </div>
                                </div> 

                                <div className={global.row}>
  {/* State Jurisdiction Dropdown */}
  <div className={global.col_s_6}>
    <div className={`${global.form_group}`}>
      <label>State jurisdiction</label>
      <select
        id="stateJurisdiction"
        className={global.form_control}
        onChange={formik.handleChange}
        value={formik.values.stateJurisdiction}
        name="stateJurisdiction"
      >
        <option value="">Select</option>
        {statesAndUTs.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
      {formik.touched.stateJurisdiction && formik.errors.stateJurisdiction && (
        <div className={`${global.error_message} ${global.text_right}`}>
          {formik.errors.stateJurisdiction}
        </div>
      )}
    </div>
  </div>

  {/* Country Dropdown */}
  <div className={global.col_s_6}>
    <div className={`${global.form_group}`}>
      <label>Country</label>
      <select
        id="country"
        className={global.form_control}
        onChange={formik.handleChange}
        value={formik.values.country}
        name="country"
      >
        <option value="">Select</option>
        <option value="India">India</option>
      </select>
      {formik.touched.country && formik.errors.country && (
        <div className={`${global.error_message} ${global.text_right}`}>
          {formik.errors.country}
        </div>
      )}
    </div>
  </div>
</div>


                              </div>
                            </div>
                      </>
                    )}


{isShowFormSubmit  && ( 
                    <div >
                    <div className={global.row}>
                      <div className={global.col_s_2}>
                        <input type='submit' onClick={handleSubmitGSTINData} className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Submit" />
                      </div>
                    </div>
                    </div>
                  )}
      </div>
     
    </div>
  );
};

export default AddGstDetails;
