import React, { useEffect, useState } from 'react';
import { Typography, Select, MenuItem, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, FormControl, InputLabel } from '@mui/material';
import fetcher from '../../utils/fetcher';
import { PageUrls, ApiUrls, filterTypes, options } from "../../utils/constants";
import { useNavigate } from 'react-router-dom';
import LeadDetail from './detail/LeadsDetail';
import global from '../../assets/scss/global.module.scss'
import Loader from '../../utils/Loader';
import '../../assets/scss/style.scss';

const textIncomplete = "Incomplete";
const textFresh = "Fresh";
const textOpen = "Open";
const textRejected = "Rejected";
const textMatured = "Matured";
const textResubmission = "Resubmission";
const textUserSignPending = "User Sign Pending";
const textAdminSignPending = "Admin Sign Pending";


export const getClassForStatus = (status) => {

  switch (status) {
    case textIncomplete:
      return global.status_incomplete;
    case textFresh:
      return global.status_fresh;
    case textOpen:
      return global.status_open;
    case textRejected:
      return global.status_rejected;
    case textMatured:
      return global.status_matured;
    case textResubmission:
      return global.status_resubmission;
    case textUserSignPending:
      return global.status_user_sign_pending;
    case textAdminSignPending:
      return global.status_admin_sign_pending;
    default:
      return '';
  }
};

const Leads = () => {
  const navigate = useNavigate();
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    filterType: 'entityName',
    filterValue: '',
    status: '',
    startDate: '',
    endDate: '',
  });

  const [statuses] = useState(['ACTIVE', 'INACTIVE']);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLeadClicked, setLeadClicked] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState();

  const textIncomplete = "Incomplete";
  const textFresh = "Fresh";
  const textOpen = "Open";
  const textRejected = "Rejected";
  const textMatured = "Matured";
  const textResubmission = "Resubmission";
  const textUserSignPending = "User Sign Pending";
  const textAdminSignPending = "Admin Sign Pending";


  const getClassForStatus = (status) => {

    switch (status) {
      case textIncomplete:
        return global.status_incomplete;
      case textFresh:
        return global.status_fresh;
      case textOpen:
        return global.status_open;
      case textRejected:
        return global.status_rejected;
      case textMatured:
        return global.status_matured;
      case textResubmission:
        return global.status_resubmission;
      case textUserSignPending:
        return global.status_user_sign_pending;
      case textAdminSignPending:
        return global.status_admin_sign_pending;
      default:
        return '';
    }
  };



  useEffect(() => {
    // setupLogin();
    //localStorage.setItem('auth', JSON.stringify("eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4ODAwNjMxODU3IiwiZW1haWwiOiJhcnVuQGdtYWlsLmNvbSIsImlkIjoxLCJyb2xlIjpbIkFkbWluIl0sImlhdCI6MTczMTUyMzA0MCwiZXhwIjoxNzMyMTI3ODQwfQ.cjZSATeHTJ9wyzlW_O4izFKv9DdFW7zpc6LWPGXjEUQ"));

    fetchLeads(currentPage);
  }, [currentPage]);

  const setupLogin = async () => {
    const adminData = {
      leadUserId: 1,
      email: "arun@gmail.com",
      mobile: "8800631857",
      role: ["Admin"],
    }
    try {
      const res = await fetcher.post(ApiUrls.AUTHENTICATE, adminData);
      localStorage.setItem('auth', JSON.stringify(res));

    } catch (err) {
    }
  }

  const fetchLeads = async (page) => {
    setLoading(true)
    try {
      const data = await fetcher.get(`${ApiUrls.All_LEADS}?page=${page - 1}&size=${itemsPerPage}&field=leadStatus&value=Incomplete,Open,Rejected,Matured`);
      if (data?.status === 200) {
        setCurrentPage(page);
        setTotalPages(data?.response.totalPages);
        setLeads(data.response.userLeads);
        setFilteredLeads(data.response.userLeads);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
    setLoading(false)
  };



  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const clearFilters = () => {
    setFilters({
      filterType: 'entityName',
      filterValue: '',
      status: '',
      startDate: '',
      endDate: '',
    });
    setFilteredLeads(leads);
  };

  const searchFilters = async () => {
    setLoading(true);
    try {
      const { filterType, filterValue, status, startDate, endDate } = filters;

      // Build query parameters dynamically
      const params = new URLSearchParams();
      if (filterType && filterValue) {
        params.append('field', filterType);
        params.append('value', filterValue);
      }
      if (status) params.append('status', status);
      if (startDate) params.append('startDate', new Date(startDate).toISOString());
      if (endDate) params.append('endDate', new Date(endDate).toISOString());

      // Fetch leads based on filters
      const url = `${ApiUrls.All_LEADS}?page=${currentPage - 1}&size=${itemsPerPage}&field=leadStatus&value=Incomplete,Open,Rejected,Matured&${params.toString()}`;
      const data = await fetcher.get(url);

      if (data?.status === 200) {
        setFilteredLeads(data.response.userLeads);
        setTotalPages(data.response.totalPages);
      }
    } catch (error) {
      console.error('Error fetching filtered leads:', error);
    }
    setLoading(false);
  };






  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === options[0]) navigate(PageUrls.LEADS);
    else if (e.target.value === options[1]) navigate(PageUrls.NEWLEADS);
    else if (e.target.value === options[2]) navigate(PageUrls.ESIGNREQ);
    else if (e.target.value === options[3]) navigate(PageUrls.APIUSER);
  };

  const handleDownload = () => {
    const headers = [
      'Date & Time',
      'Lead ID',
      'Entity Name / Auth. Person Name',
      'Email ID',
      'Phone Number',
      'Company Website',
      'Message',
      'Onboarding Link',
      'Status'
    ];

    const rows = filteredLeads.map((lead) => [
      new Date(lead.createdAt * 1000).toLocaleString(),
      lead.leadUserId,
      `${lead.companyName} / ${lead.authorizedPersonName}`,
      lead.email,
      lead.mobile,
      lead.companyWebsite,
      lead.message,
      lead.onboardingLink,
      lead.status
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map((row) => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'leads_data.csv';
    link.click();
  };


  const openLeadDetail = (leadId) => {
    setSelectedLeadId(leadId);

    setLeadClicked(true);

  };



  return (
    <div className={`${global.gray_bg} ${global.pad_16} `}>
      {loading && <Loader />}
      <div className='container-fluid'>
        <div className={`${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
          <Typography variant="h1" gutterBottom>Leads</Typography>
        </div>

        {!isLeadClicked && (
          <>
            <div className={`${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16} `} >
              <FormControl variant="outlined" style={{ width: "120px", marginRight: "8px" }} >
                <InputLabel>Select an option</InputLabel>
                <Select value={selectedOption} onChange={handleOptionChange} label="Select an option">
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" style={{ width: "120px", marginRight: "8px" }}>
                <InputLabel>Filter Type</InputLabel>
                <Select name="filterType" value={filters.filterType} onChange={handleChange} label="Filter Type">
                  {filterTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField style={{ width: "120px", marginRight: "8px" }}
                variant="outlined"
                name="filterValue"
                label={`Enter ${filters.filterType === 'entityName' ? 'Entity Name' : filters.filterType}`}
                value={filters.filterValue}
                onChange={handleChange}
              />

              <FormControl variant="outlined" style={{ width: "120px", marginRight: "8px", marginLeft: "8px" }}>
                <InputLabel>Status</InputLabel>
                <Select name="status" value={filters.status} onChange={handleChange} label="Status">
                  <MenuItem value="">Select Status</MenuItem>
                  {statuses.map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField style={{ width: "120px", marginRight: "8px" }}
                variant="outlined"
                type="date"
                name="startDate"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                value={filters.startDate}
                onChange={handleChange}
              />

              <TextField
                variant="outlined"
                type="date"
                name="endDate"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                value={filters.endDate}
                onChange={handleChange}
              />

              <button className={`${global.clear_btn} ${global.lead_common_btn} `} onClick={clearFilters}>Clear</button>
              <button className={`${global.submit_btn_1} ${global.lead_common_btn} `} onClick={searchFilters}> Search <i className={global.lead_search_icon}></i></button>
              <button className={`${global.download_btn_1} ${global.lead_common_btn} `} onClick={handleDownload}>Download <i className={global.lead_download_icon}></i></button>
            </div>

            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ background: "#EAF6FF" }}>
                  <TableRow>
                    <TableCell style={{ width: "115px" }}>Date & <br /> Time</TableCell>
                    <TableCell>Lead ID</TableCell>
                    <TableCell>Entity Name <br /> Auth. Person Name</TableCell>
                    <TableCell>Email ID</TableCell>
                    <TableCell style={{ width: "170px" }}>Phone Number</TableCell>
                    <TableCell>Company Website</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Onboarding Link</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={global.gray_lead_row}>
                  {filteredLeads.map((lead) =>
                    console.log(new Date(lead.createdAt * 1000).toLocaleString()) ||
                    (
                      <TableRow key={lead.leadUserId} style={{ cursor: 'pointer' }}>
                        <TableCell>{new Date(lead.createdAt * 1000).toLocaleDateString()}<br />
                          {new Date(lead.createdAt * 1000).toLocaleTimeString()}</TableCell>
                        <TableCell>{lead.leadUserId}</TableCell>
                        <TableCell > {lead.companyName} <br />{lead.authorizedPersonName}</TableCell>
                        {/* <TableCell > {`${lead.companyName} / ${lead.authorizedPersonName}`}</TableCell> */}
                        <TableCell><span className={global.d_flex} title={lead.email}><span className={global.text_truncation}>{lead.email}</span> <i className={global.green_check_icon}></i></span></TableCell>
                        <TableCell>{lead.mobile} <i className={global.green_check_icon}></i></TableCell>
                        <TableCell><span className={global.d_flex} style={{ justifyContent: "center" }}>
                          {lead.companyWebsite !== "" ? <a className={global.text_truncation}
                            href={lead.companyWebsite}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={lead.companyWebsite}>{lead.companyWebsite}
                          </a> : "-"}

                        </span>
                        </TableCell>
                        <TableCell><span className={global.d_flex}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit,"</span></TableCell>
                        <TableCell> <span className={global.d_flex} style={{ justifyContent: "center" }}>
                          {lead.link !== null ?
                            <> <a className={global.text_truncation}
                              href={lead.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              title={lead.link} >
                              {lead.link}
                            </a>
                              <i className={global.out_link_icon}></i></> : "-"}
                        </span>
                        </TableCell>
                        <TableCell>
                          <div className={getClassForStatus(lead.leadStatus)}>
                            {lead.leadStatus}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className={`${global.pageination}`}>
              <div className={global.row}>
                <div className={global.col_s_12}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(e, page) => setCurrentPage(page)}
                    color="primary"

                    sx={{
                      '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: '#EAF6FF', // Change to your preferred color
                        borderRadius: '2px',
                        margin: '0',
                        border: 'solid #0682FF 1px;',
                        color: '#06226A',
                      },
                      '& .MuiPaginationItem-root:hover': {
                        backgroundColor: '#EAF6FF', // Change to your preferred color
                        borderRadius: '0',
                        border: 'none',
                        color: '#06226A',       // Hover text color (darker blue)
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {/*       
      {isLeadClicked && <LeadDetail leadId={selectedLeadId} />} */}
      </div>
    </div>
  );
}

export default Leads;
