import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import Loader from "../../utils/Loader";

const modeOptions = [
  "RTGS",
  "NEFT",
  "IMPS",
  "Fund Transfer",
  "Cash Deposit at Branch",
  "Cash Deposit at CDM",
];

const AddBankModal = ({ open, onClose, onSubmit }) => {
  const [modeDetails, setModeDetails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for loader

  const handleTableChange = (id, field, value) => {
    setModeDetails((prevDetails) =>
      prevDetails.map((detail) =>
        detail.id === id ? { ...detail, [field]: value } : detail
      )
    );
  };

  const formik = useFormik({
    initialValues: {
      accountHolderName: "",
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      branchName: "",
      accountType: "",
      minimumDeposit: "",
      maxDeposit: "",
      allowedModes: [],
    },
    validationSchema: Yup.object({
      accountHolderName: Yup.string().required("Account Holder Name is required"),
      bankName: Yup.string().required("Bank Name is required"),
      accountNumber: Yup.string().required("Account Number is required"),
      ifscCode: Yup.string().required("IFSC Code is required"),
      branchName: Yup.string().required("Branch Name is required"),
      accountType: Yup.string().required("Account Type is required"),
      minimumDeposit: Yup.number()
        .typeError("Minimum Deposit must be a number")
        .required("Minimum Deposit is required"),
      maxDeposit: Yup.number()
        .typeError("Max Deposit must be a number")
        .required("Max Deposit is required")
        .min(
          Yup.ref("minimumDeposit"),
          "Max Deposit should be greater than or equal to Minimum Deposit"
        ),
      allowedModes: Yup.array().min(1, "At least one mode must be selected"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true); // Show loader
      try {
        // Transform modeDetails into the required structure
        const transformedModeDetails = modeDetails.map((detail) => ({
          mode: detail.mode,
          chargeOrCommission: detail.chargeType === "Charge" ? "1" : "2", // Map chargeType to chargeOrCommission
            type: detail.type === "$" ? "2" : "1",
          value: detail.value,
        }));
    
        const payload = {
          ...values,
          maximumDeposit: values.maxDeposit,
          allowedModes: values.allowedModes.join(","),
          bankPaymentMode: transformedModeDetails, // Send as bankPaymentMode
        };
    
        const response = await fetcher.post(ApiUrls.SAVE_BANK_DETAILS, payload);
        console.log("Bank details saved successfully:", response.data);
        onSubmit(response.data);
        formik.resetForm();
        setModeDetails([]);
      } catch (error) {
        console.error("Error saving bank details:", error);
      } finally {
        setIsSubmitting(false); // Hide loader
      }
    },
    
  });

  const handleModeSelection = (selectedModes) => {
    formik.setFieldValue("allowedModes", selectedModes);
    const updatedDetails = selectedModes.map((mode) => ({
      id: mode,
      mode,
      chargeType: "",
      type: "",
      value: "",
    }));
    setModeDetails(updatedDetails);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add Bank Details</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {/* Show loader when submitting */}
          {isSubmitting && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Loader />
            </Box>
          )}
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
            <TextField
              label="Account Holder Name"
              name="accountHolderName"
              value={formik.values.accountHolderName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.accountHolderName && Boolean(formik.errors.accountHolderName)}
              helperText={formik.touched.accountHolderName && formik.errors.accountHolderName}
              fullWidth
            />
            <TextField
              label="Bank Name"
              name="bankName"
              value={formik.values.bankName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.bankName && Boolean(formik.errors.bankName)}
              helperText={formik.touched.bankName && formik.errors.bankName}
              fullWidth
            />
            <TextField
              label="Account Number"
              name="accountNumber"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
              helperText={formik.touched.accountNumber && formik.errors.accountNumber}
              fullWidth
            />
            <TextField
              label="IFSC Code"
              name="ifscCode"
              value={formik.values.ifscCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ifscCode && Boolean(formik.errors.ifscCode)}
              helperText={formik.touched.ifscCode && formik.errors.ifscCode}
              fullWidth
            />
            <TextField
              label="Branch Name"
              name="branchName"
              value={formik.values.branchName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.branchName && Boolean(formik.errors.branchName)}
              helperText={formik.touched.branchName && formik.errors.branchName}
              fullWidth
            />
            <TextField
              select
              label="Account Type"
              name="accountType"
              value={formik.values.accountType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.accountType && Boolean(formik.errors.accountType)}
              helperText={formik.touched.accountType && formik.errors.accountType}
              fullWidth
            >
              <MenuItem value="Savings">Savings</MenuItem>
              <MenuItem value="Current">Current</MenuItem>
            </TextField>
            <TextField
              label="Minimum Deposit"
              name="minimumDeposit"
              value={formik.values.minimumDeposit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.minimumDeposit && Boolean(formik.errors.minimumDeposit)}
              helperText={formik.touched.minimumDeposit && formik.errors.minimumDeposit}
              fullWidth
            />
            <TextField
              label="Max Deposit"
              name="maxDeposit"
              value={formik.values.maxDeposit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.maxDeposit && Boolean(formik.errors.maxDeposit)}
              helperText={formik.touched.maxDeposit && formik.errors.maxDeposit}
              fullWidth
            />
            <TextField
              select
              label="Allowed Modes"
              name="allowedModes"
              value={formik.values.allowedModes}
              onChange={(e) => handleModeSelection(e.target.value)}
              onBlur={formik.handleBlur}
              error={formik.touched.allowedModes && Boolean(formik.errors.allowedModes)}
              helperText={formik.touched.allowedModes && formik.errors.allowedModes}
              SelectProps={{ multiple: true }}
              fullWidth
            >
              {modeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {/* Mode Details Table */}
          {modeDetails.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Mode</TableCell>
                    <TableCell>Charge/Commission</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modeDetails.map((detail) => (
                    <TableRow key={detail.id}>
                      <TableCell>{detail.id}</TableCell>
                      <TableCell>{detail.mode}</TableCell>
                      <TableCell>
                        <Select
                          value={detail.chargeType}
                          onChange={(e) =>
                            handleTableChange(detail.id, "chargeType", e.target.value)
                          }
                          fullWidth
                        >
                          <MenuItem value="Charge">Charge</MenuItem>
                          <MenuItem value="Commission">Commission</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={detail.type}
                          onChange={(e) =>
                            handleTableChange(detail.id, "type", e.target.value)
                          }
                          fullWidth
                        >
                          <MenuItem value="₹">₹</MenuItem>
                          <MenuItem value="%">%</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          placeholder="Enter value"
                          value={detail.value}
                          onChange={(e) =>
                            handleTableChange(detail.id, "value", e.target.value)
                          }
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isSubmitting}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddBankModal;
