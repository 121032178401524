import React from 'react';
import { BrowserRouter } from 'react-router-dom'; 
import Routers from './routers/Routers';
import AddProvider from './pages/provider/AddProvider';


function App() {
  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
   // <AddProvider />
  );
}
export default App;
