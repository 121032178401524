import React, { useRef } from "react";
import DOMPurify from 'dompurify';
import global from  '../../../assets/scss/global.module.scss';

const ESignAgreementPreview = (props) => {
    const { content } = props
    const pdfRef = useRef(null);
    const handlePrint = () => {
        if (pdfRef.current) {
            // Clone the content to avoid modifying the actual DOM
            const contentToPrint = pdfRef.current.cloneNode(true);

            // Open a new window
            const printWindow = window.open("", "_blank");
            if (printWindow) {
                // Write the content to the new window
                printWindow.document.open();
                printWindow.document.write(`
                  <html>
                    <head>
                      <title>Print Preview</title>
                      <style>
                        /* Ensure styles are preserved */
                        body {
                          font-family: Arial, sans-serif;
                          margin: 0;
                          padding: 20px;
                        }
                        table {
                          width: 100%;
                          border-collapse: collapse;
                        }
                        th, td {
                          border: 1px solid black;
                          padding: 8px;
                          text-align: left;
                        }
                      </style>
                    </head>
                    <body>${contentToPrint.innerHTML}</body>
                  </html>
                `);
                printWindow.document.close();

                // Trigger the print dialog
                printWindow.print();

                // Close the print window
                printWindow.close();
            }
        }
    };
    const sanitizedContent = DOMPurify.sanitize(content);
    return (<>
        <button className={global.pdf_generator} onClick={handlePrint}>Generate PDF</button>
        <div ref={pdfRef}>
            <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        </div>
    </>

    )
}

export default ESignAgreementPreview