import React from 'react';
import global from '../../assets/scss/global.module.scss';

const UserApplyScheme = () => {
    const dummyData = [
        {
            id: 'SCH001',
            name: 'Education Scheme',
            category: 'Education',
            product: 'Books',
            dateOfCreation: '2023-12-01',
            description: 'Provides books for underprivileged children.',
        },
        {
            id: 'SCH002',
            name: 'Health Scheme',
            category: 'Healthcare',
            product: 'Medicines',
            dateOfCreation: '2023-10-15',
            description: 'Offers free medicines to low-income families.',
        },
        {
            id: 'SCH003',
            name: 'Agriculture Scheme',
            category: 'Agriculture',
            product: 'Seeds',
            dateOfCreation: '2024-01-05',
            description: 'Distributes seeds to farmers in need.',
        },
    ];

    return (
        <div className={global.wrapper_form}>
            <h3 className={global.mar_bottom_20}>Apply Scheme</h3>
            <table className={global.mar_top_16} cellpadding="5" bordercolor="#eeeeee" cellspacing="0" border="1" style={{borderCollapse:"collapse"}}>
                <thead style={{background: "#eeeeee"}}>
                    <tr>
                        <th>Scheme Name</th>
                        <th>ID</th>
                        <th>Category</th>
                        <th>Product</th>
                        <th>Date of Creation</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {dummyData.map((scheme) => (
                        <tr key={scheme.id}>
                            <td>{scheme.name}</td>
                            <td>{scheme.id}</td>
                            <td>{scheme.category}</td>
                            <td>{scheme.product}</td>
                            <td>{scheme.dateOfCreation}</td>
                            <td>{scheme.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserApplyScheme;
