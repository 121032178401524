import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Stack,
  Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';
import global from '../../assets/scss/global.module.scss'
import EditSchemeModal from "./EditScheme";
import Loader from '../../utils/Loader';

const SchemeListPage = () => {
  const [data, setData] = useState([]); // Data fetched from the API
  const [filteredData, setFilteredData] = useState([]); // Filtered data for display
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [totalRecords, setTotalRecords] = useState(0); // Total records
  const navigate = useNavigate();
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [editedSchemeId, setEditedSchemeId ] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  // Fetch schemes from the API
  useEffect(() => {
    const fetchSchemes = async () => {
      try {
        setLoader(true);
        const response = await fetcher.get(
          `${ApiUrls.FETCH_SCHEME_BY_TYPE}?schemeType=1&pageNo=${page + 1}`
        );
        if (response?.status === 200 && Array.isArray(response.response)) {
          setData(response.response);
          setFilteredData(response.response); // Update filteredData
          setTotalRecords(response.response.length); // Update total records
          setLoader(false);
        } else {
          console.error('Failed to fetch schemes:', response.message);
        
        }
      } catch (error) {
        console.error('Error fetching schemes:', error);
      }
    };
    fetchSchemes();
  }, [page]);
  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false)
  };
  // Handle filtering
  const handleFilter = (type) => {
    const today = new Date();
    let filtered = [];

    switch (type) {
      case 'all':
        filtered = data;
        break;
      case 'today':
        filtered = data.filter(
          (scheme) =>
            new Date(scheme.schedeleAt * 1000).toDateString() ===
            today.toDateString()
        );
        break;
      case 'yesterday':
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        filtered = data.filter(
          (scheme) =>
            new Date(scheme.schedeleAt * 1000).toDateString() ===
            yesterday.toDateString()
        );
        break;
      case 'last7days':
        filtered = data.filter(
          (scheme) =>
            new Date(scheme.schedeleAt * 1000) >=
            new Date(today.setDate(today.getDate() - 7))
        );
        break;
      case 'last30days':
        filtered = data.filter(
          (scheme) =>
            new Date(scheme.schedeleAt * 1000) >=
            new Date(today.setDate(today.getDate() - 30))
        );
        break;
      default:
        break;
    }
    setFilteredData(filtered);
  };

  // Handle date filtering
  const handleDateFilter = () => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const filtered = data.filter((scheme) => {
        const scheduled = new Date(scheme.schedeleAt * 1000); // Convert timestamp to date
        return scheduled >= start && scheduled <= end;
      });
      setFilteredData(filtered);
    }
  };

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };
  const handleEdit = (schemeId) => {
    
    // Set the data of the row to be edited
    setEditedSchemeId(schemeId)
   setIsEditModalOpen(true); // Open the modal
 };
  return (
    <div className= { `${global.gray_bg} ${global.pad_16} `}>
    <Box >

    

    <div className= { `${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16} `} >

          <div className={global.row}>
            <div className={global.col_s_10}>
          <a href={void(0)} className={global.scheme_active_tab} onClick={() => handleFilter('all')}>
            All
          </a>
          <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('today')}>
            Today
          </a>
          <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('yesterday')}>
            Yesterday
          </a>
          <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('last7days')}>
            Last 7 Days
          </a>
          <a href={void(0)} className={global.scheme_tab} onClick={() => handleFilter('last30days')}>
            Last 30 Days
          </a>
          <TextField
          style={{margin:"0 8px 0 0"}}
            type="date"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            type="date"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
       </div>
       <div className={global.col_s_2}>
        <a  className={global.submit_primary_btn}  onClick={() => navigate('/admin_scheme')}>
          Create New Scheme
        </a>
        </div>
        </div>
    </div>

      <Paper>
      {loader && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Loader />
        </Box>
      )}
        <TableContainer>
          <Table>
            <TableHead style={{background:"#EAF6FF"}}>
              <TableRow>
                <TableCell>Scheme Name</TableCell>
                <TableCell>Scheduled For</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Live On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData &&
                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <a href={void(0)} className={global.underline} style={{textDecoration:"underline", cursor:"pointer", color:"blue"}} onClick={() => handleEdit(row.id)}>
                        {row.name}
                      </a>
                    </TableCell>
                    <TableCell>
                      {row.schemeType === 1 ? 'API User' : row.schemeType === 2 ? 'Admin User' : ''}
                    </TableCell>
                    <TableCell>{row.desc}</TableCell>
                    <TableCell>{row.live}</TableCell>
                    <TableCell>
                      <button
                        className={global.view_btn}
                        onClick={() => handleEdit(row.id)}
                      >
                        View
                      </button>
                      <button
                        className={global.delete_btn}
                      >
                        Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

         

         
         


<div className={ `${global.pageination}`}>
            <div className={global.row}>  
              <div className={global.col_s_12}>    
                     <Pagination 
                              count={filteredData.length || 0}
                              page={page}
                              onChange={handleChangePage}
                              color="primary"
                            
                              sx={{
                                '& .MuiPaginationItem-root.Mui-selected': {
                                  backgroundColor: '#EAF6FF', // Change to your preferred color
                                  borderRadius: '2px',
                                  margin:'0',
                                  border:'solid #0682FF 1px;',
                                  color: '#06226A',
                                },
                                '& .MuiPaginationItem-root:hover': {
                                      backgroundColor: '#EAF6FF', // Change to your preferred color
                                      borderRadius: '0',
                                      border:'none',
                                      color: '#06226A',       // Hover text color (darker blue)
                                  },
                              }}
                            />  

                                </div>
                                </div>
                                </div>
      </Paper>
    </Box>
    <EditSchemeModal
        isOpen={isEditModalOpen}
        onClose={closeModal}
        schemeId={editedSchemeId}
        onSave={(updatedScheme) => {
          console.log("Updated Scheme:", updatedScheme);
          setIsEditModalOpen(false);
        }}
      />
    </div>
  );
};

export default SchemeListPage;
