import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Alert } from '@mui/material';
import ESignAgreement from '../../pages/leads/eSign/ESignAgreement';
import ReactDOMServer from 'react-dom/server';
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';
import Loader from '../../utils/Loader';
import globalcss from '../../assets/scss/global.module.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ESignDialog = ({ open, setIsOpen, id, onClose, passCode, clientData, pageOpenTime, adminData, operatingSystem, browser, ip, setisValid, longitude, latitude, signedBy, signByUser }) => {
    // `otp` state holds an array of 6 digits (each a string).
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [otpError, setOtpError] = useState(false); // Manage OTP error locally

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only one digit (0-9)
        if (value === '' || /^[0-9]{1}$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Automatically move to the next input if a digit is entered
            if (value !== '' && index < otp.length - 1) {
                const nextInput = document.getElementById(`otp-input-${index + 1}`);
                if (nextInput) {
                    nextInput.focus();
                }
            }

            setOtpError(false); // Reset OTP error state on change
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newOtp = [...otp];

            // If the current input is empty, move to the previous input
            if (!newOtp[index] && index > 0) {
                const prevInput = document.getElementById(`otp-input-${index - 1}`);
                newOtp[index - 1] = ''; // Clear the previous input value
                setOtp(newOtp);

                if (prevInput) {
                    prevInput.focus();
                }
            } else {
                // Clear the current input value
                newOtp[index] = '';
                setOtp(newOtp);
            }
        }
    };

    const handleSubmit = async () => {
        const otpString = otp.join(''); // Join the OTP digits into a string

        if (otpString.length !== 6) {
            setOtpError(true);
            return;
        }

        try {

            setLoading(true);
            const data = await fetcher.get(
                `${ApiUrls.VERIFY_PIN}?pin=${otpString}`
            );
            if (data?.status === 200) {
                const jsxString = ReactDOMServer.renderToStaticMarkup(
                    <ESignAgreement
                        clientData={clientData}
                        adminData={adminData}
                        longitude={longitude}
                        latitude={latitude}
                        operatingSystem={operatingSystem}
                        ip={ip}
                        browser={browser}
                        signedBy={signedBy}
                        pageOpenTime={pageOpenTime}
                    />
                );
                const payload = {
                    esignTemplate: jsxString,
                    leadUserId: id
                };
                const saveResponse = await fetcher.post(
                    ApiUrls.LEAD_SAVE_ESIGN_CONTENT, payload
                );
                if (saveResponse?.status === 200) {
                   // alert("eSign template saved successfully.");
                    setisValid(true);
                    setOtp(['', '', '', '', '', '']);
                    setIsOpen(false);
                } else {
                    alert("Failed to save eSign template.");
                }
            } else {
                alert("Sign user verification failed.");
            }
        } catch (error) {
            setError("Error verifying user OTP.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <Box sx={style} style={{ border: "none", borderRadius: "12px" }}>
                <Typography id="modal-modal-title" style={{ textAlign: "center", fontWeight: "700" }} variant="h4" component="h4">
                    Hi, {signByUser}
                </Typography>
                <div style={{ textAlign: "center", fontSize: "14px" }}>
                    Authentication is required to proceed your Signature.
                </div>

                <div style={{ textAlign: "center", fontSize: "14px", fontWeight: "700", marginTop: "16px" }}>
                    Enter PIN
                </div>


                {error && <Alert severity="error">{error}</Alert>}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0 }}>
                    {otp.map((digit, index) => (
                        <TextField
                            key={index}
                            value={digit}
                            id={`otp-input-${index}`}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            inputProps={{
                                maxLength: 1, // Allow only one digit
                                style: { textAlign: 'center' }, // Center the text
                            }}
                            sx={{ width: '40px' }}
                            variant="outlined"
                            type="text"
                        />
                    ))}
                </Box>

                {otpError && (
                    <Typography color="error" sx={{ mt: 1 }}>
                        Please enter a valid 6-digit OTP.
                    </Typography>
                )}

                <button className={`${globalcss.submit_primary_btn} ${globalcss.mar_top_16}`}
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ mt: 2 }}
                    disabled={loading || otp.join('').length !== 6} // Disable button if OTP is incomplete or while loading
                >
                    {loading ? "Submitting..." : "Submit"}
                </button>
            </Box>
        </Modal>
    );
};

export default ESignDialog;
