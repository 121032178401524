import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Headercss from './Header.module.scss';
import '../../../assets/scss/style_header.css';
import companyLogo from '../../../assets/images/tramo-logo.svg'; 
import personimg from '../../../assets/images/man-438081_960_720.png';  
import { Link, useNavigate } from 'react-router-dom'; 
import { ApiUrls, PageUrls } from '../../../utils/constants';
import fetcher from '../../../utils/fetcher';

const Header = () => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [anchorEl5, setAnchorEl5] = useState(null);
  const [anchorEl6, setAnchorEl6] = useState(null);
  const [anchorEl7, setAnchorEl7] = useState(null);
  const [anchorEl8, setAnchorEl8] = useState(null);
  const navigate = useNavigate();

  const handleClickMenu1 = (event) => {
    setAnchorEl1(event.currentTarget); // Open Menu 1
  };

  const handleClickMenu2 = (event) => {
    setAnchorEl2(event.currentTarget); // Open Menu 2
  };

  const handleClickMenu3 = (event) => { 
    setAnchorEl3(event.currentTarget); // Open Menu 2
  };

  const handleClickMenu4 = (event) => {
    setAnchorEl4(event.currentTarget); // Open Menu 2
  };

  const handleClickMenu5 = (event) => {
    setAnchorEl5(event.currentTarget); // Open Menu 2
  };
  
  const handleClickMenu6 = (event) => {
    setAnchorEl6(event.currentTarget); // Open Menu 2
  };

    
  const handleClickMenu7 = (event) => {
    setAnchorEl7(event.currentTarget); // Open Menu 2
  };

  const handleClickMenu8 = (event) => {
    setAnchorEl8(event.currentTarget); // Open Menu 2
  };


  // Close submenu
  const handleClose = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
    setAnchorEl4(null);
    setAnchorEl5(null);
    setAnchorEl6(null);
    setAnchorEl7(null);
    setAnchorEl8(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

//   {
//     "status": 200,
//     "message": "OK",
//     "response": {
//         "id": 1,
//         "mainBalance": 0.00,
//         "availableBalance": 10.00,
//         "gst": 1.44,
//         "tds": 0.00,
//         "isActive": 1,
//         "createdAt": 1731235282,
//         "updatedAt": 1736526195
//     }
// }

const [adminData, setAdminData] = useState([]);
const fetchAdminData = async () => {
  try {
   // setLoading(true);
    const data = await fetcher.get(`${ApiUrls.FETCH_ADMIN_WALLET_DATA}`); // Page as 0-based index

    if (data?.status === 200) {
      setAdminData(data.response);
    }
  } catch (error) {
    console.error("Error fetching leads:", error);
  }
  //setLoading(false);
};
const [userData, setUserData] = useState([]);
const fetchUserData = async () => {
  try {
   // setLoading(true);
    const data = await fetcher.get(`${ApiUrls.FETCH_USER_WALLET_DATA}`); // Page as 0-based index

    if (data?.status === 200) {
      setUserData(data.response);
    }
  } catch (error) {
    console.error("Error fetching leads:", error);
  }
  //setLoading(false);
};
  useEffect(() => {
    fetchAdminData();
   // fetchUserData();
  }, []);
  return (
    <div className="container-fluid">
      <header>
        <div className="row">
          
         <div className="col-sm-2 pt-3 text-left">
            <img src={companyLogo} alt="" />
          </div>

          <div className='col-sm-10'>
            <div className='row'>
          <div className='col-sm-2 pt-3  pb-2 left_border right_border'>
            {/* <ul className={`${Headercss.available_balance}`}>
              <li>Available Balance : ₹10,000</li>
              <li>Main : ₹10,000</li>
            </ul>
          </div>
          <div className='col-sm-2  pt-3 right_border'>
            <ul className={`${Headercss.gst_balance}`}>
              <li>GST : ₹10,000</li>
              <li>TDS : ₹10,000</li>
            </ul>
          </div>
          <div className='col-sm-2  pt-3 right_border'>
            <a href='#' className={Headercss.vender_link}> Vendor Wallet Balance </a>
          </div> */}
{adminData ? (
          <ul className={`${Headercss.available_balance}`}>
            <li>Available Balance : ₹ {adminData.availableBalance}</li>
            <li>Main : ₹ {adminData.mainBalance}</li>
          </ul>
        ) : (
          <ul className={`${Headercss.available_balance}`}>
          <li>Available Balance : ₹ xxxxxx</li>
          <li>Main : ₹ xxxxxx</li>
        </ul>
        )}
          </div>
          <div className='col-sm-2  pt-3 right_border'>
          {adminData ? (
          <ul className={`${Headercss.gst_balance}`}>
            <li>GST : ₹ {adminData.gst}</li>
            <li>TDS : ₹ {adminData.tds}</li>
          </ul>
        ) : (
          <ul className={`${Headercss.gst_balance}`}>
          <li>GST : ₹ xxxxxx</li>
          <li>TDS : ₹ xxxxxx</li>
        </ul>
        )}
          </div>
          <div className='col-sm-2  pt-3 right_border'>
            <a href='#' className={Headercss.vender_link}> Vendor Wallet Balance </a>
          </div>
          
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-12 pt-3">
                <div className="d-flex justify-content-between">
                  <ul className="d-flex notification">
                    <li>
                      <span className={Headercss.admin_top_icon_1}><i className= { `${Headercss.noti_top_icon_1}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                    <li>
                      <span className={Headercss.admin_top_icon_2}><i className= { `${Headercss.noti_top_icon_2}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                    <li>
                      <span className={Headercss.admin_top_icon_3}><i className= { `${Headercss.noti_top_icon_3}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                    <li>
                      <span className={Headercss.admin_top_icon_4}><i className= { `${Headercss.noti_top_icon_4}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                    <li>
                      <span className={Headercss.admin_top_icon_5}><i className= { `${Headercss.noti_top_icon_5}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                    <li>
                      <span className={Headercss.admin_top_icon_6}><i className= { `${Headercss.noti_top_icon_6}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                    <li>
                      <span className={Headercss.admin_top_icon_7}><i className= { `${Headercss.noti_top_icon_7}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                    <li>
                      <span className={Headercss.admin_top_icon_8}><i className= { `${Headercss.noti_top_icon_8}  ${Headercss.noti_common}`}>2</i></span>
                    </li>
                  </ul>

                  <ul className="d-flex login_dropdown">
                    <li>
                      <img src={personimg} className="mr-3" />
                    </li>
                    <li style={{ position: "relative" }}>
                      <Button
                        className={Headercss.navbar_top}
                        color="inherit"
                        endIcon={<ArrowDropDownIcon />}
                        onClick={handleClickMenu1}
                      >
                        <span>Monty Roy </span> <br />
                      </Button>
                      <Menu
                        anchorEl={anchorEl1}
                        open={Boolean(anchorEl1)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleClose}>
                          Forget password
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                          Logout
                        </MenuItem>
                      </Menu>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </header>

   <nav className={Headercss.nav}>
    <ul className={Headercss.menu}>
      <li className={Headercss.menu_item}>
        <Link component={Link} to={PageUrls.DASHBOARD} style={{color:"#fff"}}>
        <i className= { `${Headercss.home_icon} ${Headercss.home_icon_size}`}></i>
         Dashboard </Link></li>
      
      <li className={Headercss.menu_item}>
        <i className= { `${Headercss.our_network_icon} ${Headercss.home_icon_size}`}></i> Our Networks
        <ul className={Headercss.submenu} >

          <li className={Headercss.menu_item}><Link onClick={handleClose} component={Link} to={PageUrls.NEWLEADS}>New Onboarding Requests</Link> </li>
          <li className={Headercss.menu_item}><Link onClick={handleClose}  component={Link} to={PageUrls.LEADS}>Leads</Link> </li>
          <li className={Headercss.menu_item}><Link onClick={handleClose} component={Link} to={PageUrls.ESIGNREQ}>eSign Requests</Link> </li>
          <li className={Headercss.menu_item}><Link onClick={handleClose} component={Link} to={PageUrls.APIUSER}>API users</Link>  </li>

        </ul>
      </li>
      

      <li className={Headercss.menu_item}>
      <i className= { `${Headercss.analytics_icon} ${Headercss.home_icon_size}`}></i> Analytics       
        <ul className={Headercss.submenu} >

           <li className={Headercss.menu_item}>   <Link onClick={handleClose} component={Link} to={PageUrls.FUND_REQUEST_REPORT}>Fund Request Report</Link> </li>
          <li className={Headercss.menu_item}>  <Link onClick={handleClose} component={Link} to={PageUrls.FUND_FLOW_REPORT}>Fund Flow Report</Link> </li>
          <li className={Headercss.menu_item}>  <Link onClick={handleClose} component={Link} to={PageUrls.ACCOUNT_STATEMENT_REPORT}>Account Statement Report</Link> </li>
          <li className={Headercss.menu_item}>  <Link onClick={handleClose} component={Link} to={PageUrls.WALLET_LEDGER}>Wallet Ledger</Link> </li>
          <li className={Headercss.menu_item}> <Link onClick={handleClose} component={Link} to={PageUrls.RECOVERY_REPORT}>Recovery Report</Link> </li>
           
        </ul>
      </li>



    
      <li className={Headercss.menu_item}>
      <i className= { `${Headercss.reconciliation_icon} ${Headercss.home_icon_size}`}></i>  Reconciliation 
        <ul className={Headercss.submenu} >

           <li className={Headercss.menu_item}>   <Link onClick={handleClose} component={Link} to={PageUrls.FUND_REQUEST_REPORT}>Reconciliation</Link> </li>
           
        </ul>
      </li>

      <li className={Headercss.menu_item}>
      <i className= { `${Headercss.funds_icon} ${Headercss.home_icon_size}`}></i>  Funds 
        <ul className={Headercss.submenu} >
             <li className={Headercss.menu_item}>   <Link onClick={handleClose}  component={Link} to={PageUrls.ADD_NEW_BANK}>Add New Bank </Link> </li>
             <li className={Headercss.menu_item}>    <Link onClick={handleClose} component={Link} to= {PageUrls.FUND_FLOW}>Admin Fund Flow</Link> </li>
            <li className={Headercss.menu_item}>    <Link onClick={handleClose} component={Link} to={PageUrls.FUND_REQUEST}>Fund Request</Link> </li>
            <li className={Headercss.menu_item}>     <Link onClick={handleClose} component={Link} to={PageUrls.LIEN}>Lien</Link> </li>
        </ul>
      </li>

      <li className={Headercss.menu_item}>
       <i className= { `${Headercss.controls_icon} ${Headercss.home_icon_size}`}></i> Controls 
        <ul className={Headercss.submenu} >
         
            <li className={Headercss.menu_item}>   <Link onClick={handleClose}  component={Link}>BBPS Management</Link> </li>
            <li className={Headercss.menu_item}>    <Link onClick={handleClose} component={Link} to={PageUrls.SCHEMELIST}>Scheme Management</Link></li>
            <li className={Headercss.menu_item}>   <Link onClick={handleClose} component={Link} to={PageUrls.PROVIDER_LIST}>Provider Management</Link></li>
            <li className={Headercss.menu_item}>   <Link onClick={handleClose} component={Link}>Content Management</Link></li>

        </ul>
      </li>

      <li className={Headercss.menu_item}>
     <i className= { `${Headercss.tools_icon} ${Headercss.home_icon_size}`}></i>   Tools 
        <ul className={Headercss.submenu} >
         
            <li className={Headercss.menu_item}>   <Link onClick={handleClose}  component={Link}>Tools</Link> </li>
        </ul>
      </li>
      <li className={Headercss.menu_item}>
       <i className= { `${Headercss.api_icon} ${Headercss.home_icon_size}`}></i> API 
        <ul className={Headercss.submenu} >
        <li className={Headercss.menu_item}>  <Link onClick={handleClose} component={Link} to={PageUrls.ADD_PROVIDER}>API</Link> </li>
        <li className={Headercss.menu_item}>  <Link onClick={handleClose} component={Link} to={PageUrls.Developer_Zone}>DEV ZONE</Link>  </li>
        </ul>
      </li>
      

    </ul>
  </nav>
 
    </div>
  );
};

export default Header;