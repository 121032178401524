import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const MyWishlistedIP = () => {
  const [productionIP, setProductionIP] = useState("");
  const [uatIP, setUatIP] = useState("");
  const [isEditingProduction, setIsEditingProduction] = useState(false);
  const [isEditingUat, setIsEditingUat] = useState(false);

  const handleEditProduction = () => {
    setIsEditingProduction((prev) => !prev);
  };

  const handleEditUat = () => {
    setIsEditingUat((prev) => !prev);
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    console.log("Production IP:", productionIP);
    console.log("UAT IP:", uatIP);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: 4,
        borderRadius: 2,
        color: "#06226A",
      }}
    >
      <Grid container spacing={3}>
        {/* Production IP Field */}
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom style={{
            fontWeight:"600"
          }}>
            Production IP
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              placeholder="Enter Production IP"
              value={productionIP}
              onChange={(e) => setProductionIP(e.target.value)}
              disabled={!isEditingProduction}
              fullWidth
              InputProps={{
                style: { backgroundColor: "#ffffff", borderRadius: 4 },
              }}
            />
            <IconButton
              onClick={handleEditProduction}
              sx={{ marginLeft: 1, color: "#06226A" }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Grid>

        {/* UAT IP Field */}
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom style={{
            fontWeight: "600"
          }}>
            UAT IP
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              placeholder="Enter UAT IP"
              value={uatIP}
              onChange={(e) => setUatIP(e.target.value)}
              disabled={!isEditingUat}
              fullWidth
              InputProps={{
                style: { backgroundColor: "#FFFFFF", borderRadius: 4 },
              }}
            />
            <IconButton
              onClick={handleEditUat}
              sx={{ marginLeft: 1, color: "#06226A" }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#06226A",
                "&:hover": {
                  backgroundColor: "#06226A",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyWishlistedIP;
