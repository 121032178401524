import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const APIDocuments = () => {
  const [callbackURLs, setCallbackURLs] = useState({
    manyTransfer: "",
    bbps: "",
    recharge: "",
    payout: "",
    transfer: "",
    dmt1: "",
    aeps: "",
  });

  const [editStates, setEditStates] = useState({
    manyTransfer: false,
    bbps: false,
    recharge: false,
    payout: false,
    transfer: false,
    dmt1: false,
    aeps: false,
  });

  const handleEdit = (key) => {
    setEditStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleInputChange = (key, value) => {
    setCallbackURLs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    console.log("Callback URLs:", callbackURLs);
  };

  const renderCallbackField = (label, key) => (
    <Grid item xs={12} md={4}>
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 400, color: "#212529" }}>
        {label}
      </Typography>
      <Box display="flex" alignItems="center">
        <TextField
          variant="outlined"
          placeholder={`Enter ${label}`}
          value={callbackURLs[key]}
          onChange={(e) => handleInputChange(key, e.target.value)}
          disabled={!editStates[key]}
          fullWidth
          InputProps={{
            style: { backgroundColor: "#FFFFFF", borderRadius: 4 },
          }}
        />
        <IconButton
          onClick={() => handleEdit(key)}
          sx={{ marginLeft: 1, color: "#06226A" }}
        >
          <EditIcon />
        </IconButton>
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: 4,
        borderRadius: 2,
        color: "#06226A",
      }}
    >
      
      <Grid container spacing={3} sx={{ fontWeight: 600 }}>
        {renderCallbackField("Many Transfer Callback URL", "manyTransfer")}
        {renderCallbackField("BBPS Callback URL", "bbps")}
        {renderCallbackField("Recharge Callback URL", "recharge")}
        {renderCallbackField("Payout Callback URL", "payout")}
        {renderCallbackField("Transfer Callback URL", "transfer")}
        {renderCallbackField("DMT1 Callback URL", "dmt1")}
        {renderCallbackField("AEPS Callback URL", "aeps")}
      </Grid>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#06226A",
            "&:hover": {
              backgroundColor: "#06226A",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default APIDocuments;
