import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Checkbox,
  Button,
} from "@mui/material";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import globalcss from '../../assets/scss/global.module.scss'

const AddServices = ({userData}) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [loadingSubcategory, setLoadingSubcategory] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedTransitionType, setSelectedTransitionType] = useState("");
  const [selectedCommission, setSelectedCommission] = useState("");
  const [selectedCharges, setSelectedCharges] = useState("");
  const [categoryKeys, setCategoryKeys] = useState(["charge", "commission"]); 
  const [schemeName, setSchemeName] = useState("");
  const [schemeDescription, setSchemeDescription] = useState("");
  const [payloadService, setPayloadService] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [addedSchemes, setAddedSchemes] = useState([]);

  const [isVisible, setIsVisible] = useState(false);
  const [schemeNameError, setSchemeNameError] = useState("");
  const [schemeDescriptionError, setSchemeDescriptionError] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetcher.get(ApiUrls.FETCH_CATEGORY);
        if (response?.status === 200 && response.response) {
          setCategories(response.response);
        } else {
          console.error("Failed to fetch categories:", response.message);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const fetchSubcategories = async (catid) => {
    setLoadingSubcategory(true);
    try {
      const response = await fetcher.get(ApiUrls.FETCH_SUB_CATEGORY, { catid });
      if (response?.status === 200 && response.response) {
        setSubcategories((prev) => ({
          ...prev,
          [catid]: response.response,
        }));
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoadingSubcategory(false);
    }
  };

  const fetchServices = async (subcatId) => {
    try {
      const response = await fetcher.get(ApiUrls.FETCH_SERVICES, { subcatid: subcatId });
      if (response?.status === 200 && response.response) {
        setServices(response.response);
        setPayloadService(generatePayload(response.response));
      } else {
        console.error("Failed to fetch services:", response.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleCategoryChange = (event) => {
    const selectedCatId = event.target.value;
    setSelectedCategory(selectedCatId);

    if (!subcategories[selectedCatId]) {
      fetchSubcategories(selectedCatId);
    }
  };

  const handleSave = async () => {
    if (!schemeName) {
      setSchemeNameError("Scheme Name is required");
      return;
    } else {
      setSchemeNameError("");
    }
  
    if (!schemeDescription) {
      setSchemeDescriptionError("Scheme Description is required");
      return;
    } else {
      setSchemeDescriptionError("");
    }
  
    const data = payloadService?.data?.filter(
      (service) => service.isChecked === true
    );
  
    const payload = {
      name: schemeName,
      desc: schemeDescription,
      appliedUserId: userData.providerUserId || 1,
      schedeleAt: 1731235282,
      cat: selectedCategory,
      subcat: selectedSubcategory,
      schemeType: 2,
      data,
    };

    console.log("Final Payload:", payload);

    const verifyRes = await fetcher.post(ApiUrls.PROVIDER_SAVE_SCHEME, payload);
    if (verifyRes?.status === 200) {
      console.log("Final Payload SAVED", payload);
      fetchProviderScheme(currentPage)
    } else {
      console.log("Final Payload Failed", payload);
    }
  };

  
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const generatePayload = (inputArray) => {
   

    return {
      data: inputArray.map((item) => ({
        serviceid: item.id,
        serviceName: item.schemeServiceName,
        operatorName: item.schemeServiceOperatorName,
        operatorImage: item.schemeServiceOperatorLogoUrl,
        isChecked: false,
        rows: [
          {
            minslab: "0",
            maxslab: "10",
            commdata: categoryKeys.map((key) => {
              if (key === "charge" ) {
                return {
                  key: key,
                  tds: "1",    
                  type: "1",   
                  value: "0", 
                };
              } else if( key === "commission"){
                return {
                  key: key, 
                  tds: "1",    
                  type: "1",   
                  value: "0", 
                };
              }else {
                return {
                  key: key,
                  tds: "1",
                  type: "1",   
                  value: "0",  
                };
              }
            }),
          },
        ],
      })),
    };
  };
  const handleRowChange = (e, serviceId, field, cell,rowIndex) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId && service.isChecked === true) {
        // Update rows correctly
        const updatedRows = service.rows.map((row,index) => {
          if(rowIndex === index){
            const updatedCommdata = row.commdata.map((commdata) =>
              commdata.key === cell
                ? { ...commdata, [field]: e.target.value }
                : commdata
            );
            return { ...row, commdata: updatedCommdata }; // Update the commdata in the row
          }else{
            return {...row}
          }
          
        });

        return {
          ...service,
          rows: updatedRows, // Replace the rows with updated rows
        };
      }
      return service; // Return unchanged service for others
    });

    setPayloadService({ data: updatedServices });
  };
  const handleRowMinMaxChange = (e, serviceId, field,rowIndex) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId && service.isChecked === true) {
        const updatedRows = service.rows.map((row,index) => {
          if(rowIndex === index){
            return { ...row, [field]: e.target.value };
          }else{
            return { ...row}
          }
        });

        return {
          ...service,
          rows: updatedRows,
        };
     
      }
      return service;
    });

    setPayloadService({ data: updatedServices });
  };
  
  
  const handleCheckboxChange = (serviceId) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId) {
        return {
          ...service,
          isChecked: !service.isChecked, // Toggle isChecked
        };
      }
      return service; // Return unchanged service for others
    });

    setPayloadService({ data: updatedServices });
  };

  useEffect(() => {
    if (selectedCategory && selectedSubcategory) {
      fetchServices(selectedSubcategory);
    }
     if(userData){
      fetchProviderScheme(currentPage);
    }
  }, [selectedCategory, selectedSubcategory,currentPage]);

  const fetchProviderScheme = async (page) => {
    setLoadingSubcategory(true);
    try {
      const url = `${ApiUrls.FETCH_APPLIED_SCHEME}?page=${page - 1}&appliedUserId=${userData.providerUserId}&userType=2`;
      const response = await fetcher.get(url);
      if (response?.status === 200 && response.response) {
        setCurrentPage(page);
        setAddedSchemes(response.response);
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoadingSubcategory(false);
    }
  };


  
  const DynamicTable = ({ data }) => {
    return (
      <div className="container-fluid" style={{marginTop:"24px", overflowX:"scroll"}}>
      <table style={{ width: "2000px", borderCollapse: "collapse", border: "1px solid #D6D9E1" }}>
        <thead>
          <tr>
          <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}></th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>ID</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Operator Name</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Service Name</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Min Default</th>
            <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Max Default</th>
            {data[0]?.rows[0]?.commdata.map((comm, index) => (
              <th
                key={`group-header-${index}`}
                colSpan={comm.key === "charge" ? 3 : 3}
                style={{ border: "1px solid #D6D9E1", textAlign: "center", padding: "10px", backgroundColor: "#F9F7C8" }}
              >
                {comm.key.toUpperCase()}
              </th>
            ))}
          </tr>
          <tr>
            {data[0]?.rows[0]?.commdata.map((item, index) => (
              item.key === "charge"  ? (
                <>
                  <th key={`sub-header-gst-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>GST</th>
                  <th key={`sub-header-type-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Type</th>
                  <th key={`sub-header-value-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Value</th>
                </>
              ) : (
                <>
                <th key={`sub-header-tds-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>TDS</th>
                  <th key={`sub-header-type-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Type</th>
                  <th key={`sub-header-value-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Value</th>
                </>
              )
            ))}
          </tr>
        </thead>
        <tbody>

    
          {data.map((service,dataIndex) =>
            service.rows.map((row, rowIndex) => (
              <tr key={`${service.serviceid}-${rowIndex}`}>
                {rowIndex === 0 ? (
                  <>
                   <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      <Checkbox      checked={service.isChecked}
                       onChange={() => handleCheckboxChange(service.serviceid)}/>
                    </td>
                    <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      {service.serviceid}
                    </td>
                    <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                    {service.operatorName}
                    </td>
                    <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                    

                    <Box> <img src={service.operatorImage}  alt={`${service.operatorName} Logo`}  style={{ maxWidth: "35px",  maxHeight: "35px",  marginBottom: "8px",  }}/>
                          <Typography variant="body2"> {service.serviceName} </Typography>
                    </Box>  
                    </td>
                  </>
                ) : null}
                <td style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                <TextField  key={`minslab-${service.serviceid}-${rowIndex}`} style={{width:"100px;"}} type="text" value={row.minslab} onChange={(e) =>  handleRowMinMaxChange(e, service.serviceid, "minslab",rowIndex)  }/>
                </td>
                <td  className={global.plus_container} style={{ border: "1px solid #D6D9E1", padding: "10px", position:"relative" }}>
                 
                  <TextField key={`maxslab-${service.serviceid}-${rowIndex}`} className={global.form_control} type="text"  value= {row.maxslab}  onChange={(e) =>  handleRowMinMaxChange(e, service.serviceid, "maxslab",rowIndex)  }  />
                 
                 <span style={{position:"absolute", right:"-30px", top:"35px", width:"60px"}}>
                   <button onClick={() => removeRow(service.serviceid, rowIndex)} style={{ marginRight: "10px", width:"20px", height:"20px", lineHeight:"16px", background:"#ccc", color:"#000", marginBottom:"5px"}}> - </button><br/>
                   <button onClick={() => addRow(service.serviceid)} style={{  width:"20px", height:"20px", lineHeight:"16px", background:"#ccc", color:"#000"}}>+</button>
                </span>
                </td>
                {row.commdata.map((comm, commIndex) => (
                 comm.key === "charge"  ? ( <>
                    
                    <td key={`gst-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
              
                      <TableCell>
                                  <div> <select   key={`gst-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`}
                                              value={comm.tds || ""}     className={globalcss.form_control}  id="tds"  onChange={(e) => handleRowChange(  e,  service.serviceid,  "tds",  comm.key ,rowIndex )  }  >
                                      <option value="1">  Yes  </option>
                                      <option value="2">  No  </option>
                                    </select>
                                  </div>
                                </TableCell>
                    </td>
                    <td key={`type-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      
                      <TableCell>
                                  {" "}
                                  <select className={globalcss.form_control}  key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} 
                                  value={comm.type || ""}   id="type"  onChange={(e) => handleRowChange(  e,  service.serviceid,  "type",  comm.key  ,rowIndex ) }  >
                                    <option value="1">₹</option>
                                    <option value="2">%</option>
                                  </select>
                                </TableCell>
                    </td>
                    <td key={`value-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                   
                      <TableCell>
                                  <TextField  key={`value-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} id="value"  type="text" 
                                  value={comm.value || ""} onChange={(e) => handleRowChange(  e,  service.serviceid,  "value", comm.key ,rowIndex )  }  />
                                </TableCell>
                    </td>
                  </>
                  ) : (
                    <>
                    <td key={`tds-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
              
              <TableCell>
                          <div> <select  key={`tds-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`}
                                      value={comm.tds || ""}     className={globalcss.form_control}  id="tds"  onChange={(e) => handleRowChange(  e,  service.serviceid,  "tds",  comm.key  ,rowIndex ) }  >
                              <option value="1">  Yes  </option>
                              <option value="2">  No  </option>
                            </select>
                          </div>
                        </TableCell>
            </td>
                      <td key={`charge-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                     
                      <TableCell>
                                  {" "}
                                  <select key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} className={globalcss.form_control}  id="type" 
                                  value={comm.type || ""} onChange={(e) => handleRowChange(  e,  service.serviceid,  "type",  comm.key  ,rowIndex)  }  >
                                   <option value="1">₹</option>
                                   <option value="2">%</option>
                                  </select>
                                </TableCell>
                    </td>
                    <td key={`value-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                 
                      <TableCell>
                                  <TextField key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} id="value"  type="text"  
                                  value={comm.value || ""}  onChange={(e) =>   handleRowChange(  e,  service.serviceid,   "value",  comm.key  ,rowIndex)  }  />
                                </TableCell>
                    </td>
                    </>
                  )
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      </div>
    );
  };
  const addRow = (serviceId) => {
    setPayloadService((prevPayload) => {
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: [...item.rows,
                  {
                    minslab: "0",
                    maxslab: "10",
                    commdata: categoryKeys.map((key) => {
                      if (key === "charge" ) {
                        return {
                          key: key,
                          tds: "1",    
                          type: "1",   
                          value: "0", 
                        };
                      } else if( key === "commission"){
                        return {
                          key: key, 
                          tds: "1",    
                          type: "1",   
                          value: "0", 
                        };
                      }else {
                        return {
                          key: key,
                          tds: "1",
                          type: "1",   
                          value: "0",  
                        };
                      }
                    }),
                  },
                ],
              }
            : item
        ),
      };
    });
  };

  const removeRow = (serviceId, rowIndex) => {
    setPayloadService((prevPayload) => {
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: item.rows.filter((_, index) => index !== rowIndex), // Remove the specific row by index
              }
            : item
        ),
      };
    });
  }; 



 
  return (

    <>
    
  



    <div className={globalcss.wrapper_form}>

   
        <h3 className={ `${globalcss.d_flex} ${globalcss.d_flex_l_r}`}>
         
         <span> Add New Scheme</span>

          <button className={globalcss.showhide_scheme} onClick={toggleVisibility}>
        {isVisible ? 'Hide Scheme ' : 'Show Scheme '} 
      </button>

          </h3>

          {isVisible && (
            addedSchemes.length > 0 && (
      <table border="1" borderColor="#f7f7f7" cellpadding="8" style={{ marginTop:"24px", width: "100%", textAlign: "left", borderCollapse:"collapse" }}>
        <thead>
          <tr style={{background:"#EAF6FF"}}>

             <th>Date of creation</th>
            <th>Scheme Name & ID</th>
            <th>Description</th>
            <th>Action</th>
           
          </tr>
        </thead>
        <tbody>
          {addedSchemes.map((scheme) => (
            <tr key={scheme.id}>
              <td>{new Date(scheme.schedeleAt * 1000).toLocaleString()}</td>
              <td>{scheme.name}</td>
              <td>{scheme.desc}</td>
              <td><a href="" className={globalcss.active_services}>Active</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  
)}

        <div className={globalcss.row} style={{marginTop: "24px"}}>
          <div className={globalcss.col_s_12}>
          <div className={globalcss.form_group}>
          <label>Scheme Name</label>
        <input type="text" className={globalcss.form_control}
          placeholder="Scheme Name"
          value={schemeName}
          onChange={(e) => setSchemeName(e.target.value)}
          margin="normal"
        />
        {schemeNameError && <span style={{ color: "red" }}>{schemeNameError}</span>}
        </div>
        </div>
        </div>

        <div className={globalcss.row}>
        <div className={globalcss.col_s_12}>
          <div className={globalcss.form_group}>

            <label>Scheme Description</label>
          <textarea className={globalcss.form_control}
          value={schemeDescription}
          onChange={(e) => setSchemeDescription(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        {schemeDescriptionError && <span style={{ color: "red" }}>{schemeDescriptionError}</span>}
            </div>
          </div>
          </div>
   

          <div className={globalcss.row}>
            <div className={globalcss.col_s_4}>
              <div className={globalcss.form_group}>

                <label>Select a Category</label>
              <select className={globalcss.form_control}
           
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="">
              Select a Category
            </option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.schemeCategoryName}
              </option>
            ))}
          </select>
              </div>
              </div>


              <div className={globalcss.col_s_4}>
              <div className={globalcss.form_group}>

                <label>Select a Subcategory</label>
                <select className={globalcss.form_control}
          
            value={selectedSubcategory}
            onChange={(e) => setSelectedSubcategory(e.target.value)}
          >
            <option value="">
            
            </option>
            {subcategories[selectedCategory]?.map((subcat) => (
              <option key={subcat.id} value={subcat.id}>
                {subcat.schemeSubCategoryName}
              </option>
            ))}
          </select>
              </div>
              </div>


              <div className={globalcss.col_s_4}>
              <div className={globalcss.form_group}>

                <label>Transaction Type</label>
                <select className={globalcss.form_control}
           value={selectedTransitionType}
            onChange={(e) => setSelectedTransitionType(e.target.value)}
  
    >
      <option value="">
        Select Charges 
      </option>
      <option value="instantly">Instantly</option>
      <option value="daily">Daily</option>
      <option value="monthly">Monthly</option>
    </select>
                </div>
                </div>



          </div>

       
          <div className={globalcss.row}>
            <div className={globalcss.col_s_4}>
              <div className={globalcss.form_group}>
                <label>Select a Service</label>
                <select  className={globalcss.form_control}
        labelId="service-label"
        value={selectedService}
        onChange={(e) => setSelectedService(e.target.value)}
        
      >
        <option value="">
          Select a Service
        </option>
        {services.map((service) => (
          <option key={service.id} value={service.id}>
            {service.name}
          </option>
        ))}
      </select>
               </div>
               </div>

               <div className={globalcss.col_s_4}>
              <div className={globalcss.form_group}>
                <label>Select Commission</label>
                <select className={globalcss.form_control}
        
        value={selectedCommission}
        onChange={(e) => setSelectedCommission(e.target.value)}
       
      >
        <option value="">
           Select Commission 
        </option>
        <option value="instantly">Instantly</option>
        <option value="daily">Daily</option>
        <option value="monthly">Monthly</option>
      </select>
                </div>
                </div>


                <div className={globalcss.col_s_4}>
              <div className={globalcss.form_group}>
                <label>Select Commission</label>
                <select className={globalcss.form_control}
       
        value={selectedCharges}
        onChange={(e) => setSelectedCharges(e.target.value)}
      
      >
        <option value="">
          <em>Select Charges</em>
        </option>
        <option value="instantly">Instantly</option>
        <option value="daily">Daily</option>
        <option value="monthly">Monthly</option>
      </select>
                </div>
                </div>

            </div> 
            {payloadService?.data?.length && (
            <DynamicTable data={payloadService.data} />
          )}
      <div className={globalcss.row} style={{marginTop: "24px"}}> 
      <div className={globalcss.col_s_3}>
        <button type="submit"  onClick={handleSave}  className={globalcss.submit_primary_btn}>Save</button>
      </div>
      </div>
    </div>
    </>
  );
};

export default AddServices;
