import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import global from '../../assets/scss/global.module.scss';
import { Edit } from '@mui/icons-material';


const UserForm = () => {
    const [participants, setParticipants] = useState({
        Operation: [],
        Finance: [],
        IT: [],
        Legal: [],
    });

    const [activeDepartment, setActiveDepartment] = useState(null); // Tracks which department's form is open

    const participantSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string()
            .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
            .required('Phone number is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
        },
        validationSchema: participantSchema,
        onSubmit: (values, { resetForm }) => {
            setParticipants((prev) => ({
                ...prev,
                [activeDepartment]: [...prev[activeDepartment], values],
            }));
            resetForm();
            setActiveDepartment(null); // Close the form after submission
        },
    });

    return (
        <div className={global.wrapper_form}>
            <h3 className={global.mar_bottom_20}>Department Participants</h3>

            {Object.keys(participants).map((dept) => (
                <div key={dept} >
                    <div className={ `${global.row} ${global.mar_top_16}`}>
                        <div className={global.col_s_10}>{dept} Department</div>
                        <div className={global.col_s_2} style={{ textAlign: 'right' }}>
                            <button
                                className={global.submit_primary_btn}
                                onClick={() => setActiveDepartment(dept)}
                            >
                                Add 
                            </button>
                        </div>
                    </div>

                    {participants[dept].length > 0 ? (
                        <table className={global.table} cellpadding="0" cellspacing="0" border="1" style={{borderCollapse:"collapse"}}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {participants[dept].map((participant, index) => (
                                    <tr key={index}>
                                        <td>{participant.name}</td>
                                        <td>{participant.email}</td>
                                        <td>{participant.phone}</td>
                                        <td>
                                            <Edit/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                     <></>
                    )}

                    {activeDepartment === dept && (
                        <form onSubmit={formik.handleSubmit} className={global.mar_top_20}>
                            <div className={`${global.row} ${global.mar_top_16}`}>
                                <div className={global.col_s_4}>
                                    <div className={global.form_group}>
                                        <label>Person Name</label>
                                        <input
                                            className={global.form_control}
                                            placeholder="Enter Name"
                                            type="text"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.name && (
                                            <div className={`${global.error_message} ${global.text_right}`}>
                                                {formik.errors.name}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={global.col_s_4}>
                                    <div className={global.form_group}>
                                        <label>Person E-mail</label>
                                        <input
                                            className={global.form_control}
                                            placeholder="Enter E-mail"
                                            type="text"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.email && (
                                            <div className={`${global.error_message} ${global.text_right}`}>
                                                {formik.errors.email}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={global.col_s_4}>
                                    <div className={global.form_group}>
                                        <label>Person Phone</label>
                                        <input
                                            className={global.form_control}
                                            placeholder="Phone Number"
                                            type="tel"
                                            name="phone"
                                            value={formik.values.phone}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, '');
                                                formik.setFieldValue('phone', value);
                                            }}
                                        />
                                        {formik.errors.phone && (
                                            <div className={`${global.error_message} ${global.text_right}`}>
                                                {formik.errors.phone}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={global.row}>
                            <div className={`${global.col_s_6} ${global.mar_top_28}`}>

<input
    type='submit'
    className={`${global.submit_primary_btn} ${global.display_inline_blk}`}
    value="Confirm"
/>
</div>
                            </div>
                        </form>
                    )}
                </div>
            ))}
        </div>
    );
};

export default UserForm;
