import React, { useState } from 'react';
import global from '../../assets/scss/global.module.scss';

const UserBankDetails = () => {
  const [accounts, setAccounts] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);
  const [newAccount, setNewAccount] = useState({
    bankName: '',
    accountNumber: '',
    ifsc: '',
    branchName: '',
    accountType: 'Saving', // Default value
    accountHolderName: '',
  });

  const toggleForm = () => setFormVisible(!formVisible);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAccount((prev) => ({ ...prev, [name]: value }));
  };

  const handleFetch = () => {
    if (!newAccount.bankName || !newAccount.accountNumber || !newAccount.ifsc) {
      alert('Please fill in the basic details first.');
      return;
    }
    setAdditionalFieldsVisible(true);
  };

  const handleConfirm = () => {
    const { bankName, accountNumber, ifsc, branchName, accountType, accountHolderName } = newAccount;

    if (!bankName || !accountNumber || !ifsc || !branchName || !accountType || !accountHolderName) {
      alert('All fields are required.');
      return;
    }

    setAccounts((prev) => [...prev, newAccount]);
    setNewAccount({
      bankName: '',
      accountNumber: '',
      ifsc: '',
      branchName: '',
      accountType: 'Saving',
      accountHolderName: '',
    });
    setFormVisible(false);
    setAdditionalFieldsVisible(false);
  };

  return (
    <div className={global.wrapper_form}>
      <div className={global.row}>
        <div className={global.col_s_9}>
            <h3>Add Bank Account</h3>
        </div>
        <div className={global.col_s_3} >
        <button onClick={toggleForm} className={global.submit_primary_btn}>
          {formVisible ? 'Close Form' : 'Add'}
        </button>
        </div>
      </div>

      {formVisible && (
        <div style={{ marginBottom: '20px' }}>
          {/* Basic Fields */}

          <div className={`${global.row} ${global.mar_top_24}`}>
            <div className={global.col_s_3}>
          <input
            type="text"
            name="bankName"
            placeholder="Bank Name"
            value={newAccount.bankName}
            onChange={handleChange}
            className={global.form_control}
          />
          </div>
          <div className={global.col_s_3}>
          <input
            type="text"
            name="accountNumber"
            placeholder="Account Number"
            value={newAccount.accountNumber}
            onChange={handleChange}
            className={global.form_control}
          />
          </div>
          <div className={global.col_s_3}>
          <input
            type="text"
            name="ifsc"
            placeholder="IFSC Code"
            value={newAccount.ifsc}
            onChange={handleChange}
            className={global.form_control}
          />
          </div>
          <div className={global.col_s_3}>
          <button onClick={handleFetch} className={global.submit_primary_btn}>
            Fetch
          </button>
          </div>
          </div>

          {/* Additional Fields */}
          {additionalFieldsVisible && (
            <>
              <input
                type="text"
                name="branchName"
                placeholder="Branch Name"
                value={newAccount.branchName}
                onChange={handleChange}
                style={{ marginRight: '10px', padding: '5px' }}
              />
              <select
                name="accountType"
                value={newAccount.accountType}
                onChange={handleChange}
                style={{ marginRight: '10px', padding: '5px' }}
              >
                <option value="Saving">Saving</option>
                <option value="Current">Current</option>
              </select>
              <input
                type="text"
                name="accountHolderName"
                placeholder="Account Holder Name"
                value={newAccount.accountHolderName}
                onChange={handleChange}
                style={{ marginRight: '10px', padding: '5px' }}
              />
              <button onClick={handleConfirm} style={{ padding: '5px 10px' }}>
                Confirm
              </button>
            </>
          )}
        </div>
      )}

      <table className={global.table}>
        <thead>
          <tr>
            <th style={{ padding: '10px' }}>Bank Name</th>
            <th style={{ padding: '10px' }}>Account Number</th>
            <th style={{ padding: '10px' }}>IFSC Code</th>
            <th style={{ padding: '10px' }}>Branch Name</th>
            <th style={{ padding: '10px' }}>Account Type</th>
            <th style={{ padding: '10px' }}>Account Holder Name</th>
          </tr>
        </thead>
        <tbody>
          {accounts.length === 0 ? (
            <tr>
              <td colSpan="6" style={{ padding: '10px', textAlign: 'center' }}>
                <div className={global.info_message}>No accounts added yet.</div>
              </td>
            </tr>
          ) : (
            accounts.map((account, index) => (
              <tr key={index}>
                <td style={{ padding: '10px' }}>{account.bankName}</td>
                <td style={{ padding: '10px' }}>{account.accountNumber}</td>
                <td style={{ padding: '10px' }}>{account.ifsc}</td>
                <td style={{ padding: '10px' }}>{account.branchName}</td>
                <td style={{ padding: '10px' }}>{account.accountType}</td>
                <td style={{ padding: '10px' }}>{account.accountHolderName}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserBankDetails;
