import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import global from "../../assets/scss/global.module.scss"; // Assuming global CSS module for pagination styling

// Dummy Data for the table
// Updated Dummy Data with Status
const dummyData = [
    {
      dateTime: "2024-12-15 10:30 AM",
      transactionId: "TXN123456",
      entityName: "ABC Pvt. Ltd.",
      bank: "HDFC",
      amount: "50000",
      amountWords: "Fifty Thousand",
      transferDate: "2024-12-14",
      branch: "Mumbai",
      charge: "500",
      utr: "UTR123456789",
      depositSlip: "Slip123",
      remark: "Approved",
      status: "Approved", 
      executesRemark: "Verified by Manager",
    },
    {
      dateTime: "2024-12-16 11:00 AM",
      transactionId: "TXN987654",
      entityName: "XYZ Pvt. Ltd.",
      bank: "SBI",
      amount: "75000",
      amountWords: "Seventy Five Thousand",
      transferDate: "2024-12-15",
      branch: "Delhi",
      charge: "750",
      utr: "UTR987654321",
      depositSlip: "Slip987",
      remark: "Pending",
      status: "Pending", 
      executesRemark: "Awaiting Approval",
    },
    {
      dateTime: "2024-12-17 09:45 AM",
      transactionId: "TXN456789",
      entityName: "LMN Pvt. Ltd.",
      bank: "ICICI",
      amount: "120000",
      amountWords: "One Lakh Twenty Thousand",
      transferDate: "2024-12-16",
      branch: "Bangalore",
      charge: "1200",
      utr: "UTR456123789",
      depositSlip: "Slip456",
      remark: "Rejected",
      status: "Rejected", // Added status
      executesRemark: "Insufficient Balance",
    },
  ];

  

// Dummy Data for Dropdowns
const banks = ["HDFC", "SBI", "ICICI", "AXIS"];
const searchByOptions = ["Transaction ID", "Entity Name", "UTR"];

const FundRequestReport = () => {
  // States for filtering
  const [filter, setFilter] = useState({
    searchBy: "",
    searchText: "",
    bank: "",
  });

  const statusOptions = ["Approved", "Pending", "Rejected"];

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Total pages
  const totalPages = Math.ceil(dummyData.length / rowsPerPage);

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  // Handle search
  const handleSearch = () => {
    console.log("Searching with filters:", filter);
    // Add your filter logic here
  };

  // Handle clearing filters
  const handleClear = () => {
    setFilter({ searchBy: "", searchText: "", bank: "" });
  };

  return (
    <div className= { `${global.gray_bg} ${global.pad_16} `}>

<div class="container-fluid">
      <div className={ `${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
         <Typography variant="h1"  gutterBottom > Fund Request Report</Typography>
      </div>

      <div className= { `${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16}   `} >
     
  <TextField
   style={{marginRight:"5px"}}
    select
    label="Search By"
    name="searchBy"
    value={filter.searchBy}
    onChange={handleFilterChange}
    variant="outlined"
     
    sx={{ width: "200px" }} // Increased width
  >
    {searchByOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </TextField>
  <TextField
   style={{marginRight:"5px"}}
    label="Enter Value"
    name="searchText"
    value={filter.searchText}
    onChange={handleFilterChange}
    variant="outlined"
    
    sx={{ width: "200px" }} // Increased width
  />
  <TextField
   style={{marginRight:"5px"}}
    select
    label="Search by Bank"
    name="bank"
    value={filter.bank}
    onChange={handleFilterChange}
    variant="outlined"
    
    sx={{ width: "200px" }} // Increased width
  >
    {banks.map((bank) => (
      <MenuItem key={bank} value={bank}>
        {bank}
      </MenuItem>
    ))}
  </TextField>
  <TextField
   style={{marginRight:"5px"}}
    select
    label="Status"
    name="status"
    value={filter.status || ""}
    onChange={handleFilterChange}
    variant="outlined"
    
    sx={{ width: "200px" }} // Adjust width as needed
  >
    {["Approved", "Pending", "Rejected"].map((status) => (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    ))}
  </TextField>
 

  <button className={ `${global.clear_btn} ${global.lead_common_btn} `} onClick={handleClear}>Clear</button>
  <button className={ `${global.submit_btn_1} ${global.lead_common_btn} `}  onClick={handleSearch}> Search <i className={global.lead_search_icon}></i></button>
  <button className={ `${global.download_btn_1} ${global.lead_common_btn} `} onClick={() => console.log("Download clicked")}>Download <i className={global.lead_download_icon}></i></button>

 
   </div>



      {/* Table Section */}
      <TableContainer component={Paper}>
        <Table>
           <TableHead style={{background: "#EAF6FF"}}>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Entity Name</TableCell>
              <TableCell>Bank</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Transfer Date</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Charge/Commission</TableCell>
              <TableCell>UTR</TableCell>
              <TableCell>Deposit Slip</TableCell>
              <TableCell>Users Remark</TableCell>
              <TableCell>Executes Remark</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {dummyData
    .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
    .map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.dateTime}</TableCell>
        <TableCell>{row.transactionId}</TableCell>
        <TableCell>{row.entityName}</TableCell>
        <TableCell>{row.bank}</TableCell>
        <TableCell>
          <Typography>{row.amount}</Typography>
          <Typography variant="caption">{row.amountWords}</Typography>
        </TableCell>
        <TableCell>{row.transferDate}</TableCell>
        <TableCell>{row.branch}</TableCell>
        <TableCell>{row.charge}</TableCell>
        <TableCell>{row.utr}</TableCell>
        <TableCell>{row.depositSlip}</TableCell>
        <TableCell>{row.remark}</TableCell>
        <TableCell>{row.executesRemark}</TableCell> {/* Added this */}
        <TableCell>{row.status}</TableCell>
      </TableRow>
    ))}
</TableBody>

        </Table>
      </TableContainer>

      {/* Custom Pagination Section */}
      <div className={`${global.pageination}`}>
        <div className={global.row}>
          <div className={global.col_s_12}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(e, page) => setCurrentPage(page)}
              color="primary"
              sx={{
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#EAF6FF",
                  borderRadius: "2px",
                  border: "solid #0682FF 1px",
                  color: "#06226A",
                },
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: "#EAF6FF",
                  color: "#06226A",
                },
              }}
            />
          </div>
        </div>
      </div>
     
    </div>
    </div>           
  );
};

export default FundRequestReport;
