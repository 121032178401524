import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Tooltip,
  TablePagination,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import global from "../../assets/scss/global.module.scss"; // Assuming global CSS module for pagination styling



const RecoveryModuleReport = () => {

    const searchByOptions = ["Transaction ID", "Wallet ID", "From", "To"];
  // Example data
  const rows = Array.from({ length: 50 }, (_, index) => ({
    transactionId: `TXN${index + 1}`,
    dateTime: "06 Apr 2024 4:13:33 PM",
    entityName: `Entity ${index + 1}`,
    userCode: `Api User (${index + 1000})`,
    recoveryAmount: `₹${(index + 1) * 1000}.00`,
    recoveredAmount: `₹${(index + 1) * 900}.00`,
    pendingAmount: `₹${(index + 1) * 100}.00`,
    recoveredAgainstTransactionId: `REC${index + 1}`,
    chargesCommission: `₹${(index + 1) * 50}.00`,
    markedBy: "Ankur Gupta",
    remarks: "Lorem ipsum dolor sit amet consectetur...",
  }));

  // State for filters and pagination
  const [filteredRows, setFilteredRows] = useState(rows);
  const [filter, setFilter] = useState({
    entityName: "",
    transactionId: "",
    date: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  
  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle filters
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleSearch = () => {
    console.log("Search clicked with filters:", filter);
  };

  const handleClear = () => {
    setFilter({
      searchBy: "",
      searchText: "",
      fromDate: "",
      toDate: "",
    });
  };
  


  return (
    <div className= { `${global.gray_bg} ${global.pad_16} `}>

    <div class="container-fluid">
          <div className={ `${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
             <Typography variant="h1"  gutterBottom > Recovery Module</Typography>
          </div>
    
          <div className= { `${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16}  `} >
      <Box display="flex" alignItems="center" gap={1}   flexWrap="wrap">
          <TextField
            select
            label="Search By"
            name="searchBy"
            value={filter.searchBy}
            onChange={handleFilterChange}
            variant="outlined"
             sx={{ width: "150px" }}
          >
            {searchByOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Enter Value"
            name="searchText"
            value={filter.searchText}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ width: "150px" }}

          />
          <TextField
            label="From Date"
            type="date"
            name="fromDate"
            value={filter.fromDate || ""}
            onChange={handleFilterChange}
            variant="outlined"
            
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "150px" }}

          />
          <TextField
            label="To Date"
            type="date"
            name="toDate"
            value={filter.toDate || ""}
            onChange={handleFilterChange}
            variant="outlined"
             InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "150px" }}

          />
          <button className={ `${global.clear_btn} ${global.lead_common_btn} `} onClick={handleClear}>Clear</button>
  <button className={ `${global.submit_btn_1} ${global.lead_common_btn} `}  onClick={handleSearch}> Search <i className={global.lead_search_icon}></i></button>
  <button className={ `${global.download_btn_1} ${global.lead_common_btn} `} onClick={() => console.log("Download clicked")}>Download <i className={global.lead_download_icon}></i></button>

        </Box>
            </div>


      {/* Table */}
      <TableContainer component={Paper}>
                   <Table>
                      <TableHead style={{background: "#EAF6FF"}}>
            <TableRow>
              <TableCell>Transaction ID & Date Time</TableCell>
              <TableCell>Entity Name / User Code</TableCell>
              <TableCell>Recovery Amount</TableCell>
              <TableCell>Recovered Amount</TableCell>
              <TableCell>Pending Amount</TableCell>
              <TableCell>Recovered Against Transaction ID</TableCell>
              <TableCell>Charges Commission</TableCell>
              <TableCell>Marked By</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box>
                      <Typography variant="body2" color="primary">
                        {row.transactionId}
                      </Typography>
                      <Typography variant="body2">{row.dateTime}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="body2">{row.entityName}</Typography>
                      <Typography variant="caption">{row.userCode}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{row.recoveryAmount}</TableCell>
                  <TableCell>{row.recoveredAmount}</TableCell>
                  <TableCell>{row.pendingAmount}</TableCell>
                  <TableCell>
                    <Typography variant="body2" color="primary">
                      {row.recoveredAgainstTransactionId}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.chargesCommission}</TableCell>
                  <TableCell>{row.markedBy}</TableCell>
                  <TableCell>
                    <Tooltip title={row.remarks}>
                      <Typography variant="body2" noWrap>
                        {row.remarks}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    </div>
  );
};

export default RecoveryModuleReport;
