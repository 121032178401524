import React, { useEffect, useState } from 'react'
import Userlogin from '../login/Userlogin'
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';
import '../../assets/scss/style.scss'
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material'; 
import { Delete } from '@mui/icons-material';
import GlobalStyles from '../../assets/scss/global.module.scss';


const DashBoard = () => {

  const [payloadData, setPayloadData] = useState([]);

  const generatePayload = (inputArray) => {
    return {
      data: inputArray.map((item) => ({
        serviceid: item.id,
        isChecked: false,
        rows: [
          {
            minslab: "10",
            maxslab: "10",
            commdata: [
              { key: "api", charge: "1", tds: "1", type: "1", value: "0" },
              { key: "agent", charge: "1", tds: "1", type: "1", value: "0" },
              { key: "distributor", type: "1", value: "0" },
              { key: "masterdistributor", type: "1", value: "0" },
            ],
          },
        ],
      })),
    };
  };

  const addRow = (serviceId) => {
    setPayloadData((prevPayload) => {
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: [...item.rows,
                  {
                    minslab: "10",
                    maxslab: "10",
                    commdata: [
                      { key: "api", charge: "1", tds: "1", type: "1", value: "0" },
                      { key: "agent", charge: "1", tds: "1", type: "1", value: "0" },
                      { key: "distributor", type: "1", value: "0" },
                      { key: "masterdistributor", type: "1", value: "0" },
                    ],
                  },
                ],
              }
            : item
        ),
      };
    });
  };

  const removeRow = (serviceId, rowIndex) => {
    setPayloadData((prevPayload) => {
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: item.rows.filter((_, index) => index !== rowIndex), // Remove the specific row by index
              }
            : item
        ),
      };
    });
  };  

  const jsonData = {
    data: [
      {
        serviceid: 26,
        isChecked: true,
        rows: [
          {
            minslab: "10",
            maxslab: "10",
            commdata: [
              { key: "api", charge: "1", tds: "1", type: "1", value: "0" },
              { key: "agent", charge: "1", tds: "1", type: "1", value: "0" },
              { key: "distributor", type: "1", value: "0" },
              { key: "masterdistributor", type: "1", value: "0" },
            ],
          },
        ],
      },
    ],
  };

  const fetchServices = async (subcatId) => {
    try {
      const response = await fetcher.get(ApiUrls.FETCH_SERVICES, {
        subcatid: subcatId,
      });
      if (response?.status === 200) {
        setPayloadData(generatePayload(response.response));
      } 
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const DynamicTable = ({ data }) => {
    return (
      <table  border="1"  style={{ width: "100%", borderCollapse: "collapse"}}>
        <thead>
          <tr>
            <th rowSpan="2" style={{  padding: "10px" }}>ID</th>
            <th rowSpan="2" style={{  padding: "10px" }}>Operator Name</th>
            <th rowSpan="2" style={{ padding: "10px" }}>Service Name</th>
            <th rowSpan="2" style={{  padding: "10px" }}>Min Default</th>
            <th rowSpan="2" style={{  padding: "10px" }}>Max Default</th>
            {data[0]?.rows[0]?.commdata.map((comm, index) => (
              <th
                key={`group-header-${index}`}
                colSpan="4"
                style={{  textAlign: "center", padding: "10px", backgroundColor: "#f0f0f0" }}
              >
                {comm.key.toUpperCase()}
              </th>
            ))}
          </tr>
          <tr>
            {data[0]?.rows[0]?.commdata.map((_, index) => (
              <>
                <th key={`sub-header-charge-${index}`} style={{  padding: "10px" }}>Charge</th>
                <th key={`sub-header-tds-${index}`} style={{  padding: "10px" }}>TDS</th>
                <th key={`sub-header-type-${index}`} style={{  padding: "10px" }}>Type</th>
                <th key={`sub-header-value-${index}`} style={{ padding: "10px" }}>Value</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((service) =>
            service.rows.map((row, rowIndex) => (
              <tr key={`${service.serviceid}-${rowIndex}`}>
                {rowIndex === 0 ? (
                  <>
                    <td rowSpan={service.rows.length} style={{ padding: "10px" }}>
                      {service.serviceid}
                    </td>
                    <td rowSpan={service.rows.length} style={{ padding: "10px" }}>
                      Operator Name
                    </td>
                    <td rowSpan={service.rows.length} style={{ padding: "10px" }}>
                      Service Name
                    </td>
                  </>
                ) : null}
                <td style={{ padding: "10px" }}>{row.minslab}</td>
                <td style={{  padding: "10px" }}>
                  {row.maxslab}
                   <br/>
                    <Button onClick={() => removeRow(service.serviceid, rowIndex)}  variant="contained" color="primary" startIcon={<Delete />}>
                    </Button>

                    <Button onClick={() => addRow(service.serviceid)} variant="contained" color="primary" startIcon={<Add />}>
                    </Button>
                </td>
                {row.commdata.map((comm, commIndex) => (
                  <>
                    <td key={`charge-${rowIndex}-${commIndex}`} style={{ border: "1px solid black", padding: "10px" }}>
                      {comm.charge || "-"}
                    </td>
                    <td key={`tds-${rowIndex}-${commIndex}`} style={{ border: "1px solid black", padding: "10px" }}>
                      {comm.tds || "-"}
                    </td>
                    <td key={`type-${rowIndex}-${commIndex}`} style={{ border: "1px solid black", padding: "10px" }}>
                      {comm.type}
                    </td>
                    <td key={`value-${rowIndex}-${commIndex}`} style={{ border: "1px solid black", padding: "10px" }}>
                      {comm.value}
                    </td>
                  </>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  };
  

  useEffect(() => {
    fetchServices(1);
  }, []);

  return (
    <div className='container-fluid dashboard'>
    <h1>DashBoard</h1>
          {/* {payloadData?.data?.length && (
            <DynamicTable data={payloadData.data} />
          )} */}
      </div>
  );
}

export default DashBoard;
