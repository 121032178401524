import React, { useEffect, useState } from "react";
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  FormControl,
  InputLabel,
  Switch,
  Button,
} from "@mui/material";
import { SaveAlt } from "@mui/icons-material";
import fetcher from "../../utils/fetcher";
import global from "../../assets/scss/global.module.scss";
import Loader from "../../utils/Loader";
import { PageUrls, ApiUrls } from "../../utils/constants";
import { useNavigate } from "react-router-dom";


const ProviderList = () => {
  const navigate = useNavigate();
  const [providers, setProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 25;
  const [isProviderClicked, setProviderClicked] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState();
  const [filters, setFilters] = useState({
    filterValue: "",
    status: "",
  });

  // Fetch providers whenever the page changes
  useEffect(() => {
    fetchProviders(currentPage);
  }, [currentPage]);

  // Fetch providers from the server
  const fetchProviders = async (page) => {
    setLoading(true);
    try {
      const response = await fetcher.get(
        `${ApiUrls.All_PROVIDERS}?page=${page - 1}&size=${itemsPerPage}`
      );
      if (response?.status === 200) {
        setCurrentPage(page);
        setProviders(response.response.userProviders);
        setFilteredProviders(response.response.userProviders);
        setTotalPages(response.response.totalPages);
      }
    } catch (error) {
      console.error("Error fetching providers:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle filter input change
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  // Apply filters
  const applyFilters = () => {
    let filtered = [...providers];
    if (filters.filterValue) {
      filtered = filtered.filter(
        (provider) =>
          provider.aliasName
            .toLowerCase()
            .includes(filters.filterValue.toLowerCase()) ||
          provider.entityName
            .toLowerCase()
            .includes(filters.filterValue.toLowerCase())
      );
    }
    if (filters.status) {
      filtered = filtered.filter(
        (provider) =>
          provider.status.toLowerCase() === filters.status.toLowerCase()
      );
    }
    setFilteredProviders(filtered);
    setCurrentPage(1); // Reset to page 1 after filtering
  };

  // Clear filters
  const clearFilters = () => {
    setFilters({ filterValue: "", status: "" });
    setFilteredProviders(providers);
  };

  const updateStatus = (id, isActive) => {
    updateProviderStatus(id, isActive)
  };
  const updateProviderStatus =async (id,isActive) => {
    const data = {
      providerUserId: id,
      status: isActive 
    }
    try {
      const res = await fetcher.post(ApiUrls.PROVIDER_ISACTIVE, data);
      if (res?.status === 200) {
        setFilteredProviders((prev) =>
          prev.map((provider) =>
            provider.providerUserId === id
          ? { ...provider, status: provider.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" }
              : provider
          )
        );
      }
     } catch (err) {
    }
  };

  // Download filtered data as CSV
  const handleDownload = () => {
    const dataToDownload = filteredProviders.map((provider) => ({
      id: provider.id,
      aliasName: provider.aliasName,
      entityName: provider.entityName,
      email: provider.email,
      phone: provider.phone,
      gstin: provider.gstin,
      pan: provider.pan,
      status: provider.status,
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      ["ID,Alias Name,Entity Name,Email,Phone,GSTIN,PAN,Status"]
        .concat(
          dataToDownload.map(
            (row) =>
              `${row.id},${row.aliasName},${row.entityName},${row.email},${row.phone},${row.gstin},${row.pan},${row.status}`
          )
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "providers.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openProviderDetail = (provider) => {
    setSelectedProvider(provider);
   
    setProviderClicked(true);
    navigate(PageUrls.ADD_PROVIDER, { state: { providerData: provider } });
  };

  return (
    <div className={`${global.gray_bg} ${global.pad_16}`}>
      {loading && <Loader />}
      <div className="container-fluid">
      <div
          className={`${global.white_bg} ${global.white_bg} ${global.pad_16}  `}
        >
        <Typography variant="h1" gutterBottom>
          Provider List
        </Typography>
        </div>

        <div className= { `${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16} `} >

  <div  className={global.row}>
  <FormControl variant="outlined" style={{ width: "150px", marginRight: "8px" }}>
  <InputLabel>Filter Type</InputLabel>
  <Select
    name="filterType"
    value={filters.filterType}
    onChange={handleFilterChange}
    label="Filter Type"
  >
   
    <MenuItem value="aliasName">Alias Name</MenuItem>
    <MenuItem value="entityName">Entity Name</MenuItem>
    {/* Add more filter types as needed */}
  </Select>
</FormControl>;

    <div className={global.col_s_10}>
    <TextField
      label="Search by Alias or Entity Name"
      variant="outlined"
      name="filterValue"
      value={filters.filterValue}
      onChange={handleFilterChange}
      style={{marginRight:"8px"}}
    />
    <FormControl variant="outlined" style={{ width: "150px" }}>
      <InputLabel>Status</InputLabel>
      <Select
        name="status"
        value={filters.status}
        onChange={handleFilterChange}
        label="Status"
        style={{marginRight:"8px"}}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="ACTIVE">ACTIVE</MenuItem>
        <MenuItem value="INACTIVE">INACTIVE</MenuItem>
      </Select>
    </FormControl>
    <button className={ `${global.submit_btn_1} ${global.lead_common_btn} `}   onClick={applyFilters}>
      Search
    </button>
    <button className={ `${global.clear_btn} ${global.lead_common_btn} `} 
       
      onClick={clearFilters}
      style={{ marginLeft: "8px" }}
    >
      Clear
    </button>
    <button className={ `${global.submit_btn_1} ${global.lead_common_btn} `} 
      
      startIcon={<SaveAlt />}
      onClick={handleDownload}
    >
      Download
    </button>
  </div>

  <div className={global.col_s_2}>
  <button
    className={ `${global.submit_primary_btn}  `} 
    onClick={() => navigate(PageUrls.ADD_PROVIDER)}
  >
    Add Provider
  </button>
  </div>
  </div> </div>

        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead style={{ background: "#EAF6FF" }}>
              <TableRow>
                <TableCell>Provider ID</TableCell>
                <TableCell>Alias Name</TableCell>
                <TableCell>Entity Name</TableCell>
                <TableCell>Email and Phone</TableCell>
                <TableCell>GSTIN and PAN</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProviders.map((provider) => (
                <TableRow
                  key={provider.providerCode}
                  onClick={() => openProviderDetail(provider)}
                >
                  <TableCell>{provider.providerCode}</TableCell>
                  <TableCell>{provider.aliasName}</TableCell>
                  <TableCell>
                    {provider.entityName || "Provider Entity"}
                  </TableCell>
                  <TableCell>
                    {provider.email || "Provider Email"} <br />
                    {provider?.mobile || "Provider Phone"}
                  </TableCell>
                  <TableCell>
                    {provider?.providerBusinessDetails?.gstin || "Provider GSTIN"} <br />
                    {provider?.providerBusinessDetails?.pan || "Provider Pan"}
                  </TableCell>
                  <TableCell>
  <Switch
    checked={provider.status === "ACTIVE"}
    onClick={(e) => e.stopPropagation()}
    onChange={(e) => updateStatus(provider.providerUserId, e.target.checked ? 1 : 0)}
    color="primary"
  />
</TableCell>

                  <TableCell>
                    <Button variant="outlined" color="primary">
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={ `${global.pageination}`}>
            <div className={global.row}>  
              <div className={global.col_s_12}>  
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
         
        />
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderList;
