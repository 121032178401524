import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  MenuItem,
  TablePagination,
} from "@mui/material";
import global from "../../assets/scss/global.module.scss"; // Assuming global CSS module for pagination styling

const WalletLedger = () => {
  const [filter, setFilter] = useState({
    searchBy: "",
    searchText: "",
    fromDate: "",
    toDate: "",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const searchByOptions = ["Transaction ID", "Wallet ID", "From", "To"];
  const rows = [
    {
      dateTime: "2024-12-21 12:45 PM",
      transactionId: "TXN0012345 / WL12345",
      from: "User A",
      to: "Admin",
      transactionAmount: "₹500",
      charges: "₹10",
      commission: "₹15",
      tds: "₹2",
      debit: "₹500",
      credit: "₹0",
      closingUser: "₹0",
      closingAdmin: "₹500",
      remark: "Successful",
    },
    // Add more rows as needed for testing
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleSearch = () => {
    console.log("Search clicked with filters:", filter);
  };

  const handleClear = () => {
    setFilter({
      searchBy: "",
      searchText: "",
      fromDate: "",
      toDate: "",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className= { `${global.gray_bg} ${global.pad_16} `}>

    <div class="container-fluid">
          <div className={ `${global.white_bg} ${global.white_bg} ${global.pad_16}  `} >
             <Typography variant="h1"  gutterBottom > Wallet Recovery</Typography>
          </div>
    
          <div className= { `${global.white_bg} ${global.mar_top_16} ${global.mar_bottom_16} ${global.pad_16}  `} >
          <Box display="flex"  gap={1} flexWrap="wrap">

        <TextField
          select
          label="Search By"
          name="searchBy"
          value={filter.searchBy}
          onChange={handleFilterChange}
          variant="outlined"
           sx={{ width: "150px" }}
        >
          {searchByOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Enter Value"
          name="searchText"
          value={filter.searchText}
          onChange={handleFilterChange}
          variant="outlined"
          sx={{ width: "150px" }}
        />
        <TextField
          label="From Date"
          type="date"
          name="fromDate"
          value={filter.fromDate || ""}
          onChange={handleFilterChange}
          variant="outlined"
           InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: "150px" }}
        />
        <TextField
          label="To Date"
          type="date"
          name="toDate"
          value={filter.toDate || ""}
          onChange={handleFilterChange}
          variant="outlined"
           InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: "150px" }}
        />
        <button className={ `${global.clear_btn} ${global.lead_common_btn} `} onClick={handleClear}>Clear</button>
  <button className={ `${global.submit_btn_1} ${global.lead_common_btn} `}  onClick={handleSearch}> Search <i className={global.lead_search_icon}></i></button>
  <button className={ `${global.download_btn_1} ${global.lead_common_btn} `} onClick={() => console.log("Download clicked")}>Download <i className={global.lead_download_icon}></i></button>

      </Box>
      </div>

      {/* Table */}
      <TableContainer component={Paper}>
                        <Table>
                           <TableHead style={{background: "#EAF6FF"}}>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Transaction ID / Wallet ID</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Transaction Amount</TableCell>
              <TableCell>Charges</TableCell>
              <TableCell>GST</TableCell>
              <TableCell>Commission</TableCell>
              <TableCell>TDS</TableCell>
              <TableCell>Debit</TableCell>
              <TableCell>Credit</TableCell>
              <TableCell>Closing User</TableCell>
              <TableCell>Closing Admin</TableCell>
              <TableCell>Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.dateTime}</TableCell>
                  <TableCell>{row.transactionId}</TableCell>
                  <TableCell>{row.from}</TableCell>
                  <TableCell>{row.to}</TableCell>
                  <TableCell>{row.transactionAmount}</TableCell>
                  <TableCell>{row.charges}</TableCell>
                  <TableCell>{row.gst}</TableCell>
                  <TableCell>{row.commission}</TableCell>
                  <TableCell>{row.tds}</TableCell>
                  <TableCell>{row.debit}</TableCell>
                  <TableCell>{row.credit}</TableCell>
                  <TableCell>{row.closingUser}</TableCell>
                  <TableCell>{row.closingAdmin}</TableCell>
                  <TableCell>{row.remark}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
    </div>
  );
};

export default WalletLedger;
