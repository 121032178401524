import React, { useState, useEffect } from "react";
import fetcher from "../../utils/fetcher";
import { ApiUrls } from "../../utils/constants";
import {
  Box,Typography,Checkbox,CircularProgress,Table,TableBody,TableCell ,TableContainer,TableHead,TableRow, Paper,Button, Modal,
  TextField,Grid,Radio,Switch,Accordion, AccordionSummary, AccordionDetails,
} from "@mui/material";
import EditSchemeModal from "./EditScheme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import global from '../../assets/scss/global.module.scss'
import '../../assets/scss/style.scss'
import AddIcon from '@mui/icons-material/Add';

const SchemeAdmin = () => {
  const [outerData, setOuterData] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState({});
  const [expandedOuterRow, setExpandedOuterRow] = useState(null);
  const [loadingSubcategory, setLoadingSubcategory] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSubcatId, setSelectedSubcatId] = useState(null);
  
  const [sectionsCsv, setSectionsCsv] = useState(null);
  const [loadingServices, setLoadingServices] = useState(false);
  const [schemeName, setSchemeName] = useState("");
  const [schemeDescription, setSchemeDescription] = useState("");
  const [payloadService, setPayloadService] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState(null);
  const [schemeData, setSchemeData] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [successModal, setSuccessModal] = useState(false);

const [editedSchemeId, setEditedSchemeId ] = useState("")
const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetcher.get(ApiUrls.FETCH_CATEGORY);
        if (response?.status === 200 && response.response) {
          setOuterData(response.response);
        } else {
          console.error("Failed to fetch categories:", response.message);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();

     
  }, []);
// Fetch schemes by category and subcategory IDs
const fetchSchemes = async (subcatId, catid) => {
  try {
    const url = `${ApiUrls.FETCH_SCHEMES_BY_IDS}?catids=${catid}&subcatids=${subcatId}`;
    const response = await fetcher.get(url);

    if (response?.status === 200 && response.response) {
      setSelectedSubcategory(subcatId)
      setSelectedCategory(catid)
      setSchemeData(response.response[0].subcatschemes[0].schemeList);

    } else {
      console.error("Failed to fetch schemes:", response.message);
      setSchemeData([]);
    }
  } catch (error) {
    console.error("Error fetching schemes:", error);
    setSchemeData([]);
  }
};

// Add any necessary dependencies
  const fetchSubcategories = async (catid) => {
    setLoadingSubcategory(true);
    try {
      const response = await fetcher.get(ApiUrls.FETCH_SUB_CATEGORY, { catid });
      if (response?.status === 200 && response.response) {
        setSelectedCatId(catid);
        setSubcategoryData((prev) => ({
          ...prev,
          [catid]: response.response,
        }));
      } else {
        console.error("Failed to fetch subcategories:", response.message);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoadingSubcategory(false);
    }
  };

  const fetchServices = async (subcatId, sectionsCsv) => {
    setLoadingServices(true);
    try {
      const response = await fetcher.get(ApiUrls.FETCH_SERVICES, {
        subcatid: subcatId,
      });

      if (response?.status === 200 && response.response) {
       // setServicesData(response.response);
        setSectionsCsv(sectionsCsv); // Storing sectionsCsv to use in the tableF

        setPayloadService(generatePayload(response.response));
      } else {
        console.error("Failed to fetch services:", response.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoadingServices(false);
    }
  };

  const handleOuterRowToggle = (id) => {
    setSchemeData([]);
    if (expandedOuterRow === id) {
      setExpandedOuterRow(null);
    } else {
      setExpandedOuterRow(id);
      if (!subcategoryData[id]) {
        fetchSubcategories(id);
      }
    }
  };

  const handleAddNewClick = (subcatId, sectionsCsv) => {
    setSelectedSubcatId(subcatId);
    fetchServices(subcatId, sectionsCsv);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPayloadService([]);
    setSelectedSubcatId(null);
    setIsEditModalOpen(false)
  };
  const toggleTableVisibility = (catid,subcatId) => {
  //   setShowTable((prev) => !prev); // Toggle table visibility
  // {!showTable &&(
    fetchSchemes(subcatId, catid)
  // )}  
  };

  const handleEdit = (schemeId) => {
     // Set the data of the row to be edited
     setEditedSchemeId(schemeId)
    setIsEditModalOpen(true); // Open the modal
  };

  
  const handleDelete = async (schemeId) => {
    try {
      const response = await fetcher.get(
        `${ApiUrls.DELETE_SCHEME}?schemeId=${schemeId}`
      );
  
      if (response?.status === 200 && response.response === "Deleted successfully") {
        console.log("Delete successful:", response.message);
        
        
       
      } else {
        console.error("Failed to delete scheme:", response.message);
      }
    } catch (error) {
      console.error("Error deleting scheme:", error);
    }
  };
  

  const handleToggle = async (schemeId, currentStatus) => {
    try {
      const newStatus = currentStatus === "ACTIVE" ? 0 : 1; // Toggle logic for the status
      const response = await fetcher.get(
        `${ApiUrls.TOGGLE_SCHEME}?schemeId=${schemeId}&status=${newStatus}`
      );
  
      if (response?.status === 200) {
        console.log("Toggle successful:", response.data);
       
      } else {
        console.error("Failed to toggle scheme:", response.message);
      }
    } catch (error) {
      console.error("Error toggling scheme:", error);
    }
  };


  const handleCheckboxChange = (serviceId) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId) {
        return {
          ...service,
          isChecked: !service.isChecked, // Toggle isChecked
        };
      }
      return service; // Return unchanged service for others
    });

    setPayloadService({ data: updatedServices });
  };

  const handleRowChange = (e, serviceId, field, cell,rowIndex) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId && service.isChecked === true) {
        // Update rows correctly
        const updatedRows = service.rows.map((row,index) => {
          if(rowIndex === index){
            const updatedCommdata = row.commdata.map((commdata) =>
              commdata.key === cell
                ? { ...commdata, [field]: e.target.value }
                : commdata
            );
            return { ...row, commdata: updatedCommdata }; // Update the commdata in the row
          }else{
            return {...row}
          }
          
        });

        return {
          ...service,
          rows: updatedRows, // Replace the rows with updated rows
        };
      }
      return service; // Return unchanged service for others
    });

    setPayloadService({ data: updatedServices });
  };
  const handleRowMinMaxChange = (e, serviceId, field,rowIndex) => {
    const updatedServices = payloadService.data.map((service) => {
      if (service.serviceid === serviceId && service.isChecked === true) {
        const updatedRows = service.rows.map((row,index) => {
          if(rowIndex === index){
            return { ...row, [field]: e.target.value };
          }else{
            return { ...row}
          }
        });

        return {
          ...service,
          rows: updatedRows,
        };
     
      }
      return service;
    });

    setPayloadService({ data: updatedServices });
  };

  // Function to parse and display CSV data as table
  const parseCsvData = (csvData) => {
    const rows = csvData.split("\n").map((row) => row.split(","));
    return rows;
  };

  const handleSave = async () => {
    const newErrors = {};

    // Validation for Scheme Name
    if (!schemeName.trim()) {
      newErrors.schemeName = "Scheme Name is required";
    }

    // Validation for Scheme Description
    if (!schemeDescription.trim()) {
      newErrors.schemeDescription = "Scheme Description is required";
    }

    setErrors(newErrors);

    // If there are errors, don't proceed
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    // Clear errors if validation passes
    setErrors({});

    const data = payloadService?.data?.filter(
      (service) => service.isChecked === true
    );
    const payload = {
      name: schemeName,
      desc: schemeDescription,
      schedeleAt: 1731235282,
      cat: selectedCatId,
      subcat: selectedSubcatId,
      schemeType: 1,
      data,
    };

    console.log("Final Payload:", payload);

    const verifyRes = await fetcher.post(ApiUrls.SAVE_SCHEME, payload);
    if (verifyRes?.status === 200 && verifyRes.response.status === 200) {
      setSuccessModal(true);
      console.log("Final Payload SAVED", payload);
    } else {
      console.log("Final Payload Failed", payload);
      setSuccessModal(true);
    }
  };

  const generatePayload = (inputArray) => {
    // Split categories into an array and map to generate commdata
    const categoryKeys = sectionsCsv.split(",").map((category) =>
      category.toLowerCase().replace(/\s+/g, "") // Convert to lowercase and remove spaces for keys
    );

    return {
      data: inputArray.map((item) => ({
        serviceid: item.id,
        serviceName: item.schemeServiceName,
        operatorName: item.schemeServiceOperatorName,
        operatorImage: item.schemeServiceOperatorLogoUrl,
        isChecked: false,
        rows: [
          {
            minslab: "0",
            maxslab: "10",
            commdata: categoryKeys.map((key) => {
              if (key === "apiuser" || key === "agent") {
                return {
                  key: key,
                  charge: "1", 
                  tds: "1",    
                  type: "1",   
                  value: "0", 
                };
              } else {
                return {
                  key: key,
                  type: "1",   
                  value: "0",  
                };
              }
            }),
          },
        ],
      })),
    };
  };


 const DynamicTable = ({ data }) => {
     return (
       <div style={{marginTop:"24px"}}>
       <table style={{ width: "2000px", borderCollapse: "collapse", border: "1px solid #D6D9E1" }}>
         <thead>
           <tr>
           <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}></th>
             <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>ID</th>
             <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Operator Name</th>
             <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Service Name</th>
             <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Min Default</th>
             <th rowSpan="2" style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Max Default</th>
             {data[0]?.rows[0]?.commdata.map((comm, index) => (
               <th
                 key={`group-header-${index}`}
                 colSpan={comm.key === "apiuser" || comm.key === "agent" ? 4 : 2}
                 style={{ border: "1px solid #D6D9E1", textAlign: "center", padding: "10px", backgroundColor: "#F9F7C8" }}
               >
                 {comm.key.toUpperCase()}
               </th>
             ))}
           </tr>
           <tr>
             {data[0]?.rows[0]?.commdata.map((item, index) => (
               item.key === "apiuser" || item.key === "agent" ? (
                 <>
                   <th key={`sub-header-charge-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px" , backgroundColor: "#EAF6FF" }}>Charge</th>
                   <th key={`sub-header-tds-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>TDS</th>
                   <th key={`sub-header-type-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Type</th>
                   <th key={`sub-header-value-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Value</th>
                 </>
               ) : (
                 <>
                   <th key={`sub-header-type-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Type</th>
                   <th key={`sub-header-value-${index}`} style={{ border: "1px solid #D6D9E1", padding: "10px", backgroundColor: "#EAF6FF" }}>Value</th>
                 </>
               )
             ))}
           </tr>
         </thead>
         <tbody>
 
     
           {data.map((service,dataIndex) =>
             service.rows.map((row, rowIndex) => (
               <tr key={`${service.serviceid}-${rowIndex}`}>
                 {rowIndex === 0 ? (
                   <>
                    <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                       <Checkbox      checked={service.isChecked}
                        onChange={() => handleCheckboxChange(service.serviceid)}/>
                     </td>
                     <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                       {service.serviceid}
                     </td>
                     <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                     {service.operatorName}
                     </td>
                     <td rowSpan={service.rows.length} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                     
 
                     <Box> <img src={service.operatorImage}  alt={`${service.operatorName} Logo`}  style={{ maxWidth: "35px",  maxHeight: "35px",  marginBottom: "8px",  }}/>
                           <Typography variant="body2"> {service.serviceName} </Typography>
                     </Box>  
                     </td>
                   </>
                 ) : null}
                 <td style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                 <TextField  key={`minslab-${service.serviceid}-${rowIndex}`} style={{width:"100px;"}} type="text" value={row.minslab} onChange={(e) =>  handleRowMinMaxChange(e, service.serviceid, "minslab",rowIndex)  }/>
                 </td>
                 <td  className={global.plus_container} style={{ border: "1px solid #D6D9E1", padding: "10px", position:"relative" }}>
                  
                   <TextField key={`maxslab-${service.serviceid}-${rowIndex}`} className={global.form_control} type="text"  value= {row.maxslab}  onChange={(e) =>  handleRowMinMaxChange(e, service.serviceid, "maxslab",rowIndex)  }  />
                   {rowIndex === service.rows.length - 1 && (
                  <span style={{position:"absolute", right:"-30px", top:"35px", width:"60px"}}>
                    <button onClick={() => removeRow(service.serviceid, rowIndex)} style={{ marginRight: "10px", width:"20px", height:"20px", lineHeight:"16px", background:"#ccc", color:"#000", marginBottom:"5px"}}> - </button><br/>
                    <button onClick={() => addRow(service.serviceid)} style={{  width:"20px", height:"20px", lineHeight:"16px", background:"#ccc", color:"#000"}}>+</button>
                 </span>)}
                 </td>
                 {row.commdata.map((comm, commIndex) => (
                  comm.key === "apiuser" || comm.key === "agent" ? ( <>
                     <td key={`charge-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                       
                       <TableCell>
                              
                                   <select key={`charge-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`}
                                           value={comm.charge || ""} id="charge" className={global.form_control}  onChange={(e) => handleRowChange(  e,  service.serviceid,  "charge", comm.key ,rowIndex )
                                     }  >
                                     <option value="1">  Charge  </option>
                                     <option value="2">  Commission  </option>
                                   </select>
                                 </TableCell>
                     </td>
                     <td key={`tds-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
               
                       <TableCell>
                                   <div> <select  key={`tds-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`}
                                               value={comm.tds || ""}     className={global.form_control}  id="tds"  onChange={(e) => handleRowChange(  e,  service.serviceid,  "tds",  comm.key  ,rowIndex ) }  >
                                       <option value="1">  Yes  </option>
                                       <option value="2">  No  </option>
                                     </select>
                                   </div>
                                 </TableCell>
                     </td>
                     <td key={`type-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                       
                       <TableCell>
                                   {" "}
                                   <select key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} 
                                   value={comm.type || ""} className={global.form_control}  id="type"  onChange={(e) => handleRowChange(  e,  service.serviceid,  "type",  comm.key ,rowIndex )  }  >
                                     <option value="1">₹</option>
                                     <option value="2">%</option>
                                   </select>
                                 </TableCell>
                     </td>
                     <td key={`value-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                    
                       <TableCell>
                                   <TextField  key={`value-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} id="value"  type="text" 
                                   value={comm.value || ""} onChange={(e) => handleRowChange(  e,  service.serviceid,  "value", comm.key  ,rowIndex)  }  />
                                 </TableCell>
                     </td>
                   </>
                   ) : (
                     <>
                       <td key={`charge-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                      
                       <TableCell>
                                   {" "}
                                   <select key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} className={global.form_control}  id="type" 
                                   value={comm.type || ""} onChange={(e) => handleRowChange(  e,  service.serviceid,  "type",  comm.key ,rowIndex )  }  >
                                     <option value="1">  Yes  </option>
                                     <option value="2">  No  </option>
                                   </select>
                                 </TableCell>
                     </td>
                     <td key={`value-${dataIndex}-${rowIndex}-${commIndex}`} style={{ border: "1px solid #D6D9E1", padding: "10px" }}>
                  
                       <TableCell>
                                   <TextField key={`type-${comm.key}-${service.serviceid}-${dataIndex}-${rowIndex}-${commIndex}`} id="value"  type="text"  
                                   value={comm.value || ""}  onChange={(e) =>   handleRowChange(  e,  service.serviceid,   "value",  comm.key  ,rowIndex)  }  />
                                 </TableCell>
                     </td>
                     </>
                   )
                 ))}
               </tr>
             ))
           )}
         </tbody>
       </table>
       </div>
     );
   };


  const addRow = (serviceId) => {
    setPayloadService((prevPayload) => {
      const categoryKeys = sectionsCsv.split(",").map((category) =>
        category.toLowerCase().replace(/\s+/g, "") // Convert to lowercase and remove spaces for keys
      );
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: [...item.rows,
                  {
                    minslab: "0",
                    maxslab: "10",
                    commdata: categoryKeys.map((key) => {
                      if (key === "apiuser" || key === "agent") {
                        return {
                          key: key,
                          charge: "1", 
                          tds: "1",    
                          type: "1",   
                          value: "0", 
                        };
                      } else {
                        return {
                          key: key,
                          type: "1",   
                          value: "0",  
                        };
                      }
                    }),
                  },
                ],
              }
            : item
        ),
      };
    });
  };

  const removeRow = (serviceId, rowIndex) => {
    setPayloadService((prevPayload) => {
      return {
        ...prevPayload,
        data: prevPayload.data.map((item) =>
          item.serviceid === serviceId
            ? {
                ...item,
                rows: item.rows.filter((_, index) => index !== rowIndex), // Remove the specific row by index
              }
            : item
        ),
      };
    });
  };  

  const SchemeTable = ({ schemeData }) => {
    return (
      <div>
      {(schemeData.length === 0) ? (
                      <Typography className={global.alert_message}>No schemes found for the selected categories.</Typography>
                    ) : (  <Table>
                                <TableHead>
                                              <TableRow>
                                                <TableCell>Date of Creation</TableCell>
                                                <TableCell>Scheme Name</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Schedule</TableCell>
                                                <TableCell>Set Default</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {schemeData.map((row, index) => (
                                                <TableRow key={index}
                                                onClick={() => handleEdit(row.id)} // Open edit modal when clicking a row
                                                style={{ cursor: "pointer" }} // Make rows look clickable
                                                >
                                                  <TableCell>{new Date(row.createdOn * 1000).toLocaleDateString()}</TableCell>
                                                  <TableCell>{`${row.schemeName} (${row.id})`}</TableCell>
                                                  <TableCell>{row.schemeDesc}</TableCell>
                                                  <TableCell>{row.isActive === 1 ? "Active" : "Inactive"}</TableCell>
                                                  <TableCell>{row.scheduleAt}</TableCell>
                                                  <TableCell><Radio name="" id=""></Radio></TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                    )}
                          </div> );
  };
  return (
    <div className="container-fluid">
    <div className={ `${global.gray_bg} ${global.mar_top_24}`}>


      {outerData.length > 0 ? (
        outerData.map((outerRow) => (
          
        <Accordion
          key={outerRow.id}
          expanded={expandedOuterRow === outerRow.id}
          onChange={() => handleOuterRowToggle(outerRow.id)}
          sx={{ marginBottom: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${outerRow.id}-content`}
            id={`panel-${outerRow.id}-header`}
            className={global.light_blue_active}
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography variant="h6">{outerRow.schemeCategoryName}</Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              backgroundColor: "#fafafa",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: 2,
            }}
          >
            {loadingSubcategory ? (
              <CircularProgress size={24} />
            ) : subcategoryData[outerRow.id] ? (
              subcategoryData[outerRow.id].map((subcategory) => (
                <Box key={subcategory.id} mt={2}>

<Accordion>
        <AccordionSummary onClick={() => toggleTableVisibility(outerRow.id, subcategory.id)}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header" style={{backgroundColor:"#EAF6FF"}}
        >
            
             <Typography> <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems:"center", justifyContent:"space-between" }}>
              <span>{subcategory.schemeSubCategoryName} </span>

          <Button style={{marginRight:"16px", background:"#fff", border: "#06226A solid 1px", color:"#06226A"}}
                        variant="outlined"
                        onClick={() => handleAddNewClick(subcategory.id, subcategory.sectionsCsv)}
                      >
                       
        {<AddIcon />} Add New
                      </Button></Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {selectedCategory === outerRow.id && selectedSubcategory === subcategory.id && (
                    <SchemeTable schemeData={schemeData} outerRowId={outerRow.id} subcategory={subcategory} />
                  )}
          </Typography>
        </AccordionDetails>
      </Accordion>

                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No subcategories found.
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      
     



        ))
      ) : (
        <Typography variant="body1" color="textSecondary">
          Loading categories...
        </Typography>
      )}

      {/* Modal */}
      <Modal open={isModalOpen} onClose={closeModal}>

      
        <Box
          bgcolor="white"
          p={4}
          borderRadius="8px"
          sx={{
            height: "80vh", // Adjust height as needed
            overflowY: "auto", // Enable vertical scrolling
            margin: "8%",
          }}
        >


          
          <div className={global.wrapper_form} style={{margin:"0px", width:"2000px"}}>
         
          <h3>Add New Scheme</h3> 

          <div className={`${global.row} ${global.mar_top_24}`}>
                <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Scheme Name & ID</label>
                    <input type="text" placeholder="Scheme Name & ID" className={global.form_control}
                onChange={(e) => setSchemeName(e.target.value)} 
              />
               {errors.schemeName && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {errors.schemeName}
          </span>
        )}
                </div>
              </div>
              <div className={global.col_s_3}>
                  <div className={global.form_group}>
                    <label>Scheme Description</label>
                    <textarea placeholder="Scheme Description" className={global.form_control}
                rows={4}
                onChange={(e) => setSchemeDescription(e.target.value)}
              />
               {errors.schemeDescription && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {errors.schemeDescription}
          </span>
        )}
                </div>
              </div>
            </div>

            
            </div>


          <Grid   spacing={2}>
            <Grid item xs={12} md={8}>
              
              
              {/* Table below description for CSV Data */}
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            
            {payloadService?.data?.length && (
            <DynamicTable data={payloadService.data} />
          )}
                 
               
          </div>

          <div className="row mt-4 ml-1">
            <div className="col_s_2">
            <button  className={global.submit_seconday_btn} onClick={closeModal}>
              Cancel
            </button>
            </div>
            <div className="col_s_2">
            <button  className={global.submit_primary_btn}  onClick={handleSave}>
              Save
            </button>
            </div>
          </div>
        </Box>
      </Modal>

      <EditSchemeModal
        isOpen={isEditModalOpen}
        onClose={closeModal}
        schemeId={editedSchemeId}
        onSave={(updatedScheme) => {
          console.log("Updated Scheme:", updatedScheme);
          setIsEditModalOpen(false);
        }}

        
      />

<Modal
        open={successModal}
        onClose={() => setSuccessModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: 300,
            textAlign: "center",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Scheme Added!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            New Scheme has been successfully added.
          </Typography>
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            color="primary"
            onClick={() => setSuccessModal(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
   
    </div>
    </div>
  );
};

export default SchemeAdmin;
